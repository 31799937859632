import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HouseCategory } from 'src/app/models/House';

@Component({
  selector: 'app-notresultsfound',
  templateUrl: './NotResultsFound.component.html',
  styleUrls: ['./NotResultsFound.component.scss']
})
export class NotResultsFoundComponent implements OnInit {
  @Input() hInitial = false;
  HouseCategory = HouseCategory;
  @Input() category: HouseCategory = null;
  fromRoute = false;
  constructor(private route: ActivatedRoute) {
    const type = this.route.snapshot.params.typeNotFound;
    if (type && !Number.isNaN(type)) {
      this.category = Number(type) as HouseCategory;
      this.fromRoute = true;
    }
  }

  ngOnInit() {
  }

}
