import { TipoInmuebles } from './../../models/TipoInmuebles';
import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { AppState, AppStateModel } from './Reducer';
import { Observable } from 'rxjs';
import { Usuario } from '../../models/Usuario';
import { Amenidad } from 'src/app/models/Amenidades';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  @Select(AppState) db: Observable<AppStateModel>;
  @Select(AppState.CurrentUser) CurrentUser: Observable<Usuario>;
  @Select(AppState.TiposInmuebles) TiposInmuebles: Observable<TipoInmuebles[]>;
  @Select(AppState.Amenidades) Amenidades: Observable<Amenidad[]>;
  constructor() {
  }
}
