<div class="fullContainer posRelative textCenter OYAuto"> <!--TODO APPLY INFINITE SCROLL-->
  <app-navbar [ShowExpanded]="false" [ShowSearchBar]="true"></app-navbar>
  <div class="childContainer">
    <mat-tab-group #mtg class="h100pr" mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="Perfil">
        <div class="row w100pr">
          <div class="col-sm-12 col-md-6 col-lg-4 col">
            <app-perfil-form></app-perfil-form>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Favoritos">
        <app-favoritos-page *ngIf="this.mtg?.selectedIndex === 1"></app-favoritos-page>
      </mat-tab>
      <mat-tab label="Tus Propiedades">
        <app-mis-propiedades-page *ngIf="this.mtg?.selectedIndex === 2"></app-mis-propiedades-page>
        <!--TODO CHANGE-->
      </mat-tab>
    </mat-tab-group>


  </div>
</div>