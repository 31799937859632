<div class="fullContainer posRelative textCenter">
  <app-navbar [ShowExpanded]="true" [ShowSearchBar]="true" (OpenCloseMap)="openCloseMap()"></app-navbar>
  <div class="show-sm mt13px mb25px">
    <div class="row mlr12px">
      <div class="col col-6 contLeftMobBtns">
        <button class="mapFiltrosMobileBtn" (click)="showFiltersMobile()">
          Filtros
          <div class="mapFiltrosMobileFilters filtrosCont" matBadge="{{filtersN}}" *ngIf="filtersN"></div>
        </button>
      </div>
      <div class="col col-6 contRightMobBtns">
        <button class="mapFiltrosMobileBtn" (click)="openCloseMap()">
          {{ !MapMode ? 'Mapa' : 'Lista'}}
          <mat-icon svgIcon="mapIcon" class="mapFiltrosMobile" *ngIf="!MapMode; else listBtnIcon"></mat-icon>
          <ng-template #listBtnIcon>
            <mat-icon svgIcon="ListHousesIcon" class="mapFiltrosMobile"></mat-icon>
          </ng-template>
        </button>
      </div>
    </div>
  </div>
  <mat-drawer-container class="h100pr" [hasBackdrop]="false">
    <mat-drawer-content class="hFillContent OYAuto" [style.Width]="MapMode && !isMobile ? '50vw' : '100vw'">
      <app-notresultsfound [category]="search?.OptionsSelectBuyLease !== null ? search.OptionsSelectBuyLease : 1" [hInitial]="true" *ngIf="Houses.length === 0"></app-notresultsfound>
      <div class="bodyhome" [ngClass]="{plr24px: (!MapMode && !isMobile)}" *ngIf="Houses.length > 0 && !(MapMode && isMobile)">
        <div class="d-flex placeContetSpaceBetween" [ngClass]="{'pt14px': !isMobile}">
          <div class="searchResults">{{searchResult}}</div>
          <div class="d-flex hide-sm mr24px">
            <button class="sortBtn" [matMenuTriggerFor]="menuSort">
              <mat-icon svgIcon="ListSortIcon"></mat-icon>
              {{currentSort == SortType.Distancia ? 'Distancia' : ''}}
              {{currentSort == SortType.MayorPrecio ? 'Mayor precio' : ''}}
              {{currentSort == SortType.MenorPrecio ? 'Menor precio' : ''}}
            </button>
            <mat-menu #menuSort="matMenu">
              <button mat-menu-item (click)="setSort(SortType.Distancia)">Distancia</button>
              <button mat-menu-item (click)="setSort(SortType.MayorPrecio)">Mayor precio</button>
              <button mat-menu-item (click)="setSort(SortType.MenorPrecio)">Menor precio</button>
            </mat-menu>
            <button class="mapBtn ml41px" *ngIf="!MapMode" (click)="openCloseMap()">
              <mat-icon svgIcon="mapIcon"></mat-icon>
              Ver Mapa
            </button>
          </div>
        </div>
        <div class="d-flex frowfwrap">
          <app-house-item id="HouseItem-{{house.id}}" [ngClass]="{w100pr: (MapMode || isMobile), 'w50pr houseNoMarginRight': (!MapMode && !isMobile)}" *ngFor="let house of Houses; let i=index" [House]="house"></app-house-item>
        </div>
      </div>
      <div class="bodyhome wh100pr" *ngIf="MapMode && isMobile">
        <google-map height="100%" width="100%" #GGMap (mapDragend)="clickMap(3)" (zoomChanged)="clickMap(4)"
        [options]="options">
          <div>
            <map-marker-clusterer #GMcluster [imagePath]="markerClustererImagePath" (clusterClick)="clickCluster($event)">
              <div *ngFor="let house of Houses; let i=index">
                <map-marker
                  #marker{{i}}="mapMarker"
                  [position]="{ lat: house.latitud, lng: house.longitud }"
                  [options]="markerOptions"
                  [icon]="'/assets/SoloLogoGradiantMini.svg'"
                  (mapClick)="openInfoWindow(house)">
                </map-marker>
                <map-info-window id="infoWindow-{{house.id}}" [options]="{pixelOffset: pixelOffset}" [position]="{ lat: house.latitud, lng: house.longitud }">
                  <div class="MapHouseContainer">
                    <app-images-carousel class="houseCarousel" [Infinite]="true" [ShowIndex]="true" [Type]="1" [ShowArrows]="true" [Width]="'initial'" [Height]="'28vh'" [Images]="house?.imagenesToShow" *ngIf="house?.imagenesToShow?.length > 0"></app-images-carousel>
                    <a class="customA" target="_blank" href="/#/House/{{house.id}}/{{house.TituloUrl}}">
                      <h3 class="m0px textDarkGray tittleHouse">{{house?.titulo}}</h3>
                      <h2 class="m0px textDarkGray priceHouse">{{house?.precio | currency:'USD':'symbol'}}</h2>
                    </a>
                    <a class="customA BtnRedirect" target="_blank" href="/#/House/{{house.id}}/{{house.TituloUrl}}">
                      <mat-icon>open_in_new</mat-icon>
                    </a>
                  </div>
                </map-info-window>
              </div>
            </map-marker-clusterer>
          </div>
        </google-map>
      </div>
    </mat-drawer-content>
    <mat-drawer #mapDrawer class="bgfakewhite" [position]="'end'" [mode]="'Side'" [opened]="false">
      <div [style.Width]="MapMode && !isMobile ? '50vw' : '0vw'" class="h100pr" *ngIf="MapMode && !isMobile">
        <google-map height="100%" width="100%" #GGMap (mapDragend)="clickMap(1)" (zoomChanged)="clickMap(2)"
        [options]="options">
          <div>
            <map-marker-clusterer #GMcluster [imagePath]="markerClustererImagePath" (clusterClick)="clickCluster($event)">
              <div *ngFor="let house of Houses; let i=index">
                <map-marker
                  #marker{{i}}="mapMarker"
                  [position]="{ lat: house.latitud, lng: house.longitud }"
                  [options]="markerOptions"
                  [icon]="'/assets/SoloLogoGradiantMini.svg'"
                  (mapClick)="openInfoWindow(house)">
                </map-marker>
                <map-info-window id="infoWindow-{{house.id}}" [options]="{pixelOffset: pixelOffset}" [position]="{ lat: house.latitud, lng: house.longitud }">
                  <div class="MapHouseContainer">
                    <app-images-carousel class="houseCarousel" [Infinite]="true" [ShowIndex]="true" [Type]="1" [ShowArrows]="true" [Width]="'initial'" [Height]="'28vh'" [Images]="house?.imagenesToShow" *ngIf="house?.imagenesToShow?.length > 0"></app-images-carousel>
                    <a class="customA" target="_blank" href="/#/House/{{house.id}}/{{house.TituloUrl}}">
                      <h3 class="m0px textDarkGray tittleHouse">{{house?.titulo}}</h3>
                      <h2 class="m0px textDarkGray priceHouse">{{house?.precio | currency:'USD':'symbol'}}</h2>
                    </a>
                    <a class="customA BtnRedirect" target="_blank" href="/#/House/{{house.id}}/{{house.TituloUrl}}">
                      <mat-icon>open_in_new</mat-icon>
                    </a>
                  </div>
                </map-info-window>
              </div>
            </map-marker-clusterer>
          </div>
        </google-map>
      </div>
    </mat-drawer>
  </mat-drawer-container>
</div>