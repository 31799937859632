import { DefaultAmenidades } from './../../models/Amenidades';
import { TipoInmueblesPagination } from './../../models/TipoInmuebles';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { LatLng } from 'src/app/models/LatLng';
import { TipoInmuebles } from 'src/app/models/TipoInmuebles';
import { Usuario } from 'src/app/models/Usuario';
import { LoadingComponent } from 'src/app/Popup/loading/loading.component';
import { RepositoryService } from '../Repository/Repository.service';
import { GetAmenidades, GetTiposInmuebles, SetCurrentUser, ShowLoading } from './Actions';
import { Amenidad, AmenidadesPagination } from 'src/app/models/Amenidades';
import { Utils } from 'src/app/Utils/Utils';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
export class AppStateModel {
  currentUser: Usuario;
  tiposInmuebles: TipoInmuebles[];
  amenidades: Amenidad[];
}
@State<AppStateModel>({
  name: 'App',
  defaults: {
    currentUser: null,
    tiposInmuebles: [],
    amenidades: DefaultAmenidades
  }
})
@Injectable()
export class AppState {
  constructor(private crud: RepositoryService, private dialog: MatDialog) { }
  dialogRef: MatDialogRef<LoadingComponent> = null;
  @Selector()
  static CurrentUser(state: AppStateModel) {
    return state.currentUser;
  }
  @Selector()
  static TiposInmuebles(state: AppStateModel) {
    return state.tiposInmuebles;
  }
  @Selector()
  static Amenidades(state: AppStateModel) {
    return state.amenidades;
  }
  @Action(SetCurrentUser)
  SetCurrentUser(ctx: StateContext<AppStateModel>, { Token }: SetCurrentUser) {
    if (Token) {
      localStorage.setItem('token', Token);
      Utils.firstValueFrom(this.crud.GetPetition('user/me')).then((res: Usuario) => {
        const data: Usuario = res;
        ctx.patchState({ currentUser: data });
      }).catch(err => {
        this.crud.ShowErrors(err);
        localStorage.removeItem('token');
        ctx.patchState({ currentUser: null });
      });
    } else {
      localStorage.removeItem('token');
      ctx.patchState({ currentUser: null });
    }
  }
  @Action(ShowLoading)
  ShowLoading(ctx: StateContext<AppStateModel>, {Show}: ShowLoading) {
    if (Show) {
      if (!this.dialogRef) {
        this.dialogRef = this.dialog.open(LoadingComponent, {
          disableClose: true,
          width: '300px',
          height: '300px',
          backdropClass: 'loadingPopUp',
          panelClass: 'loadingPopUpPanel',
        });
        Utils.firstValueFrom(this.dialogRef.afterClosed()).then(res => {
          this.dialogRef = null;
        });
      }
    } else {
      if (this.dialogRef) {
        this.dialogRef.close();
      }
    }
  }
  @Action(GetAmenidades)
  GetAmenidades(ctx: StateContext<AppStateModel>) {
    Utils.firstValueFrom(this.crud.GetPetition('inmueble/public/amenidades/?limit=100', false, false)).then((res: AmenidadesPagination) => {
      ctx.patchState({amenidades: res.results});
    });
  }
  @Action(GetTiposInmuebles)
  GetTiposInmuebles(ctx: StateContext<AppStateModel>) {
    // tslint:disable-next-line: max-line-length
    Utils.firstValueFrom(this.crud.GetPetition('inmueble/public/tipo-inmueble/?limit=100', false, false)).then((res: TipoInmueblesPagination) => {
      res.results.unshift({
        id: -1,
        nombre: 'Cualquiera'
      });
      ctx.patchState({tiposInmuebles: res.results});
    });
  }
}
