import { SearchComponent } from './Components/Search/search.component';
import { SetPasswordComponent } from './Components/ControlSesiones/SetPassword/set-password.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './Components/ControlSesiones/Home/home.component';
import { LogInComponent } from './Components/ControlSesiones/LogIn/LogIn.component';
import { RegistroComponent } from './Components/ControlSesiones/Registro/Registro.component';
import { RequestPasswordComponent } from './Components/ControlSesiones/RequestPassword/request-password.component';
import { TestsComponent } from './Components/tests/tests.component';
import { CanActivateIsLoggedService } from './services/CanActivateIsLogged.service';
import { CanActivateIsNotLoggedService } from './services/CanActivateIsNotLogged.service';
import { SellComponent } from './Components/Sell/Sell.component';
import { NotResultsFoundComponent } from './Components/NotResultsFound/NotResultsFound.component';
import { FavoritesComponent } from './Components/Favorites/Favorites.component';
import { HousePageComponent } from './Components/HousePage/HousePage.component';
import { DashboardPageComponent } from './Components/DashboardPage/dashboard-page.component';

const routes: Routes = [
  { path: 'Home', component: HomeComponent },
  { path: 'Search/:searchObj', component: SearchComponent },
  { path: 'Search', component: SearchComponent },
  { path: 'House/:houseId', component: HousePageComponent },
  { path: 'House/:houseId/:houseName', component: HousePageComponent },
  { path: 'Sell', component: SellComponent },
  { path: 'NotResultsFound/:typeNotFound', component: NotResultsFoundComponent },
  { path: 'NotResultsFound', component: NotResultsFoundComponent },
  { path: 'Login', component: LogInComponent, canActivate: [CanActivateIsNotLoggedService] },
  { path: 'Registro', component: RegistroComponent, canActivate: [CanActivateIsNotLoggedService] },
  { path: 'RecuperarPassword', component: RequestPasswordComponent, canActivate: [CanActivateIsNotLoggedService] },
  { path: 'Favorites', component: FavoritesComponent, canActivate: [CanActivateIsLoggedService] },
  { path: 'SetPassword/:uid/:token', component: SetPasswordComponent, canActivate: [CanActivateIsNotLoggedService] },
  { path: 'Dashboard', component: DashboardPageComponent, canActivate: [CanActivateIsLoggedService] },
  { path: 'test', component: TestsComponent, canActivate: [CanActivateIsLoggedService] },
  { path: '', redirectTo: 'Home', pathMatch: 'full' },
  { path: '**', redirectTo: 'NotResultsFound', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabledBlocking' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
