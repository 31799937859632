import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { House } from 'src/app/models/House';

@Component({
  selector: 'app-prospecto-comprador-modal',
  templateUrl: './ProspectoCompradorModal.component.html',
  styleUrls: ['./ProspectoCompradorModal.component.scss']
})
export class ProspectoCompradorModalComponent implements OnInit {
  house: House = null;
  constructor(public dialogRef: MatDialogRef<ProspectoCompradorModalComponent>, @Inject(MAT_DIALOG_DATA) public data: House) {
    this.house = data;
  }

  ngOnInit() {
  }
  closeModal() {
    this.dialogRef.close();
  }
}
