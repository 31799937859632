<div *ngIf="category; else isRoute" class="centerMiddle" [ngClass]="{hInitial: hInitial}">
  <div>
    <h1 class="mw100vw">
      <b>
        {{category === HouseCategory.Buy ? '¡No hay resultados para venta con estas características!' : category === HouseCategory.Lease ? '¡No hay resultados para renta con estas características!' : category === HouseCategory.Favorites ? '¡No tienes casas guardadas como favoritas!' : category === HouseCategory.Mines ? '¡No tienes casas publicadas!' : '¡No hay resultados!'}}
      </b>
    </h1>
    <img class="mw100vw" src="/assets/Error.jpg">
  </div>
</div>
<ng-template #isRoute>
  <div class="fullContainer posRelative textCenter">
    <app-navbar [ShowExpanded]="false" [ShowSearchBar]="true"></app-navbar>
    <div [ngClass]="{hInitial: hInitial}">
      <div>
        <h1 class="mw100vw">
          <b>
            {{category === HouseCategory.Buy ? '¡No hay resultados para venta con estas características!' : category === HouseCategory.Lease ? '¡No hay resultados para renta con estas características!' : category === HouseCategory.Favorites ? '¡No tienes casas guardadas como favoritas!' : category === HouseCategory.Mines ? '¡No tienes casas publicadas!' : '¡No hay resultados!'}}
          </b>
        </h1>
        <img class="mw100vw" src="/assets/Error.jpg">
      </div>
    </div>
  </div>
</ng-template>


