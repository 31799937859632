import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, OnDestroy, ViewChild, ChangeDetectorRef, Inject, HostListener, AfterViewInit } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { environment } from 'src/environments/environment';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { SelectValues } from 'src/app/models/SelectValues';
import { AppStateService } from 'src/app/services/Store/AppState.service';
import { Subscription } from 'rxjs';
import { Search } from 'src/app/models/Search';
import { Utils } from 'src/app/Utils/Utils';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSliderChange } from '@angular/material/slider';
@Component({
  selector: 'app-filters',
  templateUrl: './Filters.component.html',
  styleUrls: ['./Filters.component.scss']
})
export class FiltersComponent implements OnDestroy, AfterViewInit {
  @ViewChild('placesRef2') placesRef: GooglePlaceDirective;
  Lat = 0;
  Lng = 0;
  form: UntypedFormGroup = null;
  options: any = {
    types: [],
    componentRestrictions: { country: environment.countryForSearchGGMaps }
  };
  filters = '';
  TiposInmuebles: SelectValues[] = [];
  sub: Subscription = null;
  selectedAnyTipoInm = true;
  MinPriceDisplayValue = '';
  MaxPriceDisplayValue = '';
  minPrice = 0;
  maxPrice = 0;
  Loading = true;
  ButtonsTop = false;
  // tslint:disable-next-line: max-line-length
  constructor(private fb: UntypedFormBuilder, private appstate: AppStateService, public dialogRef: MatDialogRef<FiltersComponent>, @Inject(MAT_DIALOG_DATA) public data: Search, private cd: ChangeDetectorRef) {
    this.sub = this.appstate.TiposInmuebles.subscribe(res => {
      if (res && res.length > 0) {
        this.TiposInmuebles = res.map(ti => ({Name: ti.nombre, Value: ti.id} as SelectValues));
      }
    });
    this.init(false);
  }
  ngAfterViewInit(): void {
    this.onResize();
  }
  @HostListener('window:resize')
  onResize() {
    this.ButtonsTop = document.getElementById('fullContainer').offsetWidth > 400;
    this.cd.detectChanges();
  }
  init(reset: boolean = true) {
    let form1Object: Search = null;
    let TiposInmuebles: number[] = [-1];
    if (reset) {
      form1Object = new Search();
    } else {
      form1Object = Utils.CloneObject(this.data);
      TiposInmuebles = this.data.TiposInmuebles;
      form1Object.TiposInmuebles = [-1];
    }
    this.form = this.fb.group(form1Object);
    this.form.controls.TiposInmuebles.setValue(TiposInmuebles);
    this.minPrice = form1Object.MinPrice;
    this.maxPrice = form1Object.MaxPrice;
    this.MinPriceDisplayValue = this.formatLabel((form1Object.MinPrice).toString());
    this.MaxPriceDisplayValue = this.formatLabel((form1Object.MaxPrice).toString());
    this.Loading = false;
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  TiposInmueblesChanged() {
    this.ModInput('TiposInmuebles');
    const selecteds = (this.form.controls.TiposInmuebles.value as number[]);
    if (selecteds && selecteds.some(t => t === -1) && selecteds.length > 1 && !this.selectedAnyTipoInm) {
      this.selectedAnyTipoInm = true;
      this.form.controls.TiposInmuebles.setValue([-1]);
    } else if (selecteds && selecteds.some(t => t === -1) && selecteds.length > 1) {
      this.selectedAnyTipoInm = false;
      selecteds.shift();
      this.form.controls.TiposInmuebles.setValue(selecteds);
    }
  }
  handleAddressChange(address: Address) {
    this.ModInput('LatLng');
    if (address.name !== '' && address?.geometry) {
      this.Lat = address.geometry.location.lat();
      this.Lng = address.geometry.location.lng();
    } else {
      this.Lat = null;
      this.Lng = null;
    }
  }
  setValueMinMax(change: MatSliderChange, source: number) {
    if (source === 1) {
      const min = change.value;
      this.minPrice = Number(min);
      this.MinPriceDisplayValue = this.formatLabel(min.toString());
      this.ModInput('MinPrice');
    } else if (source === 2) {
      const max = change.value;
      this.maxPrice = Number(max);
      this.MaxPriceDisplayValue = this.formatLabel(max.toString());
      this.ModInput('MaxPrice');
    }
  }
  formatLabel(value: string | number): string {
    value = Number(value);
    if (value === 3020000) {
      return 'Más de 3 millones';
    }
    if (value === 80000) {
      return (0).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    }
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }
  ModInput(key: string) {
    this.form.controls['mod' + key].setValue(true);
  }
  Aceptar() {
    const res: Search = this.form.value;
    this.dialogRef.close(res);
  }
}
