<div class="houseCard" *ngIf="House && !isMobile">
  <app-images-carousel class="houseCarousel" [ForceWH]="true" [Infinite]="true" [BorderRadious]="'3px'" [Pager]="false" [ShowIndex]="false" [Type]="1" [ShowArrows]="false" [Width]="'246px'" [Height]="'212px'" [Images]="House?.imagenesToShow" *ngIf="House?.imagenesToShow?.length > 0 && !loading"></app-images-carousel>
  <a href="/#/House/{{House.id}}/{{House.TituloUrl}}" target="_blank" class="cardTextSection fakeA">
    <div class="titleContainer">
      <div class="cardTitle" [matTooltip]="House?.titulo">
        {{House?.ShortTitle}}
      </div>
      <div *ngIf="CurrentUser">
        <mat-icon class="fakeUrl likeIcon" [class.spinner]="likeLoading" (click)="setLike($event)" *ngIf="House.is_liked">favorite</mat-icon>
        <mat-icon class="fakeUrl likeIconDisabled" [class.spinner]="likeLoading" (click)="setLike($event)" *ngIf="!House.is_liked">favorite_border</mat-icon>
      </div>
    </div>
    <div class="cardSubtitle" [matTooltip]="House?.direccion">{{House?.ShortDir}}</div>
    <div class="cardDesc" [matTooltip]="House?.descripcion">{{House?.ShortDesc}}</div>
    <div class="cardPrice">{{House?.precio | currency:'USD':'symbol'}} {{House?.moneda}}</div>
    <div class="cardIconsContainer">
      <div class="cardIconCont">
        <mat-icon class="cardIcon" svgIcon="bed"></mat-icon>
        <div class="cardIconText">{{House.recamaras}} hab</div>
      </div>
      <div class="cardIconCont">
        <mat-icon class="cardIcon" svgIcon="bath"></mat-icon>
        <div class="cardIconText">{{House.banos}}{{House?.medios_banos > 0 ? '.5' : ''}} baños</div>
      </div>
      <div class="cardIconCont">
        <mat-icon class="cardIcon" svgIcon="expand"></mat-icon>
        <div class="cardIconText">{{House.m_2}} m<sup>2</sup></div>
      </div>
    </div>
  </a>
</div>
<div class="houseCardMobile" *ngIf="House && isMobile">
  <app-images-carousel class="houseCarousel" [ForceWH]="true" [Infinite]="true" [BorderRadious]="'0px'" [Pager]="true" [ShowIndex]="false" [Type]="1" [ShowArrows]="false" [Width]="'100%'" [Height]="'213px'" [Images]="House?.imagenesToShow" *ngIf="House?.imagenesToShow?.length > 0 && !loading"></app-images-carousel>
  <div class="sm-plr15px">
    <a href="/#/House/{{House.id}}/{{House.TituloUrl}}" target="_blank" class="cardTextSection fakeA cardTextSectionMobile">
      <div class="titleContainer titleContainerMobile">
        <div class="cardTitle" [matTooltip]="House?.titulo">
          {{House?.ShortTitle}}
        </div>
        <div *ngIf="CurrentUser">
          <mat-icon class="fakeUrl likeIcon" [class.spinner]="likeLoading" (click)="setLike($event)" *ngIf="House.is_liked">favorite</mat-icon>
          <mat-icon class="fakeUrl likeIconDisabled" [class.spinner]="likeLoading" (click)="setLike($event)" *ngIf="!House.is_liked">favorite_border</mat-icon>
        </div>
      </div>
      <div class="cardSubtitle" [matTooltip]="House?.direccion">{{House?.ShortDir}}</div>
      <div class="cardDesc" [matTooltip]="House?.descripcion">{{House?.ShortDesc}}</div>
      <div class="cardPrice">{{House?.precio | currency:'USD':'symbol'}} {{House?.moneda}}</div>
      <div class="cardIconsContainer">
        <div class="cardIconCont">
          <mat-icon class="cardIcon" svgIcon="bed"></mat-icon>
          <div class="cardIconText">{{House.recamaras}} hab</div>
        </div>
        <div class="cardIconCont">
          <mat-icon class="cardIcon" svgIcon="bath"></mat-icon>
          <div class="cardIconText">{{House.banos}}{{House?.medios_banos > 0 ? '.5' : ''}} baños</div>
        </div>
        <div class="cardIconCont">
          <mat-icon class="cardIcon" svgIcon="expand"></mat-icon>
          <div class="cardIconText">{{House.m_2}} m<sup>2</sup></div>
        </div>
      </div>
    </a>
  </div>
</div>