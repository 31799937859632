<div class="searchBarContainer" [ngClass]="{bgfafafa: !isLoginURL, bgfbfbfb: isLoginURL}" id="searchHouseComponent">
  <div class="searchBar d-flex" [ngClass]="{expanded: ShowExpanded}">
    <div class="searchLeft h100pr ml24px display-flex-center-left">
      <a class="display-flex-center-left a-black-text-decoration-none zi10" routerLink="/">
        <img class="SoloLogo" src="/assets/LogoAzulNoFondo.png" alt="">
        <div class="boldText">Propefy</div>
      </a>
      <div class="w100pr pl4pr hide-sm" *ngIf="localShowSearchBar">
        <form [formGroup]="form" *ngIf="form" (submit)="searchValue()">
          <div class="d-flex">
            <mat-form-field class="input1 customInputNoMP" appearance="outline">
              <mat-select (selectionChange)="onChangeBuyLease($event)" formControlName="OptionsSelectBuyLease">
                <mat-option *ngFor="let item of optionsBuyLease" [value]="item.type">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="input2 customInputNoMP" appearance="outline">
              <input ngx-google-places-autocomplete #placesRef="ngx-places" placeholder="Tu ubicación ideal va aquí" [options]="options" (onAddressChange)="handleAddressChange($event)" class="custom-white" formControlName="Search" #search id="search" autocomplete="off" matInput>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
    <div class="searchRight h100pr">
      <div class="mr24px dflexCenterRight">
        <div class="ingresaBtnCont">
          <button mat-stroked-button (click)="login()" *ngIf="!(user | async)" class="primaryStrokedBtn">Ingresa</button>
          <button mat-button class="nameButton" [matMenuTriggerFor]="menu" *ngIf="user | async">
            <div class="cardIconCont">
              <span>{{(user | async)?.name}}</span>
              <mat-icon class="arrowIcon" svgIcon="mini_down_blue_arrow"></mat-icon>
            </div>
          </button>
          <mat-menu #menu="matMenu">
            <a mat-menu-item routerLink="/Dashboard">Mi perfil</a>
            <button mat-menu-item (click)="logout()">Cerrar sesión</button>
          </mat-menu>
        </div>
        <div class="publicaBtnCont">
          <button mat-stroked-button (click)="showPublicaPropiedad()" class="degradadoStrokedBtn hide-sm">Publica Tu Propiedad</button>
          <button mat-stroked-button (click)="showPublicaPropiedad()" class="degradadoStrokedBtn show-sm">Publicar</button>
        </div>
      </div>
    </div>
    <div class="searchRight2 h100pr">
      <div class="mr24px mr5px-sm dflexCenterRight">
        <button mat-button class="menuBtn" [matMenuTriggerFor]="menu2">
          <mat-icon class="menuIcon">menu</mat-icon>
        </button>
        <mat-menu #menu2="matMenu">
          <ng-container *ngIf="!(user | async); else elseTemplate">
            <button mat-menu-item (click)="login()">Ingresa</button>
          </ng-container>
          <ng-template #elseTemplate>
            <a mat-menu-item routerLink="/Dashboard">Mi perfil</a>
            <button mat-menu-item (click)="logout()">Cerrar sesión</button>
          </ng-template>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="searchBarFiltersContainer" *ngIf="ShowExpanded">
    <app-filteritem *ngFor="let item of chips; let i=index" [item]="item"></app-filteritem>
  </div>
  <div class="w100pr show-sm row" *ngIf="localShowSearchBar">
    <div class="col-12">
      <form [formGroup]="form" *ngIf="form" (submit)="searchValue()">
        <div class="d-flex">
          <mat-form-field class="input1 input1sm customInputNoMP" appearance="outline">
            <mat-select (selectionChange)="onChangeBuyLease($event)" formControlName="OptionsSelectBuyLease">
              <mat-option *ngFor="let item of optionsBuyLease" [value]="item.type">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="input2 input2sm customInputNoMP" appearance="outline">
            <input ngx-google-places-autocomplete #placesRef="ngx-places" placeholder="Buscar" [options]="options" (onAddressChange)="handleAddressChange($event)" class="custom-white" formControlName="Search" #search id="search" autocomplete="off" matInput>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
</div>