import { Usuario } from 'src/app/models/Usuario';
import { Component, Input, AfterViewInit, ChangeDetectorRef, HostListener, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { House } from 'src/app/models/House';
import { AppStateService } from 'src/app/services/Store/AppState.service';
import { Utils } from 'src/app/Utils/Utils';
import { RepositoryService } from 'src/app/services/Repository/Repository.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-house-item',
  templateUrl: './HouseItem.component.html',
  styleUrls: ['./HouseItem.component.scss']
})
export class HouseItemComponent implements AfterViewInit, OnDestroy {
  @Input() House: House = null;
  subs: Subscription = null;
  CurrentUser: Usuario = null;
  loading = false;
  isMobile: boolean = null;
  likeLoading = false;
  // TestingCarouselImages = [
  //   '/assets/FotoBackground.jpg',
  //   '/assets/FotoBackground2.jpg',
  //   '/assets/FotoBackground3.png',
  // ];
  // tslint:disable-next-line: max-line-length
  constructor(private cd: ChangeDetectorRef, private assSelector: AppStateService, private crud: RepositoryService, private platform: Platform) {
    this.subs = this.assSelector.CurrentUser.subscribe(user => {
      if (!this.CurrentUser && Utils.IsLogged(user)) {
        this.CurrentUser = user;
      } else if (this.CurrentUser) {
        this.CurrentUser = null;
      }
    });
  }
  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
  @HostListener('window:resize')
  public detectResize(): void {
    this.isMobile = this.platform.is('capacitor') || document.getElementById('fullContainer').offsetWidth <  992;
    this.House.imagenesToShow = this.House.imagenes_set.map(img => img.thumbnail);
  }
  ngAfterViewInit() {
    this.detectResize();
    this.cd.detectChanges();
  }
  setLike(event) {
    console.log(event);
    event.preventDefault();
    this.likeLoading = true;
    Utils.firstValueFrom(this.crud.PatchPetition('inmueble/like/inmuebles/' + this.House.id + '/')).then((res: House) => {
      this.House.is_liked = res.is_liked;
      this.likeLoading = false;
    });
  }
}
