<div class="loginContainer">
  <a
    class="display-flex-center-left a-black-text-decoration-none pb24px"
    routerLink="/"
  >
    <img class="SoloLogo" src="/assets/LogoAzulNoFondo.png" alt="" />
    <div class="boldText">Propefy</div>
  </a>
  <div class="loginCard">
    <form
      [formGroup]="formLogin"
      class="frm"
      *ngIf="!loadingInit"
      (ngSubmit)="login()"
    >
      <div class="loginFormContainer">
        <div class="loginWelcomeText">¡Hola de nuevo!</div>
        <div class="loginWelcomeText2 pb24px">Ingresa a tu Cuenta</div>
        <mat-form-field
          class="w100pr pb24px custom-semi-oval-mat-form-field custom-blue-mat-form-field"
          appearance="outline"
        >
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" autocomplete="on" required />
          <mat-error *ngIf="formLogin?.controls?.email?.errors?.required"
            >Por favor ingresa un email válido</mat-error
          >
        </mat-form-field>
        <mat-form-field
          class="w100pr pb24px custom-semi-oval-mat-form-field custom-blue-mat-form-field"
          appearance="outline"
        >
          <mat-label>Contraseña</mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            autocomplete="on"
            required
          />
          <button
            mat-icon-button
            tabindex="-1"
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            type="button"
            [attr.aria-pressed]="hide"
          >
            <mat-icon class="eye-icon" *ngIf="hide">visibility</mat-icon>
            <mat-icon class="eye-icon" *ngIf="!hide">visibility_off</mat-icon>
          </button>
          <mat-error *ngIf="formLogin?.controls?.password?.errors?.required">{{
            Utils.datoRequerido
          }}</mat-error>
        </mat-form-field>
        <div class="forgot-password-container">
          <a
            routerLink="/RecuperarPassword"
            tabindex="-1"
            class="forgot-password mb32px"
            >¿Olvidaste tu Contraseña?</a
          >
        </div>
        <button
          mat-flat-button
          class="button btnBlue2 mb24px"
          [class.spinner]="loading"
          [disabled]="loading"
          type="submit"
        >
          Continuar
        </button>
        <div class="fake-hr mb24px"></div>
        <div class="login-alternative-text mb24px">O ingresa con</div>
        <div class="alternative-logins-container mb24px">
          <button class="social-media-login-button" disabled>
            <img src="/assets/icons/google.svg" class="social-media-login" />
          </button>
          <button class="social-media-login-button" disabled>
            <img src="/assets/icons/facebook.svg" class="social-media-login" />
          </button>
          <button class="social-media-login-button" disabled>
            <img src="/assets/icons/twitter.svg" class="social-media-login" />
          </button>
        </div>
        <div class="contact-us-register-container">
          ¿Aún no tienes cuenta? &nbsp;
          <a routerLink="/Registro" class="forgot-password">Regístrate</a>
        </div>
      </div>
    </form>
  </div>
</div>
