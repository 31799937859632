import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FilterType } from 'src/app/models/FiltersChips';

@Component({
  selector: 'app-custom-cb',
  templateUrl: './customCb.component.html',
  styleUrls: ['./customCb.component.scss']
})
export class CustomCbComponent implements OnInit {
  @Input() isMobile = false;
  @Input() checked = false;
  @Input() text: string = null;
  @Input() value: number;
  @Input() type: FilterType;
  @Output() RbOnClick: EventEmitter<CustomCbComponent> = new EventEmitter();
  constructor() {
  }
  onClickCB() {
    this.RbOnClick.emit(this as CustomCbComponent);
  }
  ngOnInit() {
  }

}
