import { RepositoryService } from './../../../services/Repository/Repository.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProspectoVendedor } from 'src/app/models/ProspectoVendedor';
import { Utils } from 'src/app/Utils/Utils';

@Component({
  selector: 'app-publica-tu-propiedad',
  templateUrl: './publica-tu-propiedad.component.html',
  styleUrls: ['./publica-tu-propiedad.component.scss'],
})
export class PublicaTuPropiedadComponent implements OnInit {
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  Utils = Utils;
  form: UntypedFormGroup = null;
  loading = false;
  constructor(private fb: UntypedFormBuilder, private crud: RepositoryService) { }
  ngOnInit() {
    const data: ProspectoVendedor = new ProspectoVendedor();
    this.form = this.fb.group(data);
    this.form.controls.nombre.setValidators([Validators.required]);
    this.form.controls.correo.setValidators([Validators.required, Validators.pattern(Utils.emailPattern)]);
    // tslint:disable-next-line: max-line-length
    this.form.controls.interested_phone_number.setValidators([Validators.required, Validators.pattern('[0-9]+'), Validators.maxLength(10), Validators.minLength(10)]);
  }
  submitInterested() {
    this.loading = true;
    const body: ProspectoVendedor = this.form.value;
    Utils.firstValueFrom(this.crud.PostPetition('inmueble/prospecto/vendedor/', body, false, true)).then(res => {
      this.loading = false;
      this.closeModal.emit();
    }).catch(err => {
      this.loading = false;
    });
  }
}
