import { Router } from '@angular/router';
import { LatLng } from 'src/app/models/LatLng';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Utils } from './../../../Utils/Utils';
import { AppStateService } from 'src/app/services/Store/AppState.service';
import { Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Search } from 'src/app/models/Search';
import { TipoInmuebles } from 'src/app/models/TipoInmuebles';
import { environment } from 'src/environments/environment';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { OptionsSelectBuyLease } from 'src/app/models/OptionsSelectBuyLease';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy {
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  optionsBuyLease: OptionsSelectBuyLease[] = [
    {
      type: 1,
      url: 'Buy',
      name: 'Comprar'
    },
    {
      type: 2,
      url: 'Lease',
      name: 'Rentar'
    }
  ];
  form: UntypedFormGroup = null;
  tiposInmuebles: Observable<TipoInmuebles[]> = this.appStateS.TiposInmuebles.pipe(map(item => Utils.CloneObject(item)));
  options: Options = new Options({
    types: [],
    componentRestrictions: { country: environment.countryForSearchGGMaps }
  });
  Address: Address = null;
  constructor(private appStateS: AppStateService, private fb: UntypedFormBuilder, private router: Router) {
    const data: FormHomeSearch = new FormHomeSearch();
    this.form = this.fb.group(data);
    this.form.controls.TipoInmueble.addValidators([Validators.required]);
    this.form.controls.OptionsSelectBuyLease.addValidators([Validators.required]);
  }
  executeSearch(): void {
    const LatLngBL: LatLng = this.Address?.geometry?.viewport?.getNorthEast()?.lat() ? {
      Lat: this.Address.geometry.viewport.getSouthWest().lat(),
      Lng: this.Address.geometry.viewport.getSouthWest().lng()
    } : null;
    const LatLngTR: LatLng = LatLngBL !== null ? {
      Lat: this.Address.geometry.viewport.getNorthEast().lat(),
      Lng: this.Address.geometry.viewport.getNorthEast().lng()
    } : null;
    const search: Search = new Search();
    search.LatBL = LatLngBL.Lat;
    search.LngBL = LatLngBL.Lng;
    search.LatTR = LatLngTR.Lat;
    search.LngTR = LatLngTR.Lng;
    search.placeId = LatLngBL ? this.Address.place_id : null;
    search.OptionsSelectBuyLease = this.form.controls.OptionsSelectBuyLease.value;
    search.SearchStr = this.form.controls.Search.value;
    search.TiposInmuebles = [
      this.form.controls.TipoInmueble.value
    ];
    this.router.navigateByUrl('/Search' + Utils.encodeObject(search));
  }
  searchValue(): void {
    if (this.Address?.geometry?.viewport?.getNorthEast()?.lat()) {
      this.executeSearch();
    } else {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({address: this.form.controls.Search.value}, (res: google.maps.GeocoderResult[]) => {
        if (res?.length > 0) {
          this.Address = (res[0] as any) as Address;
          this.executeSearch();
        }
      });
    }
  }
  handleAddressChange(address: Address) {
    if (address.name !== '' && address?.geometry) {
      this.Address = address;
      this.form.controls.Search.setValue(this.Address.formatted_address);
    } else {
      this.Address = null;
    }
  }
  ngOnDestroy(): void {
  }
}
class FormHomeSearch {
  OptionsSelectBuyLease = 1;
  TipoInmueble = -1;
  Search: string = null;
}
