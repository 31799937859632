import { RepositoryService } from 'src/app/services/Repository/Repository.service';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, QueryList, ViewChildren, AfterContentInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, skipWhile, tap, take } from 'rxjs/operators';
import { AmenidadesCB, FormConstruccionTerrenoPrecio, TipoInmueblesCB } from 'src/app/models/Filters';
import { AppStateService } from 'src/app/services/Store/AppState.service';
import { Utils } from 'src/app/Utils/Utils';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeContext, LabelType, Options } from '@angular-slider/ngx-slider';
import { SliderComponent } from '@angular-slider/ngx-slider/slider.component';
import { FilterType } from 'src/app/models/FiltersChips';
import { CustomCbComponent } from '../CustomCb/customCb.component';
import * as alertify from 'alertifyjs';
import { UtilService } from 'src/app/services/UtilService.service';
import { Search } from 'src/app/models/Search';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterContentInit {
  @ViewChildren(CustomCbComponent) cbComponents!: QueryList<CustomCbComponent>;
  @ViewChildren('priceSlider') priceSlider: QueryList<SliderComponent>;
  Utils = Utils;
  FilterType = FilterType;
  Search: Search = null;
  manualRefreshPriceSlider: EventEmitter<void> = new EventEmitter();
  minHabitaciones: number = null;
  maxHabitaciones: number = null;
  minAntiguedad: number = null;
  maxAntiguedad: number = null;
  minBanos: number = null;
  maxBanos: number = null;
  minEstacionamientos: number = null;
  maxEstacionamientos: number = null;
  formConstruccion: UntypedFormGroup = null;
  formTerreno: UntypedFormGroup = null;
  formPrecio: UntypedFormGroup = null;
  currentConstruccion = new FormConstruccionTerrenoPrecio();
  currentTerreno = new FormConstruccionTerrenoPrecio();
  currentPrecio = new FormConstruccionTerrenoPrecio();
  timeOutConstruccion: NodeJS.Timeout = null;
  timeOutTerreno: NodeJS.Timeout = null;
  timeOutPrecio: NodeJS.Timeout = null;
  // tslint:disable-next-line: max-line-length
  tiposInmuebles: Observable<TipoInmueblesCB[]> = null;
  BaseTiposInmuebles: Observable<TipoInmueblesCB[]> = this.appStateS.TiposInmuebles
  .pipe(
    skipWhile(data => data.length === 0)
  ).pipe(map(item => (Utils.CloneObject(item) as TipoInmueblesCB[])));
  Amenidades: AmenidadesCB[] = [];
  minValuePrice = 0;
  maxValuePrice = 25000000 ;
  options: Options = {
    step: 1000,
    floor: 0,
    ceil: 25000000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<div class="custom-price-chip"><b>Min:</b> $' + value + '</div>';
        case LabelType.High:
          return '<div class="custom-price-chip"><b>Max:</b> $' + value + '</div>';
        default:
          return '$' + value;
      }
    }
  };
  // tslint:disable-next-line: max-line-length
  constructor(private route: ActivatedRoute, private appStateS: AppStateService, private cd: ChangeDetectorRef, private router: Router, private fb: UntypedFormBuilder, private crud: RepositoryService, private US: UtilService) {
    setTimeout(() => {
      this.updateMenuView();
    }, 0);
  }
  ngAfterContentInit(): void {
  }
  ngOnInit(): void {
    Utils.firstValueFrom(this.appStateS.Amenidades
      .pipe(
        skipWhile(data => data.length === 0)
      ).pipe(
        map(items => ((Utils.CloneObject(items) as any) as AmenidadesCB[])),
      ).pipe(
        map(items => {
          for (const e of items) {
            e.checked = false;
          }
          return items;
        }),
      ).pipe(
        take(1)
      ).pipe(tap(items => {
        this.Amenidades = items;
        this.updateMenuView();
      })));
  }
  changeFocus(destinyId: string, event: any) {
    event.stopPropagation();
    const destiny = document.getElementById(destinyId);
    if (destiny) {
      destiny.focus();
    }
  }
  updateMenuView() {
    Utils.getSearchData(this.crud, this.route).then(search => {
      const defaultFiltersData = new Search();
      this.Search = search;
      if (this.Search?.TiposInmuebles?.length > 0) {
        this.BaseTiposInmuebles = this.BaseTiposInmuebles
        .pipe(
          tap(arr => arr.forEach(item => item.checked = this.Search.TiposInmuebles.some(id => id === item.id))));
      } else {
      // tslint:disable-next-line: max-line-length
        this.BaseTiposInmuebles = this.BaseTiposInmuebles
        .pipe(
          tap(arr => arr.forEach(item => item.checked = false)));
      }
      this.tiposInmuebles = this.BaseTiposInmuebles;
      if (this.Search.MaxRecamaras) {
        this.maxHabitaciones = this.Search.MaxRecamaras;
      }
      if (this.Search.MinRecamaras) {
        this.minHabitaciones = this.Search.MinRecamaras;
      }
      this.updateHabitacionesColor();
      if (this.Search.MaxBanos) {
        this.maxBanos = this.Search.MaxBanos;
      }
      if (this.Search.MinBanos) {
        this.minBanos = this.Search.MinBanos;
      }
      this.updateBanosColor();
      if (this.Search.MaxEstacionamientos) {
        this.maxEstacionamientos = this.Search.MaxEstacionamientos;
      }
      if (this.Search.MinEstacionamientos) {
        this.minEstacionamientos = this.Search.MinEstacionamientos;
      }
      this.updateEstacionamientosColor();
      const formConstruccion = new FormConstruccionTerrenoPrecio();
      if (this.Search.MinConstruccion) {
        formConstruccion.min = this.Search.MinConstruccion;
      }
      if (this.Search.MaxConstruccion) {
        formConstruccion.max = this.Search.MaxConstruccion;
      }
      this.currentConstruccion = formConstruccion;
      this.formConstruccion = this.fb.group(formConstruccion);
      this.updateConstruccionColor();
      this.cd.detectChanges();
      const formTerreno = new FormConstruccionTerrenoPrecio();
      if (this.Search.MinTerreno) {
        formTerreno.min = this.Search.MinTerreno;
      }
      if (this.Search.MaxTerreno) {
        formTerreno.max = this.Search.MaxTerreno;
      }
      this.currentTerreno = formTerreno;
      this.formTerreno = this.fb.group(formTerreno);
      this.updateTerrenoColor();
      this.cd.detectChanges();
      if (this.Search?.Amenidades?.length > 0) {
        for (const item of this.Amenidades) {
          item.checked = this.Search.Amenidades.some(id => id === item.id);
        }
      } else {
        for (const item of this.Amenidades) {
          item.checked = false;
        }
      }
      if (this.Search.MaxAntiguedad) {
        this.maxAntiguedad = this.Search.MaxAntiguedad;
      }
      if (this.Search.MinAntiguedad) {
        this.minAntiguedad = this.Search.MinAntiguedad;
      }
      this.updateAntiguedadColor();
      const formPrecio = new FormConstruccionTerrenoPrecio();
      if (this.Search.MinPrice) {
        formPrecio.min = this.Search.MinPrice;
      }
      if (this.Search.MaxPrice) {
        formPrecio.max = this.Search.MaxPrice;
      }
      this.currentPrecio = formPrecio;
      this.formPrecio = this.fb.group(formPrecio);
      this.minValuePrice = formPrecio.min;
      this.maxValuePrice = formPrecio.max;
      this.updatePrecioColor();
      this.cd.detectChanges();
      this.priceSlider.forEach(s => {
        this.options.maxLimit = defaultFiltersData.MaxPrice;
        s.options = this.options;
        s.manualRefresh = this.manualRefreshPriceSlider;
        setTimeout(() => {
          setTimeout(() => {
            this.manualRefreshPriceSlider.emit();
          }, 150);
        }, 0);
      });
      this.cd.detectChanges();
    });
  }
  changeFormConstruccion(changedMin: boolean) {
    if (this.formConstruccion.controls.min.value === '' || !this.formConstruccion.controls.min.value) {
      this.formConstruccion.controls.min.setValue(null);
    } else {
      this.formConstruccion.controls.min.setValue(Number(this.formConstruccion.controls.min.value));
    }
    if (this.formConstruccion.controls.max.value === '' || !this.formConstruccion.controls.max.value) {
      this.formConstruccion.controls.max.setValue(null);
    } else {
      this.formConstruccion.controls.max.setValue(Number(this.formConstruccion.controls.max.value));
    }
    const formConstruccion: FormConstruccionTerrenoPrecio = this.formConstruccion.value;
    if (formConstruccion.max === this.currentConstruccion.max && formConstruccion.min === this.currentConstruccion.min) {
      return;
    }
    this.currentConstruccion = formConstruccion;
    if (this.timeOutConstruccion !== null) {
      clearTimeout(this.timeOutConstruccion);
      this.timeOutConstruccion = null;
    }
    this.timeOutConstruccion = setTimeout(() => {
      if (changedMin && this.currentConstruccion.min > this.currentConstruccion.max && this.currentConstruccion.max) {
        alertify.notify('¡Hey! tu valor de construcción mínimo es mayor al máximo, se ha cancelado tu máximo.', 'info', 5);
        this.currentConstruccion.max = null;
        this.formConstruccion.controls.max.setValue(null);
      } else if (!changedMin && this.currentConstruccion.min > this.currentConstruccion.max && this.currentConstruccion.max) {
        alertify.notify('¡Hey! tu valor de construcción máximo es menor al mínimo, se ha cancelado tu mínimo.', 'info', 5);
        this.currentConstruccion.min = null;
        this.formConstruccion.controls.min.setValue(null);
      } else if (changedMin && this.currentConstruccion.min === this.currentConstruccion.max && this.currentConstruccion.min) {
        alertify.notify('¡Hey! tu valor de construcción mínimo es igual al máximo, se ha cancelado tu máximo.', 'info', 5);
        this.currentConstruccion.max = null;
        this.formConstruccion.controls.max.setValue(null);
      } else if (!changedMin && this.currentConstruccion.min === this.currentConstruccion.max && this.currentConstruccion.min) {
        alertify.notify('¡Hey! tu valor de construcción máximo es igual al mínimo, se ha cancelado tu mínimo.', 'info', 5);
        this.currentConstruccion.min = null;
        this.formConstruccion.controls.min.setValue(null);
      }
      this.updateConstruccionColor();
      this.commit();
    }, 750);
  }
  updateConstruccionColor() {
    if (this.currentConstruccion.min || this.currentConstruccion.max) {
      let name = '';
      if (this.currentConstruccion.min) {
        name = 'Desde ' + this.currentConstruccion.min.toString() + 'm<sup>2</sup>';
      }
      if (this.currentConstruccion.max) {
        if (name === '') {
          name = 'Hasta ' + this.currentConstruccion.max.toString() + 'm<sup>2</sup>';
        } else {
          name = name + ' hasta ' + this.currentConstruccion.max.toString() + 'm<sup>2</sup>';
        }
      }
    }
  }
  updateTerrenoColor() {
    if (this.currentTerreno.min || this.currentTerreno.max) {
      let name = '';
      if (this.currentTerreno.min) {
        name = 'Desde ' + this.currentTerreno.min.toString() + 'm<sup>2</sup>';
      }
      if (this.currentTerreno.max) {
        if (name === '') {
          name = 'Hasta ' + this.currentTerreno.max.toString() + 'm<sup>2</sup>';
        } else {
          name = name + ' hasta ' + this.currentTerreno.max.toString() + 'm<sup>2</sup>';
        }
      }
    }
  }
  changeFormTerreno(changedMin: boolean) {
    if (this.formTerreno.controls.min.value === '' || !this.formTerreno.controls.min.value) {
      this.formTerreno.controls.min.setValue(null);
    } else {
      this.formTerreno.controls.min.setValue(Number(this.formTerreno.controls.min.value));
    }
    if (this.formTerreno.controls.max.value === '' || !this.formTerreno.controls.max.value) {
      this.formTerreno.controls.max.setValue(null);
    } else {
      this.formTerreno.controls.max.setValue(Number(this.formTerreno.controls.max.value));
    }
    const formTerreno: FormConstruccionTerrenoPrecio = this.formTerreno.value;
    if (formTerreno.max === this.currentTerreno.max && formTerreno.min === this.currentTerreno.min) {
      return;
    }
    this.currentTerreno = formTerreno;
    if (this.timeOutTerreno !== null) {
      clearTimeout(this.timeOutTerreno);
      this.timeOutTerreno = null;
    }
    this.timeOutTerreno = setTimeout(() => {
      if (changedMin && this.currentTerreno.min > this.currentTerreno.max && this.currentTerreno.max) {
        alertify.notify('¡Hey! tu valor de terreno mínimo es mayor al máximo, se ha cancelado tu máximo.', 'info', 5);
        this.currentTerreno.max = null;
        this.formTerreno.controls.max.setValue(null);
      } else if (!changedMin && this.currentTerreno.min > this.currentTerreno.max && this.currentTerreno.max) {
        alertify.notify('¡Hey! tu valor de terreno máximo es menor al mínimo, se ha cancelado tu mínimo.', 'info', 5);
        this.currentTerreno.min = null;
        this.formTerreno.controls.min.setValue(null);
      } else if (changedMin && this.currentTerreno.min === this.currentTerreno.max && this.currentTerreno.min) {
        alertify.notify('¡Hey! tu valor de terreno mínimo es igual al máximo, se ha cancelado tu máximo.', 'info', 5);
        this.currentTerreno.max = null;
        this.formTerreno.controls.max.setValue(null);
      } else if (!changedMin && this.currentTerreno.min === this.currentTerreno.max && this.currentTerreno.min) {
        alertify.notify('¡Hey! tu valor de terreno máximo es igual al mínimo, se ha cancelado tu mínimo.', 'info', 5);
        this.currentTerreno.min = null;
        this.formTerreno.controls.min.setValue(null);
      }
      this.updateTerrenoColor();
      this.commit();
    }, 750);
  }
  updatePrecioColor() {
    const defaultFiltersData = new Search();
    const currentPrecioMax = this.currentPrecio.max !== defaultFiltersData.MaxPrice ? this.currentPrecio.max : null;
    if (this.currentPrecio.min || currentPrecioMax) {
      let name = '';
      if (this.currentPrecio.min) {
        name = 'Desde ' + this.currentPrecio.min.toString() + 'm<sup>2</sup>';
      }
      if (currentPrecioMax) {
        if (name === '') {
          name = 'Hasta ' + currentPrecioMax.toString() + 'm<sup>2</sup>';
        } else {
          name = name + ' hasta ' + currentPrecioMax.toString() + 'm<sup>2</sup>';
        }
      }
    }
  }
  onChangePrice(range: ChangeContext) {
    this.formPrecio.controls.min.setValue(range.value);
    this.formPrecio.controls.max.setValue(range.highValue);
    this.changeFormPrecio(false, false);
  }
  changeFormPrecio(changedMin: boolean, updateSlider = true) {
    if (this.formPrecio.controls.min.value === '' || !this.formPrecio.controls.min.value) {
      this.formPrecio.controls.min.setValue(null);
    } else {
      this.formPrecio.controls.min.setValue(Number(this.formPrecio.controls.min.value));
    }
    if (this.formPrecio.controls.max.value === '' || !this.formPrecio.controls.max.value) {
      this.formPrecio.controls.max.setValue(null);
    } else {
      this.formPrecio.controls.max.setValue(Number(this.formPrecio.controls.max.value));
    }
    const formPrecio: FormConstruccionTerrenoPrecio = this.formPrecio.value;
    if (formPrecio.max === this.currentPrecio.max && formPrecio.min === this.currentPrecio.min) {
      return;
    }
    this.currentPrecio = formPrecio;
    if (this.timeOutPrecio !== null) {
      clearTimeout(this.timeOutPrecio);
      this.timeOutPrecio = null;
    }
    this.timeOutPrecio = setTimeout(() => {
      if (changedMin && this.currentPrecio.min > this.currentPrecio.max && this.currentPrecio.max) {
        alertify.notify('¡Hey! tu valor de precio mínimo es mayor al máximo, se ha cancelado tu máximo.', 'info', 5);
        this.currentPrecio.max = null;
        this.formPrecio.controls.max.setValue(null);
      } else if (!changedMin && this.currentPrecio.min > this.currentPrecio.max && this.currentPrecio.max) {
        alertify.notify('¡Hey! tu valor de precio máximo es menor al mínimo, se ha cancelado tu mínimo.', 'info', 5);
        this.currentPrecio.min = null;
        this.formPrecio.controls.min.setValue(null);
      } else if (changedMin && this.currentPrecio.min === this.currentPrecio.max && this.currentPrecio.min) {
        alertify.notify('¡Hey! tu valor de precio mínimo es igual al máximo, se ha cancelado tu máximo.', 'info', 5);
        this.currentPrecio.max = null;
        this.formPrecio.controls.max.setValue(null);
      } else if (!changedMin && this.currentPrecio.min === this.currentPrecio.max && this.currentPrecio.min) {
        alertify.notify('¡Hey! tu valor de precio máximo es igual al mínimo, se ha cancelado tu mínimo.', 'info', 5);
        this.currentPrecio.min = null;
        this.formPrecio.controls.min.setValue(null);
      }
      if (updateSlider) {
        this.minValuePrice = this.currentPrecio.min;
        this.maxValuePrice = this.currentPrecio.max;
      }
      this.updatePrecioColor();
      this.commit();
    }, 750);
  }
  habitacionesChanged(current: CustomCbComponent) {
    if (current.checked) {
      if (current.value === this.maxHabitaciones && current.value === this.minHabitaciones) {
        this.maxHabitaciones = null;
        this.minHabitaciones = null;
      } else if (current.value === this.maxHabitaciones) {
        this.maxHabitaciones = null;
      } else if (current.value === this.minHabitaciones) {
        this.minHabitaciones = null;
      } else {
        this.maxHabitaciones = current.value;
        this.minHabitaciones = current.value;
      }
    } else {
      if (!this.maxHabitaciones || current.value > this.maxHabitaciones) {
        this.maxHabitaciones = current.value;
      }
      if (!this.minHabitaciones || current.value < this.minHabitaciones) {
        this.minHabitaciones = current.value;
      }
    }
    this.updateHabitacionesColor();
    this.commit();
  }
  updateHabitacionesColor() {
    this.cbComponents.filter(e => e.type === FilterType.Habitaciones).forEach(e => {
      if (this.maxHabitaciones || this.minHabitaciones) {
        if (this.maxHabitaciones && this.minHabitaciones) {
          if (e.value >= this.minHabitaciones && e.value <= this.maxHabitaciones) {
            e.checked = true;
          } else {
            e.checked = false;
          }
        } else if (this.maxHabitaciones) {
          if (e.value !== this.maxHabitaciones) {
            e.checked = false;
          } else {
            e.checked = true;
          }
        } else if (this.minHabitaciones) {
          if (e.value !== this.minHabitaciones) {
            e.checked = false;
          } else {
            e.checked = true;
          }
        } else {
          e.checked = false;
        }
      } else {
        e.checked = false;
      }
    });
  }
  AntiguedadChanged(current: CustomCbComponent) {
    if (current.checked) {
      if (current.value === this.maxAntiguedad && current.value === this.minAntiguedad) {
        this.maxAntiguedad = null;
        this.minAntiguedad = null;
      } else if (current.value === this.maxAntiguedad) {
        this.maxAntiguedad = null;
      } else if (current.value === this.minAntiguedad) {
        this.minAntiguedad = null;
      } else {
        this.maxAntiguedad = current.value;
        this.minAntiguedad = current.value;
      }
    } else {
      if (!this.maxAntiguedad || current.value > this.maxAntiguedad) {
        this.maxAntiguedad = current.value;
      }
      if (!this.minAntiguedad || current.value < this.minAntiguedad) {
        this.minAntiguedad = current.value;
      }
    }
    this.updateAntiguedadColor();
    this.commit();
  }
  updateAntiguedadColor() {
    this.cbComponents.filter(e => e.type === FilterType.Antiguedad).forEach(e => {
      if (this.maxAntiguedad || this.minAntiguedad) {
        if (this.maxAntiguedad && this.minAntiguedad) {
          if (e.value >= this.minAntiguedad && e.value <= this.maxAntiguedad) {
            e.checked = true;
          } else {
            e.checked = false;
          }
        } else if (this.maxAntiguedad) {
          if (e.value !== this.maxAntiguedad) {
            e.checked = false;
          } else {
            e.checked = true;
          }
        } else if (this.minAntiguedad) {
          if (e.value !== this.minAntiguedad) {
            e.checked = false;
          } else {
            e.checked = true;
          }
        } else {
          e.checked = false;
        }
      } else {
        e.checked = false;
      }
    });
  }
  BanosChanged(current: CustomCbComponent) {
    if (current.checked) {
      if (current.value === this.maxBanos && current.value === this.minBanos) {
        this.maxBanos = null;
        this.minBanos = null;
      } else if (current.value === this.maxBanos) {
        this.maxBanos = null;
      } else if (current.value === this.minBanos) {
        this.minBanos = null;
      } else {
        this.maxBanos = current.value;
        this.minBanos = current.value;
      }
    } else {
      if (!this.maxBanos || current.value > this.maxBanos) {
        this.maxBanos = current.value;
      }
      if (!this.minBanos || current.value < this.minBanos) {
        this.minBanos = current.value;
      }
    }
    this.updateBanosColor();
    this.commit();
  }
  updateBanosColor() {
    this.cbComponents.filter(e => e.type === FilterType.Banos).forEach(e => {
      if (this.maxBanos || this.minBanos) {
        if (this.maxBanos && this.minBanos) {
          if (e.value >= this.minBanos && e.value <= this.maxBanos) {
            e.checked = true;
          } else {
            e.checked = false;
          }
        } else if (this.maxBanos) {
          if (e.value !== this.maxBanos) {
            e.checked = false;
          } else {
            e.checked = true;
          }
        } else if (this.minBanos) {
          if (e.value !== this.minBanos) {
            e.checked = false;
          } else {
            e.checked = true;
          }
        } else {
          e.checked = false;
        }
      } else {
        e.checked = false;
      }
    });
  }
  EstacionamientosChanged(current: CustomCbComponent) {
    if (current.checked) {
      if (current.value === this.maxEstacionamientos && current.value === this.minEstacionamientos) {
        this.maxEstacionamientos = null;
        this.minEstacionamientos = null;
      } else if (current.value === this.maxEstacionamientos) {
        this.maxEstacionamientos = null;
      } else if (current.value === this.minEstacionamientos) {
        this.minEstacionamientos = null;
      } else {
        this.maxEstacionamientos = current.value;
        this.minEstacionamientos = current.value;
      }
    } else {
      if (!this.maxEstacionamientos || current.value > this.maxEstacionamientos) {
        this.maxEstacionamientos = current.value;
      }
      if (!this.minEstacionamientos || current.value < this.minEstacionamientos) {
        this.minEstacionamientos = current.value;
      }
    }
    this.updateEstacionamientosColor();
    this.commit();
  }
  updateEstacionamientosColor() {
    this.cbComponents.filter(e => e.type === FilterType.Estacionamiento).forEach(e => {
      if (this.maxEstacionamientos || this.minEstacionamientos) {
        if (this.maxEstacionamientos && this.minEstacionamientos) {
          if (e.value >= this.minEstacionamientos && e.value <= this.maxEstacionamientos) {
            e.checked = true;
          } else {
            e.checked = false;
          }
        } else if (this.maxEstacionamientos) {
          if (e.value !== this.maxEstacionamientos) {
            e.checked = false;
          } else {
            e.checked = true;
          }
        } else if (this.minEstacionamientos) {
          if (e.value !== this.minEstacionamientos) {
            e.checked = false;
          } else {
            e.checked = true;
          }
        } else {
          e.checked = false;
        }
      } else {
        e.checked = false;
      }
    });
  }
  checkBoxClick(event: PointerEvent, item: TipoInmueblesCB) {
    const clear = item.id === -1 && !item.checked;
    this.tiposInmuebles = this.BaseTiposInmuebles.pipe(tap(elements => {
      if (item.id === -1 && item.checked) {
        return;
      }
      const elementref = elements.find(e => e.id === item.id);
      elementref.checked = !elementref.checked;
      if (clear) {
        for (const e of elements) {
          if (e.id !== -1) {
            e.checked = false;
          }
        }
      } else if (!elements.some(e => e.checked)) {
        elements.find(e => e.id === -1).checked = true;
      } else {
        elements.find(e => e.id === -1).checked = false;
      }
    }));
    setTimeout(() => {
      this.commit();
    }, 0);
  }
  changeAmenidades(event: PointerEvent, item: AmenidadesCB) {
    setTimeout(() => {
      const elementref = this.Amenidades.find(e => e.id === item.id);
      elementref.checked = !elementref.checked;
      this.commit();
    }, 0);
  }
  commit() {
    Utils.getSearchData(this.crud, this.route).then(async search => {
      this.Search.TiposInmuebles = (await Utils.firstValueFrom(this.tiposInmuebles)).filter(ti => ti.checked).map(e => e.id);
      this.Search.MaxRecamaras = this.maxHabitaciones;
      this.Search.MinRecamaras = this.minHabitaciones;
      this.Search.MaxBanos = this.maxBanos;
      this.Search.MinBanos = this.minBanos;
      this.Search.MaxEstacionamientos = this.maxEstacionamientos;
      this.Search.MinEstacionamientos = this.minEstacionamientos;
      const formConstruccion: FormConstruccionTerrenoPrecio = this.formConstruccion.value;
      this.Search.MinConstruccion = formConstruccion.min;
      this.Search.MaxConstruccion = formConstruccion.max;
      const formTerreno: FormConstruccionTerrenoPrecio = this.formTerreno.value;
      this.Search.MinTerreno = formTerreno.min;
      this.Search.MaxTerreno = formTerreno.max;
      this.Search.Amenidades = this.Amenidades.filter(ti => ti.checked).map(e => e.id);
      this.Search.MaxAntiguedad = this.maxAntiguedad;
      this.Search.MinAntiguedad = this.minAntiguedad;
      const formPrecio: FormConstruccionTerrenoPrecio = this.formPrecio.value;
      this.Search.MinPrice = formPrecio.min;
      this.Search.MaxPrice = formPrecio.max;
      this.router.navigateByUrl('/Search' + Utils.encodeObject(search));
    });
  }
  closeFilters() {
    this.US.ToggleSideBar.emit(false);
  }
  clearFilters() {
    this.Search = new Search();
    Utils.getSearchData(this.crud, this.route).then(async search => {
      this.router.navigateByUrl('/Search' + Utils.encodeObject(search));
      this.closeFilters();
    });
  }
}
