export class PaginationBase {
  count?: number;
  next?: string;
  previous?: string;
  results: any[];
}
export enum PaginationType {
  hidden = -1,
  opened = 15,
  closed = 7.5
}

export enum MyPanelWidthType {
  hidden = -1,
  opened = 18,
  closed = 2
}
