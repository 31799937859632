import { UtilService } from './services/UtilService.service';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { IonicModule } from '@ionic/angular';


import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { GoogleMapsModule } from '@angular/google-maps';
import { SwiperModule } from 'swiper/angular';


import { NgxsModule } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { AppState } from './services/Store/Reducer';


import {MatCardModule} from '@angular/material/card';
import {MatSidenavModule} from '@angular/material/sidenav';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatStepperModule} from '@angular/material/stepper';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatChipsModule} from '@angular/material/chips';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatBadgeModule} from '@angular/material/badge';


import { RepositoryService } from './services/Repository/Repository.service';
import { HomeComponent } from './Components/ControlSesiones/Home/home.component';
import { LogInComponent } from './Components/ControlSesiones/LogIn/LogIn.component';
import { RegistroComponent } from './Components/ControlSesiones/Registro/Registro.component';
import { RequestPasswordComponent } from './Components/ControlSesiones/RequestPassword/request-password.component';
import { SetPasswordComponent } from './Components/ControlSesiones/SetPassword/set-password.component';
import { LoadingComponent } from './Popup/loading/loading.component';
import { SidebarComponent } from './Common/sidebar/sidebar.component';
import { ImagesCarouselComponent } from './Common/ImagesCarousel/ImagesCarousel.component';
import { NavItemComponent } from './Common/NavItem/NavItem.component';
import { HouseItemComponent } from './Common/HouseItem/HouseItem.component';
import { SellComponent } from './Components/Sell/Sell.component';
import { NotResultsFoundComponent } from './Components/NotResultsFound/NotResultsFound.component';
import { Carousel1Component } from './Common/ImagesCarousel/Carousel1/Carousel1.component';
import { Carousel2Component } from './Common/ImagesCarousel/Carousel2/Carousel2.component';
import { FiltersComponent } from './Common/Filters/Filters.component';
import { FavoritesComponent } from './Components/Favorites/Favorites.component';
import { NavBarComponent } from './Common/NavBar/NavBar.component';
import { SearchComponent } from './Components/Search/search.component';
import { FilterItemComponent } from './Common/NavBar/FilterItem/FilterItem.component';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import * as $ from 'jquery';
import 'hammerjs';
import 'mousetrap';
import { MetrosScalePipe } from './Utils/MetrosScale.pipe';
import { CustomCbComponent } from './Common/CustomCb/customCb.component';
import { AllowedRegExpDirective } from './Utils/AllowedRegExpDirective.directive';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { HousePageComponent } from './Components/HousePage/HousePage.component';
import { TestInterceptorService } from './services/Interceptor/TestInterceptor.service';
import { ProspectoCompradorComponent } from './Components/HousePage/ProspectoComprador/prospecto-comprador.component';
import { ProspectoCompradorModalComponent } from './Components/HousePage/ProspectoComprador/ProspectoCompradorModal/ProspectoCompradorModal.component';
import { NumberOnlyDirective } from './Utils/NumberOnly.directive';
import { DashboardPageComponent } from './Components/DashboardPage/dashboard-page.component';
import { PerfilFormComponent } from './Components/DashboardPage/PerfilForm/perfil-form.component';
import { FavoritosPageComponent } from './Components/DashboardPage/FavoritosPage/favoritos-page.component';
import { MisPropiedadesPageComponent } from './Components/DashboardPage/MisPropiedadesPage/mis-propiedades-page.component';
import { PublicaTuPropiedadComponent } from './Common/NavBar/PublicaTuPropiedad/publica-tu-propiedad.component';
import { CanActivateIsLoggedService } from './services/CanActivateIsLogged.service';
import { CanActivateIsNotLoggedService } from './services/CanActivateIsNotLogged.service';
import { PublicaTuPropiedadModalComponent } from './Common/NavBar/PublicaTuPropiedad/PublicaTuPropiedadModal/PublicaTuPropiedadModal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NoticiasItemComponent } from './Common/NoticiasItem/noticias-item.component';


@NgModule({
  declarations: [
    AppComponent,
    LogInComponent,
    RegistroComponent,
    HomeComponent,
    RequestPasswordComponent,
    SetPasswordComponent,
    LoadingComponent,
    SidebarComponent,
    ImagesCarouselComponent,
    Carousel1Component,
    Carousel2Component,
    NavItemComponent,
    HouseItemComponent,
    SellComponent,
    NotResultsFoundComponent,
    FiltersComponent,
    MetrosScalePipe,
    FavoritesComponent,
    NavBarComponent,
    SearchComponent,
    FilterItemComponent,
    CustomCbComponent,
    HousePageComponent,
    AllowedRegExpDirective,
    ProspectoCompradorComponent,
    ProspectoCompradorModalComponent,
    NumberOnlyDirective,
    DashboardPageComponent,
    PerfilFormComponent,
    FavoritosPageComponent,
    MisPropiedadesPageComponent,
    PublicaTuPropiedadComponent,
    PublicaTuPropiedadModalComponent,
    NoticiasItemComponent,
  ],
  imports: [
    NgxsModule.forRoot([AppState], {
      developmentMode: !environment.production
    }),
    GoogleMapsModule,
    GooglePlaceModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDialogModule,
    MatSidenavModule,
    MatListModule,
    MatSelectModule,
    IonicModule.forRoot(),
    MatSliderModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatChipsModule,
    MatMenuModule,
    MatCheckboxModule,
    NgxSliderModule,
    SwiperModule,
    ShareButtonsModule,
    ShareIconsModule,
    MatTabsModule,
    InfiniteScrollModule,
    MatBadgeModule,
    NgxsModule.forRoot([
      AppState
    ], { developmentMode: !environment.production }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  exports: [
    GooglePlaceModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TestInterceptorService,
      multi: true
    },
    RepositoryService,
    MetrosScalePipe,
    UtilService,
    CanActivateIsLoggedService,
    CanActivateIsNotLoggedService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
