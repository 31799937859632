<div class="hFillContent OYAuto" #containerFavs id="containerFavs">
  <app-notresultsfound [category]="3" [hInitial]="true" *ngIf="housesPagination?.results?.length === 0 || !housesPagination?.results?.length"></app-notresultsfound>
  <div class="bodyhome plr24px"
  infiniteScroll
  [infiniteScrollContainer]="'#containerFavs'"
  [fromRoot]="true"
  [infiniteScrollDistance]="4"
  [infiniteScrollThrottle]="50"
  (scrolled)="getNext()"
  *ngIf="housesPagination?.results?.length > 0 &&  housesPagination?.results?.length">
    <div class="d-flex frowfwrap pt14px">
      <app-house-item class="w50pr houseNoMarginRight" id="HouseItem-{{house.id}}" *ngFor="let house of housesPagination?.results; let i=index" [House]="house"></app-house-item>
    </div>
  </div>
  <div class="footerContainer" *ngIf="housesPagination?.next">
    <mat-spinner class="op1"></mat-spinner>
  </div>
  <div class="pb24px"></div>
</div>