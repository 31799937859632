import { SortType } from './../../models/Enum';
import { AppStateService } from './../../services/Store/AppState.service';
import { RepositoryService } from 'src/app/services/Repository/Repository.service';
import { Utils } from 'src/app/Utils/Utils';
import { OnInit, ChangeDetectorRef, Component, HostListener, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Search } from 'src/app/models/Search';
import { House } from 'src/app/models/House';
import { LatLng } from 'src/app/models/LatLng';
import { MatDrawer } from '@angular/material/sidenav';
import { ImagesCarouselComponent } from 'src/app/Common/ImagesCarousel/ImagesCarousel.component';
import { Platform } from '@ionic/angular';
import { GoogleMap, MapInfoWindow, MapMarker, MapMarkerClusterer } from '@angular/google-maps';
import { UtilService } from 'src/app/services/UtilService.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnDestroy, OnInit {
  @ViewChildren(MapInfoWindow) infoWindows: QueryList<MapInfoWindow>;
  @ViewChildren(MapMarker) markers: QueryList<MapMarker>;
  @ViewChildren('GGMap') maps: QueryList<GoogleMap>;
  @ViewChildren('GMcluster') markersCluster: QueryList<MapMarkerClusterer>;
  @ViewChild(MatDrawer) matDrawer: MatDrawer;
  @ViewChildren(ImagesCarouselComponent) carousels: QueryList<ImagesCarouselComponent>;
  search: Search = null;
  settingBounds = false;
  SortType = SortType;
  currentSort: SortType = SortType.Distancia;
  Markers = [];
  routesub: Subscription = null;
  isMobile: boolean = null;
  MapMode = false;
  pixelOffset = new google.maps.Size(0, -30);
  ne: LatLng = { Lat: 0, Lng: 0 };
  sw: LatLng = { Lat: 0, Lng: 0 };
  Houses: House[] = [];
  filtersN: number = null;
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  options: google.maps.MapOptions = {
    mapTypeId: 'terrain',
    mapTypeControl: false,
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: false,
    maxZoom: 19,
    minZoom: 1,
    styles: [
      {
        featureType: 'poi',
        stylers: [
          { visibility: 'off' }
        ]
      }
    ]
  };
  searchResult = '';
  firstLoad = true;
  markerClustererImagePath = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';
  // tslint:disable-next-line: max-line-length
  constructor(private route: ActivatedRoute, private router: Router, private crud: RepositoryService, private ass: AppStateService, private cd: ChangeDetectorRef, private platform: Platform, private US: UtilService) {
  }
  countFilters(search: Search | null): number | null {
    if (!search) { return null; }
    const defaultFilters = new Search();
    let count = 0;
    for (const key of Object.keys(defaultFilters)) {
      if (Array.isArray(defaultFilters[key])) {
        const arrnew = search[key] as number[];
        if (key === 'Amenidades') {
          if (arrnew[0] !== -1) {
            count += arrnew.length;
          }
        } else if (key === 'TiposInmuebles') {
          if (arrnew[0] !== -1) {
            count += arrnew.length;
          }
        } else {
          const arrdef = defaultFilters[key] as number[];
          if (arrnew.some(a => !arrdef.some(b => b === a))) {
            count++;
          }
        }
      } else if (defaultFilters[key] !== search[key]) { count++; }
    }
    return count === 0 ? null : count;
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.onResize();
    }, 1000);
    this.routesub = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (Utils.isSearchURL(event.urlAfterRedirects)) {
        setTimeout(() => { // Required to set the zoom
          this.refresh();
        }, 0);
      }
    });
    if (Utils.isSearchURL(location.hash)) {
      Utils.getSearchData(this.crud, this.route).then(search => {
        if (!this.settingBounds && !this.firstLoad) {
          this.router.navigateByUrl('/Search' + Utils.encodeObject(search));
        }
      });
    }
    // Utils.firstValueFrom(this.map.tilesloaded).then(() => {
    //   this.clickMap();
    // });
  }
  generateCloseButton(): HTMLDivElement {
    const btnContainer = document.createElement('div');
    const controlUI = document.createElement('div');
    controlUI.classList.add('controlUI');
    const backgroundFakeBtn = document.createElement('div');
    backgroundFakeBtn.classList.add('controlUIBackground');
    controlUI.appendChild(backgroundFakeBtn);
    controlUI.addEventListener('click', () => {
      this.openCloseMap(true);
    });
    btnContainer.appendChild(controlUI);
    return btnContainer;
  }
  refresh() {
    if (this.settingBounds) {
      return;
    }
    Utils.getSearchData(this.crud, this.route).then(search => {
      if (this.settingBounds) {
        return;
      }
      this.search = search;
      this.filtersN = this.countFilters(search);
      const fromDraw = search.placeId === null;
      const strfilters = Utils.getSearchStr(search);
      const ne: LatLng = {
        Lat: this.search?.LatTR,
        Lng: this.search?.LngTR,
      };
      const sw: LatLng = {
        Lat: this.search?.LatBL,
        Lng: this.search?.LngBL,
      };
      const nw: LatLng = {
        Lat: ne.Lat,
        Lng: sw.Lng
      };
      const se: LatLng = {
        Lat: sw.Lat,
        Lng: ne.Lng
      };
      let strLatLng = '&polygon=MULTIPOLYGON ((('
        + nw.Lng.toString() + ' ' + nw.Lat.toString() + ','
        + ne.Lng.toString() + ' ' + ne.Lat.toString() + ','
        + se.Lng.toString() + ' ' + se.Lat.toString() + ','
        + sw.Lng.toString() + ' ' + sw.Lat.toString() + ','
        + nw.Lng.toString() + ' ' + nw.Lat.toString()
        + ')))';
      if (!fromDraw || this.firstLoad) {
        if (this.maps?.first) {
          let negm = new google.maps.LatLng(ne.Lat, ne.Lng);
          let swgm = new google.maps.LatLng(sw.Lat, sw.Lng);
          negm = new google.maps.LatLng(ne.Lat + 0.1, ne.Lng + 0.1);
          swgm = new google.maps.LatLng(sw.Lat - 0.1, sw.Lng - 0.1);
          strLatLng = '&polygon=MULTIPOLYGON ((('
            + (sw.Lng - 0.1) + ' ' + (ne.Lat + 0.1).toString() + ','
            + (ne.Lng + 0.1) + ' ' + (ne.Lat + 0.1).toString() + ','
            + (ne.Lng + 0.1) + ' ' + (sw.Lat - 0.1).toString() + ','
            + (sw.Lng - 0.1) + ' ' + (sw.Lat - 0.1).toString() + ','
            + (sw.Lng - 0.1) + ' ' + (ne.Lat + 0.1).toString()
            + ')))';
          const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds(swgm, negm);
          this.settingBounds = true;
          this.maps?.first?.fitBounds(bounds, 0);
          this.firstLoad = false;
        }
      }
      // Utils.firstValueFrom(this.crud.GetPetition('inmueble/public/inmuebles/?limit=3&categoria=' + this.search.OptionsSelectBuyLease.toString()
      Utils.firstValueFrom(this.crud.GetPetition('inmueble/public/inmuebles/?categoria=' + this.search.OptionsSelectBuyLease.toString()
        + strfilters + strLatLng, false, true)).then((res: House[]) => {
          if (this.markersCluster?.length > 0) {
            for (const cluster of this.markersCluster) {
              cluster.markerClusterer.clearMarkers();
            }
            this.markersCluster.reset([]);
          }
          if (res && res.length > 0) {
            const MapHouses: House[] = res.map(h => {
              return Utils.ProcessHouse(h);
            });
            this.Houses = MapHouses.filter((item, index) => MapHouses.findIndex(e => e.id === item.id) === index);
          } else {
            this.Houses = [];
          }
          this.refreshSearchResult(ne, sw);
          this.settingBounds = false;
        });
      this.cd.detectChanges();
    });
  }
  setSort(sortType: SortType) {
    this.currentSort = sortType;
    switch (sortType) {
      case SortType.Distancia:
        this.Houses.sort((a, b) => a.distance - b.distance);
        break;
      case SortType.MayorPrecio:
        this.Houses.sort((a, b) => b.precio - a.precio);
        break;
      case SortType.MenorPrecio:
        this.Houses.sort((a, b) => a.precio - b.precio);
        break;
      default:
        this.currentSort = SortType.Distancia;
        this.Houses.sort((a, b) => a.distance - b.distance);
        break;
    }
  }
  refreshSearchResult(ne: LatLng, sw: LatLng) {
    const geocoder = new google.maps.Geocoder();
    const latitude = (ne.Lat + sw.Lat) / 2;
    const longitude = (ne.Lng + sw.Lng) / 2;
    const latlng = new google.maps.LatLng(latitude, longitude);
    geocoder.geocode({ location: latlng }, (res: google.maps.GeocoderResult[]) => {
      const ventaORenta = this.search.OptionsSelectBuyLease === 1 ? 'Venta' : 'Renta';
      if (res?.length > 0) {
        let locality: google.maps.GeocoderResult = null;
        for (const l of res) {
          if (l.types.some(t => t === 'locality')) {
            locality = l;
            break;
          }
        }
        if (!locality) {
          locality = res[0];
        }
        let city = locality?.address_components?.find(c => c.types.some(t => t === 'locality'))?.short_name;
        if (!city) {
          city = 'esta área';
        }
        this.searchResult = this.Houses.length.toString() + ' Propiedades en ' + ventaORenta + ' cercas de ' + city + '.';
      } else {
        this.searchResult = this.Houses.length.toString() + ' Propiedades en ' + ventaORenta + ' cercas de esta área.';
      }
    });
  }
  clickCluster(cluster: any) {
    if (!this.settingBounds && !this.firstLoad) {
      const verificator = cluster?.getBounds()?.getSouthWest()?.lat();
      if (verificator) {
        const clusterRef = cluster;
        const LatLngBL = {
          Lat: clusterRef.getBounds().getSouthWest().lat(),
          Lng: clusterRef.getBounds().getSouthWest().lng()
        };
        const LatLngTR = {
          Lat: clusterRef.getBounds().getNorthEast().lat(),
          Lng: clusterRef.getBounds().getNorthEast().lng()
        };
        Utils.getSearchData(this.crud, this.route).then(search => {
          try {
            search.LatBL = LatLngBL.Lat;
            search.LngBL = LatLngBL.Lng;
            search.LatTR = LatLngTR.Lat;
            search.LngTR = LatLngTR.Lng;
            search.SearchStr = null;
            search.placeId = null;
            this.router.navigateByUrl('/Search' + Utils.encodeObject(search));
          } catch (error) {
            console.log(error);
          }
        });
      }
    }
  }
  clickMap(source: number) {
    console.log('Source::', source);
    if (!this.settingBounds && !this.firstLoad) {
      if (this.maps?.first?.getBounds()) {
        // tslint:disable-next-line: prefer-const
        let ne: LatLng = {
          Lat: this.search?.LatTR,
          Lng: this.search?.LngTR,
        };
        if (this.maps?.first?.getBounds().getNorthEast().lat() === ne?.Lat && ne?.Lat
          && this.maps?.first?.getBounds().getNorthEast().lng() === ne?.Lng) {
          return;
        }
        Utils.getSearchData(this.crud, this.route).then(search => {
          search.LatBL = this.maps?.first?.getBounds().getSouthWest().lat();
          search.LngBL = this.maps?.first?.getBounds().getSouthWest().lng();
          search.LatTR = this.maps?.first?.getBounds().getNorthEast().lat();
          search.LngTR = this.maps?.first?.getBounds().getNorthEast().lng();
          search.SearchStr = null;
          search.placeId = null;
          this.router.navigateByUrl('/Search' + Utils.encodeObject(search));
        });
      }
    }
  }
  openInfoWindow(house: House) {
    const infoWindow = this.infoWindows.find(iw => (iw?.infoWindow as any)?.content?.id === 'infoWindow-' + house.id);
    if (infoWindow) {
      if (house.Opened) {
        infoWindow.close();
      } else {
        infoWindow.open();
      }
      house.Opened = !house.Opened;
    }
  }
  openCloseMap(forceClose: boolean = false) {
    if (forceClose) {
      this.MapMode = false;
      this.matDrawer.close();
      setTimeout(() => { // Required to set the zoom
        this.refresh();
      }, 0);
    } else {
      this.matDrawer.toggle();
      this.MapMode = this.matDrawer.opened;
      if (this.MapMode) {
        this.firstLoad = true;
        setTimeout(() => { // Required to set the zoom
          this.initMap();
          this.refresh();
        }, 0);
      }
    }
    this.cd.detectChanges();
    this.carousels?.forEach(item => {
      item.refresh();
    });
  }
  @HostListener('window:resize')
  onResize() {
    const isMobile = this.platform.is('capacitor') || document.getElementById('fullContainer').offsetWidth < 992;
    if (isMobile !== this.isMobile && this.matDrawer) {
      this.isMobile = isMobile;
      this.openCloseMap(this.isMobile);
      this.firstLoad = true;
    }
    this.initMap();
    // tslint:disable-next-line: max-line-length
    this.cd.detectChanges();
  }
  initMap() {
    const closeMapButton = this.generateCloseButton();
    this.maps?.first?.controls[google.maps.ControlPosition.TOP_LEFT].clear();
    this.maps?.first?.controls[google.maps.ControlPosition.TOP_LEFT].push(closeMapButton);
  }
  showFiltersMobile() {
    this.US.ToggleSideBar.emit(true);
  }
  ngOnDestroy() {
    this.routesub.unsubscribe();
  }
}
