import { UtilService } from './services/UtilService.service';
import { RepositoryService } from 'src/app/services/Repository/Repository.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { IonSlides, Platform } from '@ionic/angular';
import { SetCurrentUser, ShowLoading } from './services/Store/Actions';
import { NavItem } from './models/NavItem';
import { Observable, Subscription } from 'rxjs';
import { Usuario } from './models/Usuario';
import { AppStateService } from './services/Store/AppState.service';
import { AppStateModel } from './services/Store/Reducer';
import { Utils } from './Utils/Utils';
import { MatSidenav } from '@angular/material/sidenav';
import { InitStoreService } from './services/Store/InitStore.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SidebarComponent } from './Common/sidebar/sidebar.component';
import { ShareSource } from './models/Enum';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {
  @ViewChild('snav', {read: MatSidenav}) snav: MatSidenav;
  @ViewChild('sidenavcomp', {read: SidebarComponent}) sidenavcomp: SidebarComponent;
  Utils = Utils;
  customIcons: string[] = [
    'dollarHouse',
    'heartHouse',
    'mapIcon',
    'bed',
    'bath',
    'expand',
    'ListSortIcon',
    'car',
    'downBlueArrow',
    'alberca',
    'balcon',
    'condominio',
    'coto_privado',
    'elevador',
    'jardin',
    'roof_garden',
    'vapor',
    'mini_down_blue_arrow',
    'arrow_back_blue',
    'ListHousesIcon',
  ];
  firstLoadLatLng = true;
  subExternalToogle: Subscription;
  constructor(
    private platform: Platform,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private initStore: InitStoreService,
    private crud: RepositoryService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private US: UtilService,
  ) {
    if (this.route.snapshot.queryParams?.ShareType && this.route.snapshot.queryParams.ShareType !== ShareSource.Undefined) {
      this.crud.SetSource(this.route.snapshot.queryParams.ShareType);
    }
    this.customIcons.forEach((iconName) => {
      this.iconRegistry.addSvgIcon(
        iconName,
        this.sanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${iconName}.svg`)
      );
    });
    const path = '/assets/icons';
    this.iconRegistry
      .addSvgIcon('fblogin',  Utils.setPath(`${path}/FacebookIcon.svg`, this.sanitizer))
      .addSvgIcon('gglogin',  Utils.setPath(`${path}/GoogleIcon.svg`, this.sanitizer));
    this.subExternalToogle = this.US.ToggleSideBar.subscribe((res) => {
      this.US.isToggleOpen = res;
      if (res) {
        this.sidenavcomp.updateMenuView();
        this.snav.open();
      } else {
        this.snav.close();
      }
    });
  }
  ngOnDestroy(): void {
    this.subExternalToogle?.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.initializeApp();
  }
  initializeApp() {
    // this.isMobile = true; // TODO TEST MOBILES HERE
    this.store.dispatch(new ShowLoading(true));
    const token = localStorage.getItem('token');
    if (token) {
      Utils.getUser(this.crud).then(res => {
        this.store.dispatch(new SetCurrentUser(token));
        this.store.dispatch(new ShowLoading(false));
        // this.router.navigateByUrl('/Home');
      }).catch(error => {
        this.store.dispatch(new SetCurrentUser(null));
        this.router.navigateByUrl('/Login');
        this.store.dispatch(new ShowLoading(false));
      });
    } else {
      this.store.dispatch(new SetCurrentUser(null));
      this.store.dispatch(new ShowLoading(false));
    }
  }
}
