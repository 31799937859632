<ion-app>
  <div id="fullContainer">
    <mat-sidenav-container class="sideNabContainer">
      <mat-sidenav #snav class="sideBar" [mode]="'over'">
        <app-sidebar #sidenavcomp></app-sidebar>
      </mat-sidenav>
      <mat-sidenav-content class="overflowInherit">
        <div class="wh100pr componentsParent">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</ion-app>
