import { RepositoryService } from './../../services/Repository/Repository.service';
import { Usuario } from './../../models/Usuario';
import { Input, OnDestroy, Output, ViewChild, AfterViewInit, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { Search } from 'src/app/models/Search';
import { AppStateService } from 'src/app/services/Store/AppState.service';
import { Utils } from 'src/app/Utils/Utils';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { SetCurrentUser } from 'src/app/services/Store/Actions';
import { LatLng } from 'src/app/models/LatLng';
import { OptionsSelectBuyLease } from 'src/app/models/OptionsSelectBuyLease';
import { FiltersChips, FilterType } from 'src/app/models/FiltersChips';
import { PublicaTuPropiedadModalComponent } from './PublicaTuPropiedad/PublicaTuPropiedadModal/PublicaTuPropiedadModal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-navbar',
  templateUrl: './NavBar.component.html',
  styleUrls: ['./NavBar.component.scss']
})
export class NavBarComponent implements AfterViewInit, OnDestroy {
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  @Output() OpenCloseMap: EventEmitter<any> = new EventEmitter();
  @Input() ShowExpanded = false;
  @Input() ShowSearchBar: boolean;
  @Input() Address: Address = null;
  isLoginURL = false;
  localShowSearchBar = false;
  form: UntypedFormGroup;
  Utils = Utils;
  chips: FiltersChips[] = [
    {
      defaultText: 'Tipo de Inmueble',
      text: 'Tipo de Inmueble',
      ftype: FilterType.TipoInmueble,
      extraClasses: '',
    },
    {
      defaultText: 'Precio',
      text: 'Precio',
      ftype: FilterType.Precio,
      extraClasses: '',
    },
    {
      defaultText: 'Habitaciones',
      text: 'Habitaciones',
      ftype: FilterType.Habitaciones,
      extraClasses: '',
    },
    {
      defaultText: 'Baños',
      text: 'Baños',
      ftype: FilterType.Banos,
      extraClasses: '',
    },
    {
      defaultText: 'Estacionamiento',
      text: 'Estacionamiento',
      ftype: FilterType.Estacionamiento,
      extraClasses: '',
    },
    {
      defaultText: 'Amenidades',
      text: 'Amenidades',
      ftype: FilterType.Amenidades,
      extraClasses: '',
    },
    {
      defaultText: 'Antigüedad',
      text: 'Antigüedad',
      ftype: FilterType.Antiguedad,
      extraClasses: '',
    },
    {
      defaultText: 'Construcción',
      text: 'Construcción',
      ftype: FilterType.Construccion,
      extraClasses: '',
    },
    {
      defaultText: 'Terreno',
      text: 'Terreno',
      ftype: FilterType.Terreno,
      extraClasses: '',
    },
  ];
  routesub: Subscription;
  user: Observable<Usuario | null> = this.appStateService.CurrentUser.pipe(map(usr => Utils.IsLogged(usr) ? usr : null));
  options: any = {
    types: [],
    componentRestrictions: { country: environment.countryForSearchGGMaps }
  };
  optionsBuyLease: OptionsSelectBuyLease[] = [
    {
      type: 1,
      url: 'Buy',
      name: 'Comprar'
    },
    {
      type: 2,
      url: 'Lease',
      name: 'Rentar'
    }
  ];
  firstLoad = true;
  // tslint:disable-next-line: max-line-length
  constructor(private appStateService: AppStateService, private dialog: MatDialog, private router: Router, private fb: UntypedFormBuilder, private store: Store, private cd: ChangeDetectorRef, private route: ActivatedRoute, private crud: RepositoryService) {
    this.routesub = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.processUrl(event.urlAfterRedirects);
    });
  }
  processUrl(url: string) {
    const SearchObj: FormNavSearch = new FormNavSearch();
    if (Utils.isSearchURL(url)) {
      Utils.getSearchData(this.crud, this.route).then(search => {
        if (search.OptionsSelectBuyLease) {
          SearchObj.OptionsSelectBuyLease = search.OptionsSelectBuyLease;
        }
        SearchObj.Search = search.SearchStr;
        this.form = this.fb.group(SearchObj);
        this.firstLoad = false;
        this.isLoginURL = true;
      });
    } else {
      SearchObj.Search = null;
      SearchObj.OptionsSelectBuyLease = 1;
      this.form = this.fb.group(SearchObj);
      this.form.controls.Search.setValue(null);
      this.isLoginURL = false;
    }
  }
  ngAfterViewInit(): void {
    this.processUrl(location.hash);
    setTimeout(() => {
      this.localShowSearchBar = this.ShowSearchBar;
      this.cd.detectChanges();
    }, 1000);
  }
  ngOnDestroy(): void {
    this.routesub.unsubscribe();
  }
  login() {
    this.router.navigateByUrl('Login');
  }
  logout() {
    this.store.dispatch(new SetCurrentUser(null));
    this.router.navigateByUrl('Login');
  }
  openCloseMap() {
    this.OpenCloseMap.emit();
  }
  onChangeBuyLease(e: MatSelectChange) {
    Utils.getSearchData(this.crud, this.route).then(search => {
      search.OptionsSelectBuyLease = e.value;
      this.router.navigateByUrl('/Search' + Utils.encodeObject(search));
    });
  }
  getLastFilters(): Promise<Search> {
    return new Promise((res, err) => {
      if (Utils.isSearchURL()) {
        Utils.getSearchData(this.crud, this.route).then(search => {
          res(search);
        });
      } else {
        res(new Search());
      }
    });
  }
  executeSearch() {
    this.getLastFilters().then(search => {
      const LatLngBL: LatLng = this.Address?.geometry?.viewport?.getNorthEast()?.lat() ? {
        Lat: this.Address.geometry.viewport.getSouthWest().lat(),
        Lng: this.Address.geometry.viewport.getSouthWest().lng()
      } : null;
      const LatLngTR: LatLng = LatLngBL !== null ? {
        Lat: this.Address.geometry.viewport.getNorthEast().lat(),
        Lng: this.Address.geometry.viewport.getNorthEast().lng()
      } : null;
      search.LatBL = LatLngBL.Lat;
      search.LngBL = LatLngBL.Lng;
      search.LatTR = LatLngTR.Lat;
      search.LngTR = LatLngTR.Lng;
      search.placeId = LatLngBL ? this.Address.place_id : null;
      search.SearchStr = this.form.controls.Search.value;
      search.OptionsSelectBuyLease = this.form.controls.OptionsSelectBuyLease.value;
      this.router.navigateByUrl('/Search' + Utils.encodeObject(search));
    });
  }
  searchValue(): void {
    if (this.Address?.geometry?.viewport?.getNorthEast()?.lat()) {
      this.executeSearch();
    } else {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({address: this.form.controls.Search.value}, (res: google.maps.GeocoderResult[]) => {
        if (res?.length > 0) {
          this.Address = (res[0] as any) as Address;
          this.executeSearch();
        }
      });
    }
  }
  handleAddressChange(address: Address) {
    if (address.name !== '' && address?.geometry) {
      this.Address = address;
      this.form.controls.Search.setValue(this.Address.formatted_address);
      this.searchValue();
    } else {
      this.Address = null;
    }
  }
  showPublicaPropiedad() {
    this.dialog.open(PublicaTuPropiedadModalComponent, {
      width: '50vw',
    });
  }
}
class FormNavSearch {
  OptionsSelectBuyLease = 1;
  Search: string = null;
}
