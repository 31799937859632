<div class="loginContainer">
  <a
    class="display-flex-center-left a-black-text-decoration-none pb24px"
    routerLink="/"
  >
    <img class="SoloLogo" src="/assets/LogoAzulNoFondo.png" alt="" />
    <div class="boldText">Propefy</div>
  </a>
  <div class="loginCard">
    <form
      [formGroup]="formRegistro"
      class="frm"
      *ngIf="!loadingInit"
      (ngSubmit)="register()"
    >
      <div class="loginFormContainer">
        <div class="loginWelcomeText2">¡Hola!</div>
        <div class="loginWelcomeText pb24px">
          Ingresa tus datos para crear una cuenta
        </div>
        <mat-form-field
          class="w100pr pb24px custom-semi-oval-mat-form-field custom-blue-mat-form-field"
          appearance="outline"
        >
          <mat-label>Nombre</mat-label>
          <input required matInput formControlName="name" autocomplete="name" />
          <mat-error *ngIf="formRegistro?.controls?.name?.errors?.required">{{
            Utils.datoRequerido
          }}</mat-error>
          <mat-error *ngIf="formRegistro?.controls?.name?.errors?.pattern">{{
            Utils.formatoIncorrecto
          }}</mat-error>
        </mat-form-field>
        <mat-form-field
        class="w100pr pb24px custom-semi-oval-mat-form-field custom-blue-mat-form-field"
        appearance="outline"
      >
        <mat-label>Apellidos</mat-label>
        <input
          required
          matInput
          formControlName="last_name"
          autocomplete="additional-name"
        />
        <mat-error
          *ngIf="formRegistro?.controls?.last_name?.errors?.required"
          >{{ Utils.datoRequerido }}</mat-error
        >
      </mat-form-field>
        <mat-form-field
          class="w100pr pb24px custom-semi-oval-mat-form-field custom-blue-mat-form-field"
          appearance="outline"
        >
          <mat-label>Email</mat-label>
          <input
            required
            matInput
            formControlName="email"
            autocomplete="on"
            required
          />
          <mat-error *ngIf="formRegistro?.controls?.email?.errors?.required"
            >Por favor ingresa un email válido</mat-error
          >
        </mat-form-field>
        <mat-form-field
          class="w100pr pb24px custom-semi-oval-mat-form-field custom-blue-mat-form-field"
          appearance="outline"
        >
          <mat-label>Teléfono</mat-label>
          <input
            required
            matInput
            formControlName="phone"
            appNumberOnly
            autocomplete="tel"
            maxlength="10"
          />
          <mat-error *ngIf="formRegistro?.controls?.phone?.errors?.required">{{
            Utils.datoRequerido
          }}</mat-error>
          <mat-error *ngIf="formRegistro?.controls?.phone?.errors?.minlength">{{
            Utils.minLength.replace("{0}", "10")
          }}</mat-error>
          <mat-error *ngIf="formRegistro?.controls?.phone?.errors?.maxlength">{{
            Utils.maxLength.replace("{0}", "10")
          }}</mat-error>
        </mat-form-field>
        <mat-form-field
          class="w100pr pb24px custom-semi-oval-mat-form-field custom-blue-mat-form-field"
          appearance="outline"
        >
          <mat-label>Contraseña</mat-label>
          <input
            required
            matInput
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            autocomplete="new-password"
          />
          <button
            mat-icon-button
            tabindex="-1"
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            type="button"
            [attr.aria-pressed]="hide"
          >
            <mat-icon class="eye-icon" *ngIf="hide">visibility</mat-icon>
            <mat-icon class="eye-icon" *ngIf="!hide">visibility_off</mat-icon>
          </button>
          <mat-error
            *ngIf="
              formRegistro?.controls?.password?.errors?.newPasswordRepeat
                ?.MatchPassword
            "
            >Las contraseñas no coinciden.</mat-error
          >
          <mat-error
            *ngIf="formRegistro?.controls?.password?.errors?.required"
            >{{ Utils.datoRequerido }}</mat-error
          >
          <mat-error
            *ngIf="formRegistro?.controls?.password?.errors?.minlength"
            >{{ Utils.minLength.replace("{0}", "8") }}</mat-error
          >
          <mat-error
            *ngIf="formRegistro?.controls?.password?.errors?.maxlength"
            >{{ Utils.maxLength.replace("{0}", "16") }}</mat-error
          >
        </mat-form-field>
        <mat-form-field
          class="w100pr pb24px custom-semi-oval-mat-form-field custom-blue-mat-form-field"
          appearance="outline"
        >
          <mat-label>Confirma tu Contraseña</mat-label>
          <input
            required
            matInput
            [type]="hide2 ? 'password' : 'text'"
            formControlName="password2"
            autocomplete="off"
          />
          <button
            mat-icon-button
            tabindex="-1"
            matSuffix
            (click)="hide2 = !hide2"
            type="button"
            [attr.aria-label]="'Hide password 2'"
            [attr.aria-pressed]="hide2"
          >
            <mat-icon class="eye-icon" *ngIf="hide2">visibility</mat-icon>
            <mat-icon class="eye-icon" *ngIf="!hide2">visibility_off</mat-icon>
          </button>
          <mat-error *ngIf="formRegistro?.controls?.password2?.errors?.required"
            >Contraseña no válida.</mat-error
          >
          <mat-error
            *ngIf="
              formRegistro?.controls?.password2?.errors?.newPasswordRepeat
                ?.MatchPassword
            "
            >Las contraseñas no coinciden.</mat-error
          >
          <mat-error
            *ngIf="formRegistro?.controls?.password2?.errors?.minlength"
            >{{ Utils.minLength.replace("{0}", "8") }}</mat-error
          >
          <mat-error
            *ngIf="formRegistro?.controls?.password2?.errors?.maxlength"
            >{{ Utils.maxLength.replace("{0}", "16") }}</mat-error
          >
        </mat-form-field>
        <button
          mat-flat-button
          class="button btnBlue2 mb24px"
          [class.spinner]="loading"
          [disabled]="loading"
          type="submit"
        >
          Regístrame
        </button>
        <div class="fake-hr mb24px"></div>
        <div class="login-alternative-text mb24px">O ingresa con</div>
        <div class="alternative-logins-container mb24px">
          <button class="social-media-login-button" disabled>
            <img src="/assets/icons/google.svg" class="social-media-login" />
          </button>
          <button class="social-media-login-button" disabled>
            <img src="/assets/icons/facebook.svg" class="social-media-login" />
          </button>
          <button class="social-media-login-button" disabled>
            <img src="/assets/icons/twitter.svg" class="social-media-login" />
          </button>
        </div>
        <div class="contact-us-register-container">
          ¿Ya tienes cuenta? &nbsp;
          <a routerLink="/Login" class="forgot-password">Ingresa</a>
        </div>
      </div>
    </form>
  </div>
</div>
