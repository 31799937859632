import { Usuario } from 'src/app/models/Usuario';
import { RepositoryService } from './Repository/Repository.service';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Utils } from '../Utils/Utils';
import { AppStateService } from './Store/AppState.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateIsNotLoggedService implements CanActivate {
  constructor(private store: Store, private crud: RepositoryService, private router: Router) { }
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('token')) {
      let user: Usuario = null;
      try {
        user = await Utils.getUser(this.crud);
      } catch (error) {}
      if (Utils.IsLogged(user)) {
        this.router.navigateByUrl('/Home');
        return false;
      }
      return true;
    }
    return true;
  }
}
