<div class="loginContainer">
  <a
    class="display-flex-center-left a-black-text-decoration-none pb24px"
    routerLink="/"
  >
    <img class="SoloLogo" src="/assets/LogoAzulNoFondo.png" alt="" />
    <div class="boldText">Propefy</div>
  </a>
  <div class="loginCard">
    <form
      [formGroup]="formSP"
      class="frm"
      *ngIf="!loadingInit"
      (ngSubmit)="sp()"
    >
      <div class="loginFormContainer">
        <div class="loginWelcomeText2">Reestablece tu Contraseña</div>
        <div class="loginWelcomeText pb24px">
          Ingresa y confirma tu nueva contraseña
        </div>
        <mat-form-field
          class="w100pr pb24px custom-semi-oval-mat-form-field custom-blue-mat-form-field"
          appearance="outline"
        >
          <mat-label>Contraseña</mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            autocomplete="new-password"
          />
          <button
            mat-icon-button
            tabindex="-1"
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            type="button"
            [attr.aria-pressed]="hide"
          >
            <mat-icon class="eye-icon" *ngIf="hide">visibility</mat-icon>
            <mat-icon class="eye-icon" *ngIf="!hide">visibility_off</mat-icon>
          </button>
          <mat-error
            *ngIf="
              formSP?.controls?.password?.errors?.newPasswordRepeat
                ?.MatchPassword
            "
            >Las contraseñas no coinciden.</mat-error
          >
          <mat-error *ngIf="formSP?.controls?.password?.errors?.required">{{
            Utils.datoRequerido
          }}</mat-error>
          <mat-error *ngIf="formSP?.controls?.password?.errors?.minlength">{{
            Utils.minLength.replace("{0}", "8")
          }}</mat-error>
          <mat-error *ngIf="formSP?.controls?.password?.errors?.maxlength">{{
            Utils.maxLength.replace("{0}", "16")
          }}</mat-error>
        </mat-form-field>
        <mat-form-field
          class="w100pr pb24px custom-semi-oval-mat-form-field custom-blue-mat-form-field"
          appearance="outline"
        >
          <mat-label>Confirmar contraseña</mat-label>
          <input
            matInput
            [type]="hide2 ? 'password' : 'text'"
            formControlName="password2"
            autocomplete="off"
          />
          <button
            mat-icon-button
            tabindex="-1"
            matSuffix
            (click)="hide2 = !hide2"
            type="button"
            [attr.aria-label]="'Hide password 2'"
            [attr.aria-pressed]="hide2"
          >
            <mat-icon class="eye-icon" *ngIf="hide2">visibility</mat-icon>
            <mat-icon class="eye-icon" *ngIf="!hide2">visibility_off</mat-icon>
          </button>
          <mat-error *ngIf="formSP?.controls?.password2?.errors?.required"
            >Contraseña no válida.</mat-error
          >
          <mat-error
            *ngIf="
              formSP?.controls?.password2?.errors?.newPasswordRepeat
                ?.MatchPassword
            "
            >Las contraseñas no coinciden.</mat-error
          >
          <mat-error *ngIf="formSP?.controls?.password2?.errors?.minlength">{{
            Utils.minLength.replace("{0}", "8")
          }}</mat-error>
          <mat-error *ngIf="formSP?.controls?.password2?.errors?.maxlength">{{
            Utils.maxLength.replace("{0}", "16")
          }}</mat-error>
        </mat-form-field>
        <button
          mat-flat-button
          class="button btnBlue2 mb24px"
          [class.spinner]="loading"
          [disabled]="loading"
          type="submit"
        >
          Guardar
        </button>
        <div class="fake-hr mb24px"></div>
        <div class="contact-us-register-container mb32px">
          ¿Tienes problemas? &nbsp;
          <a
            href="mailto:system@propefy.com"
            tabindex="-1"
            class="forgot-password"
            >Contáctanos</a
          >
        </div>
      </div>
    </form>
  </div>
</div>
