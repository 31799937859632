import { RepositoryService } from 'src/app/services/Repository/Repository.service';
import { Usuario, UsuarioModify } from 'src/app/models/Usuario';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Utils } from 'src/app/Utils/Utils';
import { AppStateService } from 'src/app/services/Store/AppState.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import * as alertify from 'alertifyjs';
import { SetCurrentUser } from 'src/app/services/Store/Actions';

@Component({
  selector: 'app-perfil-form',
  templateUrl: './perfil-form.component.html',
  styleUrls: ['./perfil-form.component.scss'],
})
export class PerfilFormComponent implements OnInit {
  Utils = Utils;
  form: UntypedFormGroup = null;
  usuario: Observable<Usuario> = null;
  loading = false;
  constructor(private fb: UntypedFormBuilder, private ass: AppStateService, private store: Store, private crud: RepositoryService) { }

  ngOnInit() {
    this.usuario = this.ass.CurrentUser.pipe(tap(user => {
      if (user) {
        const temp: UsuarioModify = {
          email: user.email,
          name: user.name,
          last_name: user.last_name,
          phone:  user.phone
        };
        this.form = this.fb.group(temp);
        this.form.controls.phone.setValidators([Validators.required, Validators.maxLength(10), Validators.minLength(10)]);
        this.form.controls.name.setValidators([Validators.required]);
        this.form.controls.last_name.setValidators([Validators.required]);
        this.form.controls.email.disable();
        this.form.markAsPristine();
      }
    }));
  }
  submitUser() {
    const data: UsuarioModify = this.form.value;
    delete data.email;
    this.loading = true;
    Utils.firstValueFrom(this.crud.PatchPetition('user/me/', data, false, true)).then(_ => {
      this.form.markAsPristine();
      alertify.notify('¡Modificado con éxito!', 'success', 5);
      const token = localStorage.getItem('token');
      this.store.dispatch(new SetCurrentUser(token));
      this.loading = false;
    }).catch(error => {
      this.loading = false;
      this.crud.ShowErrors(error);
    });
  }
}
