
export class SetCurrentUser {
  static readonly type = 'Get current user';
  constructor(public Token: string) {}
}
export class ShowLoading {
  static readonly type = 'Show Loading';
  constructor(public Show: boolean) {}
}
export class GetTiposInmuebles {
  static readonly type = 'Get TiposInmuebles';
}
export class GetAmenidades {
  static readonly type = 'Get Amenidades';
}
