import { ImageSet } from './ImageSet';
import { PaginationBase } from './PaginationBase';

export class House {
  id: number;
  titulo: string;
  descripcion: string;
  dueno: number;
  amenidades: number[];
  // tslint:disable-next-line: variable-name
  acepta_credito: boolean;
  categoria: HouseCategory;
  // tslint:disable-next-line: variable-name
  tipo_propiedad: number;
  municipio: number;
  precio: number;
  moneda: string;
  // tslint:disable-next-line: variable-name
  precio_periodo: number;
  status: string;
  estacionamientos: number;
  recamaras: number;
  banos: number;
  // tslint:disable-next-line: variable-name
  medios_banos: number;
  direccion: string;
  latitud: number;
  longitud: number;
  // tslint:disable-next-line: variable-name
  m_2: number;
  // tslint:disable-next-line: variable-name
  m_2_construccion: number;
  creada: Date;
  actualizada: Date;
  // tslint:disable-next-line: variable-name
  imagenes_set: ImageSet[];
  imagenesToShow?: string[];
  distance: number;
  // tslint:disable-next-line: variable-name
  is_liked: string | boolean;
  destacado: boolean;
  Opened ?= false;
  ShortTitle?: string;
  ShortDesc?: string;
  ShortDir?: string;
  TituloUrl: string;
}
export class HousePagination extends PaginationBase {
  results: House[];
}
export enum HouseCategory {
  Buy = 1,
  Lease = 2,
  Favorites = 3,
  Mines = 4
}
