import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sell',
  templateUrl: './Sell.component.html',
  styleUrls: ['./Sell.component.scss']
})
export class SellComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
