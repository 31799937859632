import { Component, Input, OnInit } from '@angular/core';
import { NavItem } from 'src/app/models/NavItem';

@Component({
  selector: 'app-nav-item',
  templateUrl: './NavItem.component.html',
  styleUrls: ['./NavItem.component.scss']
})
export class NavItemComponent implements OnInit {
  @Input() nav: NavItem = new NavItem();

  constructor() { }

  ngOnInit() {
  }

}
