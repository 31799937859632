import { Component, OnInit } from '@angular/core';
import { HousePagination } from 'src/app/models/House';
import { RepositoryService } from 'src/app/services/Repository/Repository.service';
import { Utils } from 'src/app/Utils/Utils';

@Component({
  selector: 'app-mis-propiedades-page',
  templateUrl: './mis-propiedades-page.component.html',
  styleUrls: ['./mis-propiedades-page.component.scss'],
})
export class MisPropiedadesPageComponent implements OnInit {
  housesPagination: HousePagination = null;
  constructor(private crud: RepositoryService) {
  }
  ngOnInit() {
    Utils.firstValueFrom(this.crud.GetPetition('inmueble/my/inmuebles/?limit=8', false, true)).then((res: HousePagination) => {
      if (res) {
        const housesPag = Utils.CloneObject(res);
        if (housesPag?.results) {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < res.results.length; i++) {
            let item = res.results[i];
            item = Utils.ProcessHouse(item);
            housesPag?.results.splice(i, 1, item);
          }
        }
        housesPag.next = housesPag?.next?.replace('http://app/api/', '');
        housesPag.previous = housesPag?.previous?.replace('http://app/api/', '');
        this.housesPagination = housesPag;
      }
    });
  }
  getNext() {
    if (this.housesPagination?.next) {
      Utils.firstValueFrom(this.crud.GetPetition(this.housesPagination?.next,
      false, true)).then((res: HousePagination) => {
        if (res) {
          const housesPag = Utils.CloneObject(res);
          if (housesPag?.results) {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < res.results.length; i++) {
              let item = res.results[i];
              item = Utils.ProcessHouse(item);
              housesPag?.results.splice(i, 1, item);
            }
            housesPag.next = housesPag?.next?.replace('http://app/api/', '');
            housesPag.previous = housesPag?.previous?.replace('http://app/api/', '');
            Array.prototype.push.apply(this.housesPagination.results, housesPag.results);
            this.housesPagination.next = housesPag.next;
            this.housesPagination.previous = housesPag.previous;
            this.housesPagination.count = housesPag.count;
          }
        }
      });
    }
  }
}
