import { LatLng } from 'src/app/models/LatLng';

export class Search {
    SearchStr?: string = null;
    OptionsSelectBuyLease?: number = null;
    TiposInmuebles: number[] | null = [-1];
    MinPrice: number = null;
    MaxPrice = 25000000;
    placeId?: string = null;
    LatBL?: number = null;
    LngBL?: number = null;
    LatTR?: number = null;
    LngTR?: number = null;
    Amenidades: number[] = [-1];
    AceptaCredito = true;
    DentroPrivada = false;
    MaxEstacionamientos: number = null;
    MinEstacionamientos: number = null;
    MinBanos: number = null;
    MaxBanos: number = null;
    MinRecamaras: number = null;
    MaxRecamaras: number = null;
    MinConstruccion: number = null;
    MaxConstruccion: number = null;
    MinTerreno: number = null;
    MaxTerreno: number = null;
    MinAntiguedad: number = null;
    MaxAntiguedad: number = null;
}
export class SearchBounds {
    LatLngBL?: LatLng;
    LatLngTR?: LatLng;
}
