<mat-chip class="customChip fakeA {{item.extraClasses}}" (click)="updateMenuView()" [matMenuTriggerFor]="menu">
  <span class="customChipSpan" [innerHTML]="item.text"></span>
</mat-chip>
<mat-menu #menu="matMenu">
  <div class="menuContainer" (click)="$event.stopPropagation();">
    <div *ngIf="item.ftype === FilterType.TipoInmueble" (click)="$event.stopPropagation();">
      <div class="normalCBContainer">
        <div class="d-block normalCB" *ngFor="let ti of (tiposInmuebles | async)">
          <mat-checkbox [checked]="ti.checked" (change)="checkBoxClick($event, ti)" [value]="ti.id">{{ti.nombre}}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div *ngIf="item.ftype === FilterType.Habitaciones" class="customCbContainer d-flex">
      <app-custom-cb class="mr15px" [checked]="false" [type]="item.ftype" [value]="1" text="1"
        (RbOnClick)="habitacionesChanged($event)"></app-custom-cb>
      <app-custom-cb class="mr15px" [checked]="false" [type]="item.ftype" [value]="2" text="2"
        (RbOnClick)="habitacionesChanged($event)"></app-custom-cb>
      <app-custom-cb class="mr15px" [checked]="false" [type]="item.ftype" [value]="3" text="3"
        (RbOnClick)="habitacionesChanged($event)"></app-custom-cb>
      <app-custom-cb class="mr15px" [checked]="false" [type]="item.ftype" [value]="4" text="4"
        (RbOnClick)="habitacionesChanged($event)"></app-custom-cb>
      <app-custom-cb [checked]="false" [type]="item.ftype" text="5+" [value]="5"
        (RbOnClick)="habitacionesChanged($event)"></app-custom-cb>
    </div>
    <div *ngIf="item.ftype === FilterType.Banos" class="customCbContainer d-flex">
      <app-custom-cb class="mr15px" [checked]="false" [type]="item.ftype" [value]="1" text="1"
        (RbOnClick)="BanosChanged($event)"></app-custom-cb>
      <app-custom-cb class="mr15px" [checked]="false" [type]="item.ftype" [value]="1.5" text="1.5"
        (RbOnClick)="BanosChanged($event)"></app-custom-cb>
      <app-custom-cb class="mr15px" [checked]="false" [type]="item.ftype" [value]="2" text="2"
        (RbOnClick)="BanosChanged($event)"></app-custom-cb>
      <app-custom-cb class="mr15px" [checked]="false" [type]="item.ftype" [value]="2.5" text="2.5"
        (RbOnClick)="BanosChanged($event)"></app-custom-cb>
      <app-custom-cb [checked]="false" [type]="item.ftype" text="3+" [value]="3" (RbOnClick)="BanosChanged($event)">
      </app-custom-cb>
    </div>
    <div *ngIf="item.ftype === FilterType.Estacionamiento" class="customCbContainer d-flex">
      <app-custom-cb class="mr15px" [checked]="false" [type]="item.ftype" [value]="1" text="1"
        (RbOnClick)="EstacionamientosChanged($event)"></app-custom-cb>
      <app-custom-cb class="mr15px" [checked]="false" [type]="item.ftype" [value]="2" text="2"
        (RbOnClick)="EstacionamientosChanged($event)"></app-custom-cb>
      <app-custom-cb [checked]="false" [type]="item.ftype" text="3+" [value]="3"
        (RbOnClick)="EstacionamientosChanged($event)"></app-custom-cb>
    </div>
    <div *ngIf="item.ftype === FilterType.Construccion && formConstruccion" class="customConstruccionTerrenoPrecio">
      <form [formGroup]="formConstruccion">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Desde</mat-label>
              <input id="construccionMin" (keydown.tab)="changeFocus('construccionMax', $event)" class="custom-white"
                formControlName="min" [allowedRegExp]="'^[0-9]*$'" (keyup)="changeFormConstruccion(true)"
                autocomplete="off" matInput>
              <div matSuffix class="suffixMinMax">m<sup>2</sup></div>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Hasta</mat-label>
              <input id="construccionMax" (keydown.tab)="changeFocus('construccionMin', $event)" class="custom-white"
                formControlName="max" [allowedRegExp]="'^[0-9]*$'" (keyup)="changeFormConstruccion(false)"
                autocomplete="off" matInput>
              <div matSuffix class="suffixMinMax">m<sup>2</sup></div>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="item.ftype === FilterType.Terreno && formTerreno" class="customConstruccionTerrenoPrecio">
      <form [formGroup]="formTerreno">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Desde</mat-label>
              <input id="terrenoMin" (keydown.tab)="changeFocus('terrenoMax', $event)" class="custom-white"
                formControlName="min" [allowedRegExp]="'^[0-9]*$'" (keyup)="changeFormTerreno(true)" autocomplete="off"
                matInput>
              <div matSuffix class="suffixMinMax">m<sup>2</sup></div>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Hasta</mat-label>
              <input id="terrenoMax" (keydown.tab)="changeFocus('terrenoMin', $event)" class="custom-white"
                formControlName="max" [allowedRegExp]="'^[0-9]*$'" (keyup)="changeFormTerreno(false)" autocomplete="off"
                matInput>
              <div matSuffix class="suffixMinMax">m<sup>2</sup></div>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="item.ftype === FilterType.Antiguedad" class="customCbContainer d-flex">
      <app-custom-cb class="mr15px" [checked]="false" [type]="item.ftype" [value]="5" text="Menor de 5 años"
        (RbOnClick)="AntiguedadChanged($event)"></app-custom-cb>
      <app-custom-cb class="mr15px" [checked]="false" [type]="item.ftype" [value]="10" text="10 años"
        (RbOnClick)="AntiguedadChanged($event)"></app-custom-cb>
      <app-custom-cb class="mr15px" [checked]="false" [type]="item.ftype" [value]="20" text="20 años"
        (RbOnClick)="AntiguedadChanged($event)"></app-custom-cb>
      <app-custom-cb class="mr15px" [checked]="false" [type]="item.ftype" [value]="30" text="30 años"
        (RbOnClick)="AntiguedadChanged($event)"></app-custom-cb>
      <app-custom-cb [checked]="false" [type]="item.ftype" [value]="99" text="Mayor de 30 años"
        (RbOnClick)="AntiguedadChanged($event)"></app-custom-cb>
    </div>
    <div *ngIf="item.ftype === FilterType.Amenidades">
      <div class="row normalCBContainer w336px">
        <div class="col-6 normalCB" *ngFor="let amenidad of Amenidades">
          <mat-checkbox [checked]="amenidad.checked" (click)="changeAmenidades($event, amenidad)" [value]="amenidad.id">
            {{amenidad.name}}</mat-checkbox>
        </div>
      </div>
    </div>
    <div *ngIf="item.ftype === FilterType.Precio && formPrecio" class="customConstruccionTerrenoPrecio customPrecio">
      <form [formGroup]="formPrecio">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Desde</mat-label>
              <input id="precioMin" (keydown.tab)="changeFocus('precioMax', $event)" class="custom-white"
                formControlName="min" [allowedRegExp]="'^[0-9]*$'" (keyup)="changeFormPrecio(true)" autocomplete="off"
                matInput>
              <div matSuffix class="suffixMinMax">MXN</div>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Hasta</mat-label>
              <input id="precioMax" (keydown.tab)="changeFocus('precioMin', $event)" class="custom-white"
                formControlName="max" [allowedRegExp]="'^[0-9]*$'" (keyup)="changeFormPrecio(false)" autocomplete="off"
                matInput>
              <div matSuffix class="suffixMinMax">MXN</div>
            </mat-form-field>
          </div>
        </div>
      </form>
      <div>
        <ngx-slider id="priceSlider" #priceSlider (userChangeEnd)="onChangePrice($event)"
          [manualRefresh]="manualRefreshPriceSlider" [(value)]="minValuePrice" [(highValue)]="maxValuePrice"
          [options]="options"></ngx-slider>
      </div>
    </div>
  </div>
</mat-menu>