import { AfterViewInit, Component, Input, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { CarouselType } from 'src/app/models/ImageSet';
import { Carousel2Component } from './Carousel2/Carousel2.component';
import { Carousel1Component } from './Carousel1/Carousel1.component';

@Component({
  selector: 'app-images-carousel',
  templateUrl: './ImagesCarousel.component.html',
  styleUrls: ['./ImagesCarousel.component.scss']
})
export class ImagesCarouselComponent implements AfterViewInit {
  @ViewChildren(Carousel1Component) carousels1: QueryList<Carousel1Component>;
  @ViewChildren(Carousel2Component) carousels2: QueryList<Carousel2Component>;
  @Input() Images: string[] = [];
  @Input() Width = '100px';
  @Input() Height = '100px';
  @Input() Pager = true;
  @Input() ShowIndex = true;
  @Input() ForceWH = false;
  @Input() ShowIndexIfSmall = true;
  @Input() ShowArrows = true;
  @Input() Infinite = false;
  @Input() Responsive = true;
  @Input() Type: CarouselType = 1;
  @Input() BorderRadious = '0px';
  CarouselType = CarouselType;
  constructor(private cdref: ChangeDetectorRef) {}
  ngAfterViewInit(): void {
  }
  refresh() {
    this.cdref.detectChanges();
    if (this.carousels1?.length > 0) {
      this.carousels1.forEach(item => item.refresh());
    }
    if (this.carousels2?.length > 0) {
      this.carousels2.forEach(item => item.refresh());
    }
  }
  // getImagesByType(type: CarouselType) {
  //   switch (type) {
  //     case CarouselType.images1:
  //       return this.Images;
  //       break;
  //     case CarouselType.images2:
  //       const array = Utils.CloneObject(this.Images);
  //       if (array.length % 2 !== 0) {
  //         array.push();
  //       }
  //       return this.Images;
  //       break;
  //     case CarouselType.images3:
  //       return this.Images;
  //       break;
  //     default:
  //       return this.Images;
  //       break;
  //   }
  // }
}
