<div class="fullContainer posRelative textCenter">
    <app-navbar [ShowExpanded]="false" [ShowSearchBar]="false"></app-navbar>
    <div class="childContainer">
        <div class="imgContainer">
            <div class="backgroundImg"></div>
        </div>
        <div class="display-flex-center-left wh100pr ovauto homeContainer">
            <div class="row imgSize hFitContent">
                <div class="col-lg-5 col-md-6 col-sm-12 hFitContent">
                    <div >
                        <div class="w100pr my30pxsm">
                            <div class="BigTitle mb30pxsm">
                                La mejor manera encontrar una propiedad
                            </div>
                            <div class="MidSubtitle hide-sm">
                                Nunca fue tan facil encontrar una propiedad
                            </div>
                            <form class="bg-white formContainer row" [formGroup]="form" (submit)="searchValue()">
                                <div class="col-sm-6 col-12">
                                    <mat-form-field class="customInputForButton customInputForIconInfix" appearance="outline">
                                        <mat-label>Tipo de búsqueda</mat-label>
                                        <div class="dflexCenter hide-sm" matPrefix>
                                            <mat-icon svgIcon="dollarHouse" ></mat-icon>
                                        </div>
                                        <mat-select formControlName="OptionsSelectBuyLease">
                                            <mat-option *ngFor="let item of optionsBuyLease" [value]="item.type">{{item.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-6 col-12">
                                    <mat-form-field class="customInputForButton customInputForIconInfix" appearance="outline">
                                        <mat-label>Tipo de asentamiento</mat-label>
                                        <div class="dflexCenter hide-sm" matPrefix>
                                            <mat-icon svgIcon="heartHouse" ></mat-icon>
                                        </div>
                                        <mat-select formControlName="TipoInmueble">
                                            <mat-option *ngFor="let item of (tiposInmuebles | async)" [value]="item.id">{{item.nombre}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <mat-form-field class="customInputForButton" appearance="outline">
                                        <mat-label>Tu ubicación ideal va aquí</mat-label>
                                        <input ngx-google-places-autocomplete #placesRef="ngx-places" [options]="options" (onAddressChange)="handleAddressChange($event)" class="custom-white" formControlName="Search" #search id="search" autocomplete="off" matInput>
                                        <button mat-button matSuffix mat-stroked-button aria-label="search" class="degradadoStrokedBtn customBtnInsideInput hide-sm" type="submit">Buscar</button>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <button class="degradadoStrokedBtn show-sm mffmb" type="submit" mat-stroked-button matSuffix >Buscar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!--
                <app-noticias-item></app-noticias-item>
            -->
        </div>
    </div>
</div>
