import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UsuarioRegistro } from 'src/app/models/Usuario';
import { RepositoryService } from 'src/app/services/Repository/Repository.service';
import { SetCurrentUser } from 'src/app/services/Store/Actions';
import { Utils } from 'src/app/Utils/Utils';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-Registro',
  templateUrl: './Registro.component.html',
  styleUrls: ['./Registro.component.scss']
})
export class RegistroComponent implements OnInit {
  loadingInit = true;
  loading = false;
  Utils = Utils;
  hide = true;
  hide2 = true;
  formRegistro: FormGroup;
  constructor(private fb: FormBuilder, private router: Router, private crud: RepositoryService, private store: Store) {
  }
  ngOnInit(): void {
    const body: UsuarioRegistro = {
      name: '',
      last_name: '',
      phone: '',
      email: '',
      password: '',
      password2: ''
    };
    this.formRegistro = this.fb.group(body);
    this.formRegistro.controls.name.setValidators([Validators.required]);
    this.formRegistro.controls.last_name.setValidators([Validators.required]);
    this.formRegistro.controls.phone.setValidators([Validators.required, Validators.maxLength(10), Validators.minLength(10)]);
    this.formRegistro.controls.email.setValidators([Validators.required, Validators.pattern(Utils.emailPattern)]);
    this.formRegistro.controls.password.setValidators([Validators.required,
    this.MatchPassword, Validators.minLength(8), Validators.maxLength(16)]);
    this.formRegistro.controls.password2.setValidators([Validators.required,
    this.MatchPassword, Validators.minLength(8), Validators.maxLength(16)]);
    this.loadingInit = false;
  }
  MatchPassword(AC: UntypedFormControl) {
    const dataForm = AC.parent;
    if (!dataForm) { return null; }
    const newPasswordRepeat = dataForm.get('password2');
    const password = dataForm.get('password')?.value;
    const confirmPassword = newPasswordRepeat?.value;
    const pass2CtrlSTR = 'password2';
    if ((password !== '' || confirmPassword !== '') && password !== confirmPassword) {
      /* for newPasswordRepeat from current field "newPassword" */
      dataForm.controls[pass2CtrlSTR].setErrors( {MatchPassword: true} );
      if ( newPasswordRepeat === AC ) {
        /* for current field "newPasswordRepeat" */
        return {newPasswordRepeat: {MatchPassword: true} };
      }
    } else {
      dataForm.controls[pass2CtrlSTR].setErrors( null );
    }
    return null;
  }
  register() {
    const body: UsuarioRegistro = this.formRegistro.value;;
    body.email = body.email.trim().toLowerCase();
    body.name = body.name.trim();
    body.last_name = body.last_name.trim();
    this.loading = true;
    Utils.firstValueFrom(this.crud.PostPetition('user/create/', body, false, false)).then((res: any) => {
      this.store.dispatch(new SetCurrentUser(res.token));
      this.loading = false;
      this.router.navigateByUrl('/');
    }).catch(error => {
      this.crud.ShowErrors(error);
      this.loading = false;
    });
  }
  login() {
    this.router.navigateByUrl('/Login');
  }
}
