<div *ngIf="usuario | async" class="formContainer">
  <form [formGroup]="form" (submit)="submitUser()" *ngIf="form">
    <mat-form-field
      class="w100pr pb24px custom-semi-oval-mat-form-field custom-blue-mat-form-field"
      appearance="outline"
    >
      <mat-label>Nombre</mat-label>
      <input matInput formControlName="name" autocomplete="name" />
      <mat-error *ngIf="form?.controls?.name?.errors?.required">{{
        Utils.datoRequerido
      }}</mat-error>
      <mat-error *ngIf="form?.controls?.name?.errors?.pattern">{{
        Utils.formatoIncorrecto
      }}</mat-error>
    </mat-form-field>
    <mat-form-field
      class="w100pr pb24px custom-semi-oval-mat-form-field custom-blue-mat-form-field"
      appearance="outline"
    >
      <mat-label>Apellidos</mat-label>
      <input
        matInput
        formControlName="last_name"
        autocomplete="additional-name"
      />
      <mat-error *ngIf="form?.controls?.last_name?.errors?.required">{{
        Utils.datoRequerido
      }}</mat-error>
      <mat-error *ngIf="form?.controls?.last_name?.errors?.pattern">{{
        Utils.formatoIncorrecto
      }}</mat-error>
    </mat-form-field>
    <mat-form-field
      class="w100pr pb24px custom-semi-oval-mat-form-field custom-blue-mat-form-field"
      appearance="outline"
    >
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" autocomplete="email" />
    </mat-form-field>
    <mat-form-field
      class="w100pr pb24px custom-semi-oval-mat-form-field custom-blue-mat-form-field"
      appearance="outline"
    >
      <mat-label>Teléfono</mat-label>
      <input
        matInput
        formControlName="phone"
        appNumberOnly
        maxlength="10"
        autocomplete="tel"
      />
      <mat-error *ngIf="form?.controls?.phone?.errors?.required">{{
        Utils.datoRequerido
      }}</mat-error>
      <mat-error *ngIf="form?.controls?.phone?.errors?.minlength">{{
        Utils.minLength.replace("{0}", "10")
      }}</mat-error>
      <mat-error *ngIf="form?.controls?.phone?.errors?.maxlength">{{
        Utils.maxLength.replace("{0}", "10")
      }}</mat-error>
    </mat-form-field>
    <button
      mat-flat-button
      [disabled]="form.invalid || !this.form.dirty || loading"
      [class.spinner]="loading"
      class="button btnBlue2 submitBtn"
      type="submit"
    >
      Guardar
    </button>
  </form>
</div>
