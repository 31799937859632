<div class="conFilters">
  <div class="backBtnSection d-flex">
    <button (click)="closeFilters()" class="backButton">
      <mat-icon svgIcon="arrow_back_blue"></mat-icon>
    </button>
    <div class="filtrosText wwfa">
      Filtros
    </div>
    <button (click)="clearFilters()" class="backButton deleteBtn">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <div class="tagTitles">Tipo de Inmueble</div>
  <div (click)="$event.stopPropagation();">
    <div class="normalCBContainer">
      <div class="d-block normalCB" *ngFor="let ti of (tiposInmuebles | async)">
        <mat-checkbox [checked]="ti.checked" (click)="checkBoxClick($event, ti)" [value]="ti.id">{{ti.nombre}}</mat-checkbox>
      </div>
    </div>
  </div>
  <div class="tagTitles">Precio</div>
  <div *ngIf="formPrecio" class="customConstruccionTerrenoPrecio customPrecio">
    <form [formGroup]="formPrecio">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Desde</mat-label>
            <input id="precioMin" (keydown.tab)="changeFocus('precioMax', $event)" class="custom-white" formControlName="min" [allowedRegExp]="'^[0-9]*$'" (keyup)="changeFormPrecio(true)" autocomplete="off" matInput>
            <div matSuffix class="suffixMinMax">MXN</div>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Hasta</mat-label>
            <input id="precioMax" (keydown.tab)="changeFocus('precioMin', $event)" class="custom-white" formControlName="max" [allowedRegExp]="'^[0-9]*$'" (keyup)="changeFormPrecio(false)" autocomplete="off" matInput>
            <div matSuffix class="suffixMinMax">MXN</div>
          </mat-form-field>
        </div>
      </div>
    </form>
    <div>
      <ngx-slider id="priceSlider" #priceSlider (userChangeEnd)="onChangePrice($event)" [manualRefresh]="manualRefreshPriceSlider" [(value)]="minValuePrice" [(highValue)]="maxValuePrice" [options]="options"></ngx-slider>
    </div>
  </div>
  <div class="tagTitles">Habitaciones</div>
  <div class="customCbContainer d-flex">
    <app-custom-cb [isMobile]="true" class="mr15px" [checked]="false" [type]="FilterType.Habitaciones" [value]="1" text="1" (RbOnClick)="habitacionesChanged($event)"></app-custom-cb>
    <app-custom-cb [isMobile]="true" class="mr15px" [checked]="false" [type]="FilterType.Habitaciones" [value]="2" text="2" (RbOnClick)="habitacionesChanged($event)"></app-custom-cb>
    <app-custom-cb [isMobile]="true" class="mr15px" [checked]="false" [type]="FilterType.Habitaciones" [value]="3" text="3" (RbOnClick)="habitacionesChanged($event)"></app-custom-cb>
    <app-custom-cb [isMobile]="true" class="mr15px" [checked]="false" [type]="FilterType.Habitaciones" [value]="4" text="4" (RbOnClick)="habitacionesChanged($event)"></app-custom-cb>
    <app-custom-cb [isMobile]="true" [checked]="false" [type]="FilterType.Habitaciones" text="5+" [value]="5" (RbOnClick)="habitacionesChanged($event)"></app-custom-cb>
  </div>
  <div class="tagTitles">Baños</div>
  <div class="customCbContainer d-flex">
    <app-custom-cb [isMobile]="true" class="mr15px" [checked]="false" [type]="FilterType.Banos" [value]="1" text="1" (RbOnClick)="BanosChanged($event)"></app-custom-cb>
    <app-custom-cb [isMobile]="true" class="mr15px" [checked]="false" [type]="FilterType.Banos" [value]="1.5" text="1.5" (RbOnClick)="BanosChanged($event)"></app-custom-cb>
    <app-custom-cb [isMobile]="true" class="mr15px" [checked]="false" [type]="FilterType.Banos" [value]="2" text="2" (RbOnClick)="BanosChanged($event)"></app-custom-cb>
    <app-custom-cb [isMobile]="true" class="mr15px" [checked]="false" [type]="FilterType.Banos" [value]="2.5" text="2.5" (RbOnClick)="BanosChanged($event)"></app-custom-cb>
    <app-custom-cb [isMobile]="true" [checked]="false" [type]="FilterType.Banos" text="3+" [value]="3" (RbOnClick)="BanosChanged($event)"></app-custom-cb>
  </div>
  <div class="tagTitles">Estacionamiento(s)</div>
  <div class="customCbContainer d-flex">
    <app-custom-cb [isMobile]="true" class="mr15px" [checked]="false" [type]="FilterType.Estacionamiento" [value]="1" text="1" (RbOnClick)="EstacionamientosChanged($event)"></app-custom-cb>
    <app-custom-cb [isMobile]="true" class="mr15px" [checked]="false" [type]="FilterType.Estacionamiento" [value]="2" text="2" (RbOnClick)="EstacionamientosChanged($event)"></app-custom-cb>
    <app-custom-cb [isMobile]="true" [checked]="false" [type]="FilterType.Estacionamiento" text="3+" [value]="3" (RbOnClick)="EstacionamientosChanged($event)"></app-custom-cb>
  </div>
  <div class="tagTitles">Amenidades</div>
  <div>
    <div class="row normalCBContainer">
      <div class="col-6 normalCB" *ngFor="let amenidad of Amenidades">
        <mat-checkbox [checked]="amenidad.checked" (click)="changeAmenidades($event, amenidad)" [value]="amenidad.id">{{amenidad.name}}</mat-checkbox>
      </div>
    </div>
  </div>
  <div class="tagTitles">Antigüedad (años)</div>
  <div class="customCbContainer d-flex">
    <app-custom-cb [isMobile]="true" class="mr15px" [checked]="false" [type]="FilterType.Antiguedad" [value]="5" text="<5" (RbOnClick)="AntiguedadChanged($event)"></app-custom-cb>
    <app-custom-cb [isMobile]="true" class="mr15px" [checked]="false" [type]="FilterType.Antiguedad" [value]="10" text="10" (RbOnClick)="AntiguedadChanged($event)"></app-custom-cb>
    <app-custom-cb [isMobile]="true" class="mr15px" [checked]="false" [type]="FilterType.Antiguedad" [value]="20" text="20" (RbOnClick)="AntiguedadChanged($event)"></app-custom-cb>
    <app-custom-cb [isMobile]="true" class="mr15px" [checked]="false" [type]="FilterType.Antiguedad" [value]="30" text="30" (RbOnClick)="AntiguedadChanged($event)"></app-custom-cb>
    <app-custom-cb [isMobile]="true"                [checked]="false" [type]="FilterType.Antiguedad" [value]="99" text=">30" (RbOnClick)="AntiguedadChanged($event)"></app-custom-cb>
  </div>
  <div class="tagTitles">Construcción</div>
  <div *ngIf="formConstruccion" class="customConstruccionTerrenoPrecio">
    <form [formGroup]="formConstruccion">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Desde</mat-label>
            <input id="construccionMin" (keydown.tab)="changeFocus('construccionMax', $event)" class="custom-white" formControlName="min" [allowedRegExp]="'^[0-9]*$'" (keyup)="changeFormConstruccion(true)" autocomplete="off" matInput>
            <div matSuffix class="suffixMinMax">m<sup>2</sup></div>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Hasta</mat-label>
            <input id="construccionMax" (keydown.tab)="changeFocus('construccionMin', $event)" class="custom-white" formControlName="max" [allowedRegExp]="'^[0-9]*$'" (keyup)="changeFormConstruccion(false)" autocomplete="off" matInput>
            <div matSuffix class="suffixMinMax">m<sup>2</sup></div>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="tagTitles">Terreno</div>
  <div *ngIf="formTerreno" class="customConstruccionTerrenoPrecio">
    <form [formGroup]="formTerreno">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Desde</mat-label>
            <input id="terrenoMin" (keydown.tab)="changeFocus('terrenoMax', $event)" class="custom-white" formControlName="min" [allowedRegExp]="'^[0-9]*$'" (keyup)="changeFormTerreno(true)" autocomplete="off" matInput>
            <div matSuffix class="suffixMinMax">m<sup>2</sup></div>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Hasta</mat-label>
            <input id="terrenoMax" (keydown.tab)="changeFocus('terrenoMin', $event)" class="custom-white" formControlName="max" [allowedRegExp]="'^[0-9]*$'" (keyup)="changeFormTerreno(false)" autocomplete="off" matInput>
            <div matSuffix class="suffixMinMax">m<sup>2</sup></div>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>