import { ChangeDetectorRef } from '@angular/core';
import { Component, Input, AfterViewInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { Utils } from 'src/app/Utils/Utils';

@Component({
  selector: 'app-carousel2',
  templateUrl: './Carousel2.component.html',
  styleUrls: ['./Carousel2.component.scss']
})
export class Carousel2Component implements AfterViewInit {
  @ViewChild('sliderCarr', {read: IonSlides}) slides: IonSlides;
  @Input() Images: string[] = [];
  @Input() Width = '100px';
  @Input() Height = '100px';
  @Input() ForceWH = false;
  @Input() ShowArrows = true;
  @Input() BorderRadious = '0px';
  @Input() Infinite = false;
  currentIndex = [-1];
  imagesToShow: string[][] = [];
  constructor(private ref: ChangeDetectorRef) { }
  refresh() {
    this.ref.detectChanges();
  }
  ngAfterViewInit(): void {
    // if (this.Images.length === 1) {
    //   this.Images.push(this.Images[0]);
    // }
    const indexArray = [];
    const imagesToShow: string[][] = [];
    let objct = [];
    let index = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.Images.length; i++) {
      if ((i + 1) % 2  !== 0) {
        objct.push(this.Images[i]);
        if (this.Images.length === i + 1) {
          objct.push(null);
          imagesToShow.push(Utils.CloneObject(objct));
          indexArray.push(index);
          index++;
        }
      } else {
        objct.push(this.Images[i]);
        imagesToShow.push(Utils.CloneObject(objct));
        objct = [];
        indexArray.push(index);
        index++;
      }
    }
    this.imagesToShow = imagesToShow;
    this.currentIndex = indexArray;
    this.ref.detectChanges();
  }
  lbuttonClick() {
    if (this.currentIndex[0] !== -1) {
      const item1 = this.currentIndex.pop(); // Before
      this.currentIndex.unshift(item1);
      this.slides.slideTo(this.currentIndex[0]);
    }
  }
  rbuttonClick() {
    if (this.currentIndex[0] !== -1) {
      const item1 = this.currentIndex.shift(); // Next
      this.currentIndex.push(item1);
      this.slides.slideTo(this.currentIndex[0]);
    }
  }
  lbuttonVisible() {
    if (this.currentIndex[0] === -1) {
      return false;
    }
    return (this.currentIndex[0] > 0 && this.ShowArrows) || (this.Infinite && this.imagesToShow.length > 1);
  }
  rbuttonVisible() {
    if (this.currentIndex[0] === -1) {
      return false;
    }
    return (this.currentIndex[0] < this.imagesToShow?.length - 1 && this.ShowArrows) || (this.Infinite && this.imagesToShow.length > 1);
  }
  changed() {
    this.slides.getActiveIndex().then(res => {
      this.currentIndex[0] = res;
    });
  }
}
