import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Utils } from 'src/app/Utils/Utils';
import { ProspectoComprador } from 'src/app/models/ProspectoComprador';
import { RepositoryService } from 'src/app/services/Repository/Repository.service';
import { House } from 'src/app/models/House';
import { UtilService } from 'src/app/services/UtilService.service';
import { Usuario } from 'src/app/models/Usuario';

@Component({
  selector: 'app-prospecto-comprador',
  templateUrl: './prospecto-comprador.component.html',
  styleUrls: ['./prospecto-comprador.component.scss'],
})
export class ProspectoCompradorComponent implements OnInit {
  @Input() house: House = null;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  Utils = Utils;
  form: UntypedFormGroup = null;
  loading = false;
  constructor(private fb: UntypedFormBuilder, private crud: RepositoryService, private readonly utilsService: UtilService) { }
  ngOnInit() {
    this.initForm(null);
    this.utilsService.getCurrentUser().then(res => {
      this.initForm(res);
    });
  }
  initForm(usuario: Usuario | null) {
    if (usuario) {
      const data: ProspectoComprador = new ProspectoComprador();
      data.correo = usuario.email;
      data.interested_phone_number = usuario.phone;
      data.nombre = usuario.name + ' ' + usuario.last_name;
      this.form = this.fb.group(data);
      this.form.controls.nombre.setValidators([Validators.required]);
      this.form.controls.correo.setValidators([Validators.required, Validators.pattern(Utils.emailPattern)]);
      // tslint:disable-next-line: max-line-length
      this.form.controls.interested_phone_number.setValidators([Validators.required, Validators.pattern('[0-9]+'), Validators.maxLength(10), Validators.minLength(10)]);
    } else {
      const data: ProspectoComprador = new ProspectoComprador();
      this.form = this.fb.group(data);
      this.form.controls.nombre.setValidators([Validators.required]);
      this.form.controls.correo.setValidators([Validators.required, Validators.pattern(Utils.emailPattern)]);
      // tslint:disable-next-line: max-line-length
      this.form.controls.interested_phone_number.setValidators([Validators.required, Validators.pattern('[0-9]+'), Validators.maxLength(10), Validators.minLength(10)]);
    }
  }
  submitInterested() {
    this.loading = true;
    const body: ProspectoComprador = this.form.value;
    body.inmueble = this.house.id;
    Utils.firstValueFrom(this.crud.PostPetition('inmueble/prospecto/comprador/', body, false, true)).then(res => {
      this.loading = false;
      this.closeModal.emit();
    }).catch(err => {
      this.loading = false;
    });
  }
}
