import { Utils } from 'src/app/Utils/Utils';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/Repository/Repository.service';
import { AfterViewInit, Component, HostListener, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { House } from 'src/app/models/House';
import { Carousel1Component } from 'src/app/Common/ImagesCarousel/Carousel1/Carousel1.component'
import { Usuario } from 'src/app/models/Usuario';
import { AppStateService } from 'src/app/services/Store/AppState.service';
import { ProspectoCompradorModalComponent } from './ProspectoComprador/ProspectoCompradorModal/ProspectoCompradorModal.component';
import { Store } from '@ngxs/store';
import { ShowLoading } from 'src/app/services/Store/Actions';
import { LatLng } from 'src/app/models/LatLng';
import { GoogleMap } from '@angular/google-maps';
import * as alertify from 'alertifyjs';
import { ShareSource } from 'src/app/models/Enum';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-house-page',
  templateUrl: './HousePage.component.html',
  styleUrls: ['./HousePage.component.scss']
})
export class HousePageComponent implements AfterViewInit, OnDestroy {
  @ViewChildren('GGMapHP') maps: QueryList<GoogleMap>;
  @ViewChildren(Carousel1Component) imagesCarousel: QueryList<Carousel1Component>;
  house: House = null;
  urlShare = '';
  imageShare = '';
  titleShare = '';
  longDesc = false;
  descriptionShare = '';
  tagsShare = '#Propefy';
  CurrentUser: Usuario = null;
  subs: Subscription = null;
  likeLoading = false;
  shareMode = false;
  maxHeightImgs = '0px';
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  options: google.maps.MapOptions = {
    mapTypeId: 'terrain',
    mapTypeControl: false,
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    fullscreenControl: false,
    draggable: false,
    streetViewControl: false,
    maxZoom: 19,
    minZoom: 1,
    styles: [
      {
        featureType: 'poi',
        stylers: [
          { visibility: 'off' }
        ]
      }
    ]
  };
  mapdata: LatLng = null;
  // tslint:disable-next-line: max-line-length
  constructor(private crud: RepositoryService, private route: ActivatedRoute, private router: Router, private assSelector: AppStateService, private dialog: MatDialog, private store: Store) {
    this.subs = this.assSelector.CurrentUser.subscribe(user => {
      if (!this.CurrentUser && Utils.IsLogged(user)) {
        this.CurrentUser = user;
      } else if (this.CurrentUser) {
        this.CurrentUser = null;
      }
    });
  }
  @HostListener('window:resize')
  public detectResize(): void {
    const offsetH = document.getElementById('fullContainer')?.offsetHeight;
    if (offsetH) {
      this.maxHeightImgs = (offsetH * 0.7).toString() + 'px';
      for (const item of this.imagesCarousel) {
        item.MaxHeight = this.maxHeightImgs;
      }
    }
    Utils.getSublocalityByLatLng(this.mapdata).then(locality => {
      const negm = new google.maps.LatLng(locality.geometry.bounds.getNorthEast().lat(), locality.geometry.bounds.getNorthEast().lng());
      const swgm = new google.maps.LatLng(locality.geometry.bounds.getSouthWest().lat(), locality.geometry.bounds.getSouthWest().lng());
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds(swgm, negm);
      let mapLoaded = this.maps?.first != null;
      if (mapLoaded) {
        for (const map of this.maps) {
          map.fitBounds(bounds, 0);
        }
      } else {
        const interval2 = setInterval(() => {
          if (!mapLoaded) {
            if (this.maps?.first != null) {
              mapLoaded = true;
              for (const map of this.maps) {
                map.fitBounds(bounds, 0);
              }
              clearInterval(interval2);
            }
          }
        }, 500);
      }
    });
  }
  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
  hasAmenidad(amenidadId: number): boolean {
    return this.house?.amenidades?.some(a => a === amenidadId) || true;
  }
  ngAfterViewInit(): void {
    if (!this.route.snapshot.params.houseId || this.route.snapshot.params.houseId === '') {
      this.router.navigateByUrl('/NotResultsFound');
    }
    this.store.dispatch(new ShowLoading(true));
    // tslint:disable-next-line: max-line-length
    Utils.firstValueFrom(this.crud.GetPetition('inmueble/public/inmuebles/' + this.route.snapshot.params.houseId + '/', false, true)).then(res => {
      if (!res) {
        this.router.navigateByUrl('/NotResultsFound');
        this.house = null;
      }
      this.house = Utils.ProcessHouse(res);
      if (this.route.snapshot.params?.houseName !== this.house.TituloUrl) {
        this.router.navigateByUrl('/House/' + this.house.id + '/' + this.house.TituloUrl);
      }
      this.house.imagenesToShow = this.house.imagenes_set.map(img => img.photo);
      this.urlShare = location.origin + '/#/House/' + this.house.id + '/' + this.house.TituloUrl + '&ShareType=' + ShareSource.ShareButton;
      this.imageShare = this.house.imagenes_set[0].photo;
      this.titleShare = this.house.titulo;
      this.descriptionShare = this.house.ShortDesc;
      this.store.dispatch(new ShowLoading(false));
      this.mapdata = {
        Lat: this.house.latitud,
        Lng: this.house.longitud,
      };
      const interval = setInterval(() => {
        const containers = document.getElementsByClassName('amenidadesContainer');
        if (containers?.length > 0) {
          clearInterval(interval);
          this.processAmenidades();
        }
      }, 500);
      this.detectResize();
    }).catch(err => {
      this.router.navigateByUrl('/NotResultsFound');
    });
  }
  setLike() {
    this.likeLoading = true;
    Utils.firstValueFrom(this.crud.PatchPetition('inmueble/like/inmuebles/' + this.house.id + '/')).then((res: House) => {
      this.house.is_liked = res.is_liked;
      this.likeLoading = false;
      alertify.notify('¡Modificado con éxito!', 'success', 5);
    });
  }
  processAmenidades() {
    const containers = document.getElementsByClassName('amenidadesContainer');
    if (containers?.length > 0) {
      const amenidadesContainer = document.createElement('table');
      if (this.house?.amenidades?.length > 0) {
        let td = document.createElement('tr');
        if (this.house?.amenidades?.some(a => a === 1)) {
          td.innerHTML += `
          <td class="pb26px">
            <div class="cardIconCont">
              <img class="cardIcon" src="/assets/icons/alberca.svg">
              <div class="cardIconText">Alberca</div>
            </div>
          </td>
          `;
        }
        if (this.house?.amenidades?.some(a => a === 2)) {
          td.innerHTML += `
          <td class="pb26px">
            <div class="cardIconCont">
              <img class="cardIcon" src="/assets/icons/jardin.svg">
              <div class="cardIconText">Jardín</div>
            </div>
          </td>
          `;
        }
        if (this.house?.amenidades?.some(a => a === 3)) {
          td.innerHTML += `
          <td class="pb26px">
            <div class="cardIconCont">
              <img class="cardIcon" src="/assets/icons/elevador.svg">
              <div class="cardIconText">Elevador</div>
            </div>
          </td>
          `;
        }
        if (this.house?.amenidades?.some(a => a === 4)) {
          td.innerHTML += `
          <td class="pb26px">
            <div class="cardIconCont">
              <img class="cardIcon" src="/assets/icons/vapor.svg">
              <div class="cardIconText">Vapor</div>
            </div>
          </td>
          `;
        }
        if (td.children.length === 4) {
          amenidadesContainer.appendChild(td);
          td = document.createElement('tr');
        }
        if (this.house?.amenidades?.some(a => a === 5)) {
          td.innerHTML += `
          <td class="pb26px">
            <div class="cardIconCont">
              <img class="cardIcon" src="/assets/icons/condominio.svg">
              <div class="cardIconText">Condominio</div>
            </div>
          </td>
          `;
        }
        if (td.children.length === 4) {
          amenidadesContainer.appendChild(td);
          td = document.createElement('tr');
        }
        if (this.house?.amenidades?.some(a => a === 6)) {
          td.innerHTML += `
          <td class="pb26px">
            <div class="cardIconCont">
              <img class="cardIcon" src="/assets/icons/coto_privado.svg">
              <div class="cardIconText">Coto Privado</div>
            </div>
          </td>
          `;
        }
        if (td.children.length === 4) {
          amenidadesContainer.appendChild(td);
          td = document.createElement('tr');
        }
        if (this.house?.amenidades?.some(a => a === 7)) {
          td.innerHTML += `
          <td class="pb26px">
            <div class="cardIconCont">
              <img class="cardIcon" src="/assets/icons/roof_garden.svg">
              <div class="cardIconText">Roof Garden</div>
            </div>
          </td>
          `;
        }
        if (td.children.length === 4) {
          amenidadesContainer.appendChild(td);
          td = document.createElement('tr');
        }
        if (this.house?.amenidades?.some(a => a === 8)) {
          td.innerHTML += `
          <td class="pb26px">
            <div class="cardIconCont">
              <img class="cardIcon" src="/assets/icons/balcon.svg">
              <div class="cardIconText">Balcón</div>
            </div>
          </td>
          `;
        }
        amenidadesContainer.appendChild(td);
      }
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < containers.length; i++) {
        const element = containers[i];
        if (element?.children?.length > 0) {
          element.removeChild(element.firstChild);
        }
        element.appendChild(amenidadesContainer.cloneNode(true));
      }
    }
  }
  showShare() {
    this.shareMode = true;
  }
  showMoreDesc() {
    this.longDesc = true;
  }
  contactanosClick() {
    this.dialog.open(ProspectoCompradorModalComponent, {
      minWidth: '250px',
      maxWidth: '95vw',
      data: this.house,
    });
  }
}
