import { AppStateService } from './../Store/AppState.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, delay, mergeMap, retryWhen, shareReplay } from 'rxjs/operators';
import * as alertify from 'alertifyjs';
import { Platform } from '@ionic/angular';
import { ShareSource } from 'src/app/models/Enum';
@Injectable({
  providedIn: 'root'
})
export class RepositoryService {
  apiValue = '/ProxyTest/';
  frontURLs: string[] = [
    'http://localhost:4200',
    'https://master.d2vb22obajpglr.amplifyapp.com',
    'https://www.propefy.com',
    'https://dev.d2vb22obajpglr.amplifyapp.com'
  ];
  constructor(private http: HttpClient, private platform: Platform, private ass: AppStateService) {
    if (this.frontURLs.some(URL => location.origin === URL)) {
      this.apiValue = environment.URL + 'api/';
    }
  }
  private getHeadders() {
    const token = localStorage.getItem('token');
    if (token) {
      return { headers: new HttpHeaders().append('Authorization', 'Token ' + token) };
    } else {
      return { headers: new HttpHeaders() };
    }
  }
  SetSource(source: ShareSource) {
    // TODO
  }
  GetPetition(URL: string, CustomOrigin = false, HeaddersOn = true, CustomHeadders: {headers: HttpHeaders} = null): Observable<any> {
    let headders: {headers: HttpHeaders} = CustomHeadders;
    if (!headders) {
      headders = this.getHeadders();
    }
    if (!CustomOrigin) {
      URL = this.apiValue + URL;
    }
    if (HeaddersOn) {
      if (URL === '/ProxyTest/user/me') {
        // const { Http } = Plugins;
        // return from(Http.request({
        //   method
        // }))
        return this.http.get('/GetMe/', headders);
      }
      return this.http.get(URL, headders);
    } else {
      return this.http.get(URL);
    }
  }
  // tslint:disable-next-line: max-line-length
  PostPetition(URL: string, Body: any = null, CustomOrigin = false, HeaddersOn = true, CustomHeadders: {headers: HttpHeaders} = null): Observable<any> {
    let headders: {headers: HttpHeaders} = CustomHeadders;
    if (!headders) {
      headders = this.getHeadders();
    }
    if (!CustomOrigin) {
      URL = this.apiValue + URL;
    }
    if (HeaddersOn) {
      return this.http.post(URL, Body, headders);
    } else {
      return this.http.post(URL, Body);
    }
  }
  // tslint:disable-next-line: max-line-length
  PutPetition(URL: string, Body: any = null, CustomOrigin = false, HeaddersOn = true, CustomHeadders: {headers: HttpHeaders} = null): Observable<any> {
    let headders: {headers: HttpHeaders} = CustomHeadders;
    if (!headders) {
      headders = this.getHeadders();
    }
    if (!CustomOrigin) {
      URL = this.apiValue + URL;
    }
    if (HeaddersOn) {
      return this.http.put(URL, Body, headders);
    } else {
      return this.http.put(URL, Body);
    }
  }
  // tslint:disable-next-line: max-line-length
  PatchPetition(URL: string, Body: any = null, CustomOrigin = false, HeaddersOn = true, CustomHeadders: {headers: HttpHeaders} = null): Observable<any> {
    let headders: {headers: HttpHeaders} = CustomHeadders;
    if (!headders) {
      headders = this.getHeadders();
    }
    if (!CustomOrigin) {
      URL = this.apiValue + URL;
    }
    if (HeaddersOn) {
      return this.http.patch(URL, Body, headders);
    } else {
      return this.http.patch(URL, Body);
    }
  }
  ShowErrors(error) {
    if (error.status === 404) {
      alertify.notify('Not found page.', 'error', 3);
    } else {
      for (const key of Object.keys(error?.error)) {
        while (true) {
          try {
            const CurrentError = error?.error[key]?.pop();
            if (CurrentError) {
              alertify.notify(CurrentError, 'error', 3);
            }
            else {
              break;
            }
          } catch (error) {
            break;
          }
        }
      }
    }
  }
  private delayedRetry(delayMs: number, maxRetry: number) {
    let retries = maxRetry;
    return (src: Observable<any>) => src.pipe(
      retryWhen((errors: Observable<any>) => errors.pipe(
        delay(delayMs),
        mergeMap((error) => {
          if (retries-- > 0) {
            return of(error);
          } else {
            const err = new Error('test');
            return throwError(() => err);
          }
        })
      ))
    );
  }
}
