<div class="contfather blackBG" [style.width]="Width" [style.height]="Height" *ngIf="currentIndex[0] != -1">
  <div class="lbuttoncarrcont" *ngIf="lbuttonVisible()">
    <button mat-mini-fab class="carrbtn" (click)="lbuttonClick()"><mat-icon>chevron_left</mat-icon></button>
  </div>
  <ion-slides class="drawAndDrop" #sliderCarr (ionSlideDidChange)="changed()" [pager]="false" >
    <ion-slide *ngFor="let group of imagesToShow; let i=index">
      <div class="posRelative" [style.width]="'50%'" [style.height]="'100%'">
        <img [src]="group[0]" class="carrImg" [style.width]="'100%'" [style.border-radius]="BorderRadious" [style.height]="'100%'"/>
      </div>
      <div class="posRelative" [style.width]="'50%'" [style.height]="'100%'">
        <img [src]="group[1]" *ngIf="group[1]" class="carrImg" [style.width]="'100%'" [style.height]="'100%'"/>
      </div>
    </ion-slide>
  </ion-slides>
  <div class="rbuttoncarrcont" *ngIf="rbuttonVisible()">
    <button mat-mini-fab class="carrbtn" (click)="rbuttonClick()"><mat-icon>chevron_right</mat-icon></button>
  </div>
</div>
