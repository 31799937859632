import { Search } from 'src/app/models/Search';
import { RepositoryService } from './../../../services/Repository/Repository.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { FilterType } from './../../../models/FiltersChips';
import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter, QueryList, ViewChildren } from '@angular/core';
import { FiltersChips } from 'src/app/models/FiltersChips';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from 'src/app/Utils/Utils';
import { AmenidadesCB, FormConstruccionTerrenoPrecio, TipoInmueblesCB } from 'src/app/models/Filters';
import { AppStateService } from 'src/app/services/Store/AppState.service';
import { BehaviorSubject } from 'rxjs';
import { map, tap, skipWhile, take } from 'rxjs/operators';
import { CustomCbComponent } from '../../CustomCb/customCb.component';
import * as alertify from 'alertifyjs';
import { ChangeContext, LabelType, Options } from '@angular-slider/ngx-slider';
import { SliderComponent } from '@angular-slider/ngx-slider/slider.component';

@Component({
  selector: 'app-filteritem',
  templateUrl: './FilterItem.component.html',
  styleUrls: ['./FilterItem.component.scss']
})
export class FilterItemComponent implements OnInit {
  @ViewChildren(CustomCbComponent) cbComponents!: QueryList<CustomCbComponent>;
  @ViewChildren('priceSlider') priceSlider: QueryList<SliderComponent>;
  @Input() item: FiltersChips = null;
  Utils = Utils;
  FilterType = FilterType;
  Search: Search = null;
  manualRefreshPriceSlider: EventEmitter<void> = new EventEmitter();
  minHabitaciones: number = null;
  maxHabitaciones: number = null;
  minAntiguedad: number = null;
  maxAntiguedad: number = null;
  minBanos: number = null;
  maxBanos: number = null;
  minEstacionamientos: number = null;
  maxEstacionamientos: number = null;
  formConstruccion: UntypedFormGroup = null;
  formTerreno: UntypedFormGroup = null;
  formPrecio: UntypedFormGroup = null;
  currentConstruccion = new FormConstruccionTerrenoPrecio();
  currentTerreno = new FormConstruccionTerrenoPrecio();
  currentPrecio = new FormConstruccionTerrenoPrecio();
  timeOutConstruccion: NodeJS.Timeout = null;
  timeOutTerreno: NodeJS.Timeout = null;
  timeOutPrecio: NodeJS.Timeout = null;
  tiposInmuebles: BehaviorSubject<TipoInmueblesCB[]> = new BehaviorSubject([]);
  baseTiposInmuebles: TipoInmueblesCB[] = [];
  Amenidades: AmenidadesCB[] = [];
  minValuePrice = 0;
  maxValuePrice = 25000000 ;
  options: Options = {
    step: 1000,
    floor: 0,
    ceil: 25000000,
    translate: (value: number, label: LabelType): string => {
      const displayValue = value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      switch (label) {
        case LabelType.Low:
          return '<div class="custom-price-chip"><b>Precio min:</b>' + displayValue + '</div>';
        case LabelType.High:
          return '<div class="custom-price-chip"><b>Precio max:</b>' + displayValue + '</div>';
        default:
          return displayValue;
      }
    }
  };
  // tslint:disable-next-line: max-line-length
  constructor(private route: ActivatedRoute, private appStateS: AppStateService, private cd: ChangeDetectorRef, private router: Router, private fb: UntypedFormBuilder, private crud: RepositoryService) {
  }
  ngOnInit(): void {
    if (this.item.ftype === FilterType.Amenidades) {
      Utils.firstValueFrom(this.appStateS.Amenidades
        .pipe(
          skipWhile(data => data.length === 0)
        ).pipe(
          map(items => ((Utils.CloneObject(items) as any) as AmenidadesCB[])),
        ).pipe(
          map(items => {
            for (const e of items) {
              e.checked = false;
            }
            return items;
          }),
        ).pipe(
          take(1)
        ).pipe(tap(items => {
          this.Amenidades = items;
          this.updateMenuView();
        })));
    } else if (this.item.ftype === FilterType.TipoInmueble) {
      Utils.firstValueFrom(this.appStateS.TiposInmuebles
      .pipe(skipWhile(data => data.length === 0))
      .pipe(map(item => (Utils.CloneObject(item) as TipoInmueblesCB[])))).then(res => {
        for (const ti of res) {
          ti.checked = false;
        }
        this.baseTiposInmuebles = res;
        this.updateMenuView();
      });
    } else {
      setTimeout(() => {
        this.updateMenuView();
      }, 0);
    }
  }
  changeFocus(destinyId: string, event: any) {
    event.stopPropagation();
    const destiny = document.getElementById(destinyId);
    if (destiny) {
      destiny.focus();
    }
  }
  updateMenuView() {
    Utils.getSearchData(this.crud, this.route).then(search => {
      this.Search = search;
      const defaultFiltersData = new Search();
      switch (this.item.ftype) {
        case FilterType.TipoInmueble:
          if (this.Search?.TiposInmuebles?.length > 0) {
            for (const ti of this.baseTiposInmuebles) {
              if (this.Search.TiposInmuebles.some(id => id === ti.id)) {
                ti.checked = true;
              } else {
                ti.checked = false;
              }
            }
            this.tiposInmuebles.next(this.baseTiposInmuebles);
          }
          Utils.firstValueFrom(this.tiposInmuebles).then(res => {
            this.updateTipoInmuebleColor();
          });
          break;
        case FilterType.Habitaciones:
          if (this.Search.MaxRecamaras) {
            this.maxHabitaciones = this.Search.MaxRecamaras;
          }
          if (this.Search.MinRecamaras) {
            this.minHabitaciones = this.Search.MinRecamaras;
          }
          this.updateHabitacionesColor();
          break;
        case FilterType.Banos:
          if (this.Search.MaxBanos) {
            this.maxBanos = this.Search.MaxBanos;
          }
          if (this.Search.MinBanos) {
            this.minBanos = this.Search.MinBanos;
          }
          this.updateBanosColor();
          break;
        case FilterType.Estacionamiento:
          if (this.Search.MaxEstacionamientos) {
            this.maxEstacionamientos = this.Search.MaxEstacionamientos;
          }
          if (this.Search.MinEstacionamientos) {
            this.minEstacionamientos = this.Search.MinEstacionamientos;
          }
          this.updateEstacionamientosColor();
          break;
        case FilterType.Construccion:
          const formConstruccion = new FormConstruccionTerrenoPrecio();
          if (this.Search.MinConstruccion) {
            formConstruccion.min = this.Search.MinConstruccion;
          }
          if (this.Search.MaxConstruccion) {
            formConstruccion.max = this.Search.MaxConstruccion;
          }
          this.currentConstruccion = formConstruccion;
          this.formConstruccion = this.fb.group(formConstruccion);
          this.updateConstruccionColor();
          this.cd.detectChanges();
          break;
        case FilterType.Terreno:
          const formTerreno = new FormConstruccionTerrenoPrecio();
          if (this.Search.MinTerreno) {
            formTerreno.min = this.Search.MinTerreno;
          }
          if (this.Search.MaxTerreno) {
            formTerreno.max = this.Search.MaxTerreno;
          }
          this.currentTerreno = formTerreno;
          this.formTerreno = this.fb.group(formTerreno);
          this.updateTerrenoColor();
          this.cd.detectChanges();
          break;
        case FilterType.Amenidades:
          if (this.Search?.Amenidades?.length > 0) {
            for (const item of this.Amenidades) {
              item.checked = this.Search.Amenidades.some(id => id === item.id);
            }
          } else {
            for (const item of this.Amenidades) {
              item.checked = false;
            }
          }
          this.updateAmenidadesColor();
          break;
        case FilterType.Antiguedad:
          if (this.Search.MaxAntiguedad) {
            this.maxAntiguedad = this.Search.MaxAntiguedad;
          }
          if (this.Search.MinAntiguedad) {
            this.minAntiguedad = this.Search.MinAntiguedad;
          }
          this.updateAntiguedadColor();
          break;
        case FilterType.Precio:
          const formPrecio = new FormConstruccionTerrenoPrecio();
          if (this.Search.MinPrice) {
            formPrecio.min = this.Search.MinPrice;
          }
          if (this.Search.MaxPrice) {
            formPrecio.max = this.Search.MaxPrice;
          }
          this.currentPrecio = formPrecio;
          this.formPrecio = this.fb.group(formPrecio);
          this.minValuePrice = formPrecio.min;
          this.maxValuePrice = formPrecio.max;
          this.updatePrecioColor();
          this.cd.detectChanges();
          this.priceSlider.forEach(s => {
            this.options.maxLimit = defaultFiltersData.MaxPrice;
            s.options = this.options;
            s.manualRefresh = this.manualRefreshPriceSlider;
            setTimeout(() => {
              setTimeout(() => {
                this.manualRefreshPriceSlider.emit();
              }, 150);
            }, 0);
          });
          this.cd.detectChanges();
          break;
        default:
          break;
      }
    });
  }
  changeFormConstruccion(changedMin: boolean) {
    if (this.formConstruccion.controls.min.value === '' || !this.formConstruccion.controls.min.value) {
      this.formConstruccion.controls.min.setValue(null);
    } else {
      this.formConstruccion.controls.min.setValue(Number(this.formConstruccion.controls.min.value));
    }
    if (this.formConstruccion.controls.max.value === '' || !this.formConstruccion.controls.max.value) {
      this.formConstruccion.controls.max.setValue(null);
    } else {
      this.formConstruccion.controls.max.setValue(Number(this.formConstruccion.controls.max.value));
    }
    const formConstruccion: FormConstruccionTerrenoPrecio = this.formConstruccion.value;
    if (formConstruccion.max === this.currentConstruccion.max && formConstruccion.min === this.currentConstruccion.min) {
      return;
    }
    this.currentConstruccion = formConstruccion;
    if (this.timeOutConstruccion !== null) {
      clearTimeout(this.timeOutConstruccion);
      this.timeOutConstruccion = null;
    }
    this.timeOutConstruccion = setTimeout(() => {
      if (changedMin && this.currentConstruccion.min > this.currentConstruccion.max && this.currentConstruccion.max) {
        alertify.notify('¡Hey! tu valor de construcción mínimo es mayor al máximo, se ha cancelado tu máximo.', 'info', 5);
        this.currentConstruccion.max = null;
        this.formConstruccion.controls.max.setValue(null);
      } else if (!changedMin && this.currentConstruccion.min > this.currentConstruccion.max && this.currentConstruccion.max) {
        alertify.notify('¡Hey! tu valor de construcción máximo es menor al mínimo, se ha cancelado tu mínimo.', 'info', 5);
        this.currentConstruccion.min = null;
        this.formConstruccion.controls.min.setValue(null);
      } else if (changedMin && this.currentConstruccion.min === this.currentConstruccion.max && this.currentConstruccion.min) {
        alertify.notify('¡Hey! tu valor de construcción mínimo es igual al máximo, se ha cancelado tu máximo.', 'info', 5);
        this.currentConstruccion.max = null;
        this.formConstruccion.controls.max.setValue(null);
      } else if (!changedMin && this.currentConstruccion.min === this.currentConstruccion.max && this.currentConstruccion.min) {
        alertify.notify('¡Hey! tu valor de construcción máximo es igual al mínimo, se ha cancelado tu mínimo.', 'info', 5);
        this.currentConstruccion.min = null;
        this.formConstruccion.controls.min.setValue(null);
      }
      this.updateConstruccionColor();
      this.commit();
    }, 750);
  }
  updateConstruccionColor() {
    if (this.item.ftype === FilterType.Construccion) {
      if (this.currentConstruccion.min || this.currentConstruccion.max) {
        let name = '';
        if (this.currentConstruccion.min) {
          name = 'Desde ' + this.currentConstruccion.min.toString() + 'm<sup>2</sup>';
        }
        if (this.currentConstruccion.max) {
          if (name === '') {
            name = 'Hasta ' + this.currentConstruccion.max.toString() + 'm<sup>2</sup>';
          } else {
            name = name + ' hasta ' + this.currentConstruccion.max.toString() + 'm<sup>2</sup>';
          }
        }
        this.item.text = name;
        this.item.extraClasses = 'filterModified';
      } else {
        this.item.text = this.item.defaultText;
        this.item.extraClasses = '';
      }
    }
  }
  updateTerrenoColor() {
    if (this.item.ftype === FilterType.Terreno) {
      if (this.currentTerreno.min || this.currentTerreno.max) {
        let name = '';
        if (this.currentTerreno.min) {
          name = 'Desde ' + this.currentTerreno.min.toString() + 'm<sup>2</sup>';
        }
        if (this.currentTerreno.max) {
          if (name === '') {
            name = 'Hasta ' + this.currentTerreno.max.toString() + 'm<sup>2</sup>';
          } else {
            name = name + ' hasta ' + this.currentTerreno.max.toString() + 'm<sup>2</sup>';
          }
        }
        this.item.text = name;
        this.item.extraClasses = 'filterModified';
      } else {
        this.item.text = this.item.defaultText;
        this.item.extraClasses = '';
      }
    }
  }
  changeFormTerreno(changedMin: boolean) {
    if (this.formTerreno.controls.min.value === '' || !this.formTerreno.controls.min.value) {
      this.formTerreno.controls.min.setValue(null);
    } else {
      this.formTerreno.controls.min.setValue(Number(this.formTerreno.controls.min.value));
    }
    if (this.formTerreno.controls.max.value === '' || !this.formTerreno.controls.max.value) {
      this.formTerreno.controls.max.setValue(null);
    } else {
      this.formTerreno.controls.max.setValue(Number(this.formTerreno.controls.max.value));
    }
    const formTerreno: FormConstruccionTerrenoPrecio = this.formTerreno.value;
    if (formTerreno.max === this.currentTerreno.max && formTerreno.min === this.currentTerreno.min) {
      return;
    }
    this.currentTerreno = formTerreno;
    if (this.timeOutTerreno !== null) {
      clearTimeout(this.timeOutTerreno);
      this.timeOutTerreno = null;
    }
    this.timeOutTerreno = setTimeout(() => {
      if (changedMin && this.currentTerreno.min > this.currentTerreno.max && this.currentTerreno.max) {
        alertify.notify('¡Hey! tu valor de terreno mínimo es mayor al máximo, se ha cancelado tu máximo.', 'info', 5);
        this.currentTerreno.max = null;
        this.formTerreno.controls.max.setValue(null);
      } else if (!changedMin && this.currentTerreno.min > this.currentTerreno.max && this.currentTerreno.max) {
        alertify.notify('¡Hey! tu valor de terreno máximo es menor al mínimo, se ha cancelado tu mínimo.', 'info', 5);
        this.currentTerreno.min = null;
        this.formTerreno.controls.min.setValue(null);
      } else if (changedMin && this.currentTerreno.min === this.currentTerreno.max && this.currentTerreno.min) {
        alertify.notify('¡Hey! tu valor de terreno mínimo es igual al máximo, se ha cancelado tu máximo.', 'info', 5);
        this.currentTerreno.max = null;
        this.formTerreno.controls.max.setValue(null);
      } else if (!changedMin && this.currentTerreno.min === this.currentTerreno.max && this.currentTerreno.min) {
        alertify.notify('¡Hey! tu valor de terreno máximo es igual al mínimo, se ha cancelado tu mínimo.', 'info', 5);
        this.currentTerreno.min = null;
        this.formTerreno.controls.min.setValue(null);
      }
      this.updateTerrenoColor();
      this.commit();
    }, 750);
  }
  updatePrecioColor() {
    if (this.item.ftype === FilterType.Precio) {
      const defaultFiltersData = new Search();
      const currentPrecioMax = this.currentPrecio.max !== defaultFiltersData.MaxPrice ? this.currentPrecio.max : null;
      if (this.currentPrecio.min || currentPrecioMax) {
        let name = '';
        if (this.currentPrecio.min) {
          name = 'Desde ' + this.currentPrecio.min.toString() + 'm<sup>2</sup>';
        }
        if (currentPrecioMax) {
          if (name === '') {
            name = 'Hasta ' + currentPrecioMax.toString() + 'm<sup>2</sup>';
          } else {
            name = name + ' hasta ' + currentPrecioMax.toString() + 'm<sup>2</sup>';
          }
        }
        this.item.text = name;
        this.item.extraClasses = 'filterModified';
      } else {
        this.item.text = this.item.defaultText;
        this.item.extraClasses = '';
      }
    }
  }
  onChangePrice(range: ChangeContext) {
    this.formPrecio.controls.min.setValue(range.value);
    this.formPrecio.controls.max.setValue(range.highValue);
    this.changeFormPrecio(false, false);
  }
  changeFormPrecio(changedMin: boolean, updateSlider = true) {
    if (this.formPrecio.controls.min.value === '' || !this.formPrecio.controls.min.value) {
      this.formPrecio.controls.min.setValue(null);
    } else {
      this.formPrecio.controls.min.setValue(Number(this.formPrecio.controls.min.value));
    }
    if (this.formPrecio.controls.max.value === '' || !this.formPrecio.controls.max.value) {
      this.formPrecio.controls.max.setValue(null);
    } else {
      this.formPrecio.controls.max.setValue(Number(this.formPrecio.controls.max.value));
    }
    const formPrecio: FormConstruccionTerrenoPrecio = this.formPrecio.value;
    if (formPrecio.max === this.currentPrecio.max && formPrecio.min === this.currentPrecio.min) {
      return;
    }
    this.currentPrecio = formPrecio;
    if (this.timeOutPrecio !== null) {
      clearTimeout(this.timeOutPrecio);
      this.timeOutPrecio = null;
    }
    this.timeOutPrecio = setTimeout(() => {
      if (changedMin && this.currentPrecio.min > this.currentPrecio.max && this.currentPrecio.max) {
        alertify.notify('¡Hey! tu valor de precio mínimo es mayor al máximo, se ha cancelado tu máximo.', 'info', 5);
        this.currentPrecio.max = null;
        this.formPrecio.controls.max.setValue(null);
      } else if (!changedMin && this.currentPrecio.min > this.currentPrecio.max && this.currentPrecio.max) {
        alertify.notify('¡Hey! tu valor de precio máximo es menor al mínimo, se ha cancelado tu mínimo.', 'info', 5);
        this.currentPrecio.min = null;
        this.formPrecio.controls.min.setValue(null);
      } else if (changedMin && this.currentPrecio.min === this.currentPrecio.max && this.currentPrecio.min) {
        alertify.notify('¡Hey! tu valor de precio mínimo es igual al máximo, se ha cancelado tu máximo.', 'info', 5);
        this.currentPrecio.max = null;
        this.formPrecio.controls.max.setValue(null);
      } else if (!changedMin && this.currentPrecio.min === this.currentPrecio.max && this.currentPrecio.min) {
        alertify.notify('¡Hey! tu valor de precio máximo es igual al mínimo, se ha cancelado tu mínimo.', 'info', 5);
        this.currentPrecio.min = null;
        this.formPrecio.controls.min.setValue(null);
      }
      if (updateSlider) {
        this.minValuePrice = this.currentPrecio.min;
        this.maxValuePrice = this.currentPrecio.max;
      }
      this.updatePrecioColor();
      this.commit();
    }, 750);
  }
  habitacionesChanged(current: CustomCbComponent) {
    if (current.checked) {
      if (current.value === this.maxHabitaciones && current.value === this.minHabitaciones) {
        this.maxHabitaciones = null;
        this.minHabitaciones = null;
      } else if (current.value === this.maxHabitaciones) {
        this.maxHabitaciones = null;
      } else if (current.value === this.minHabitaciones) {
        this.minHabitaciones = null;
      } else {
        this.maxHabitaciones = current.value;
        this.minHabitaciones = current.value;
      }
    } else {
      if (!this.maxHabitaciones || current.value > this.maxHabitaciones) {
        this.maxHabitaciones = current.value;
      }
      if (!this.minHabitaciones || current.value < this.minHabitaciones) {
        this.minHabitaciones = current.value;
      }
    }
    this.updateHabitacionesColor();
    this.commit();
  }
  updateHabitacionesColor() {
    this.cbComponents.filter(e => e.type === FilterType.Habitaciones).forEach(e => {
      if (this.maxHabitaciones || this.minHabitaciones) {
        if (this.maxHabitaciones && this.minHabitaciones) {
          if (e.value >= this.minHabitaciones && e.value <= this.maxHabitaciones) {
            e.checked = true;
          } else {
            e.checked = false;
          }
        } else if (this.maxHabitaciones) {
          if (e.value !== this.maxHabitaciones) {
            e.checked = false;
          } else {
            e.checked = true;
          }
        } else if (this.minHabitaciones) {
          if (e.value !== this.minHabitaciones) {
            e.checked = false;
          } else {
            e.checked = true;
          }
        } else {
          e.checked = false;
        }
      } else {
        e.checked = false;
      }
    });
    if (this.item.ftype === FilterType.Habitaciones) {
      if (this.minHabitaciones && this.maxHabitaciones && this.minHabitaciones !== this.maxHabitaciones) {
        this.item.text = this.minHabitaciones.toString() + '-' + this.maxHabitaciones.toString() + ' Habitaciones';
        this.item.extraClasses = 'filterModified';
      } else if (this.minHabitaciones) {
        this.item.text = this.minHabitaciones.toString() + ' Habitaciones';
        this.item.extraClasses = 'filterModified';
      } else if (this.maxHabitaciones) {
        this.item.text = this.maxHabitaciones.toString() + ' Habitaciones';
        this.item.extraClasses = 'filterModified';
      } else {
        this.item.text = this.item.defaultText;
        this.item.extraClasses = '';
      }
    }
  }
  AntiguedadChanged(current: CustomCbComponent) {
    if (current.checked) {
      if (current.value === this.maxAntiguedad && current.value === this.minAntiguedad) {
        this.maxAntiguedad = null;
        this.minAntiguedad = null;
      } else if (current.value === this.maxAntiguedad) {
        this.maxAntiguedad = null;
      } else if (current.value === this.minAntiguedad) {
        this.minAntiguedad = null;
      } else {
        this.maxAntiguedad = current.value;
        this.minAntiguedad = current.value;
      }
    } else {
      if (!this.maxAntiguedad || current.value > this.maxAntiguedad) {
        this.maxAntiguedad = current.value;
      }
      if (!this.minAntiguedad || current.value < this.minAntiguedad) {
        this.minAntiguedad = current.value;
      }
    }
    this.updateAntiguedadColor();
    this.commit();
  }
  updateAntiguedadColor() {
    this.cbComponents.filter(e => e.type === FilterType.Antiguedad).forEach(e => {
      if (this.maxAntiguedad || this.minAntiguedad) {
        if (this.maxAntiguedad && this.minAntiguedad) {
          if (e.value >= this.minAntiguedad && e.value <= this.maxAntiguedad) {
            e.checked = true;
          } else {
            e.checked = false;
          }
        } else if (this.maxAntiguedad) {
          if (e.value !== this.maxAntiguedad) {
            e.checked = false;
          } else {
            e.checked = true;
          }
        } else if (this.minAntiguedad) {
          if (e.value !== this.minAntiguedad) {
            e.checked = false;
          } else {
            e.checked = true;
          }
        } else {
          e.checked = false;
        }
      } else {
        e.checked = false;
      }
    });
    if (this.item.ftype === FilterType.Antiguedad) {
      if (this.minAntiguedad && this.maxAntiguedad && this.minAntiguedad !== this.maxAntiguedad) {
        // tslint:disable-next-line: max-line-length
        const minStr = this.minAntiguedad === 5 ? 'Menor de 5 años' : this.minAntiguedad === 99 ? 'Mayor de 30 años' : this.minAntiguedad.toString() + ' años';
        // tslint:disable-next-line: max-line-length
        const maxStr = this.maxAntiguedad === 5 ? 'Menor de 5 años' : this.maxAntiguedad === 99 ? 'Mayor de 30 años' : this.maxAntiguedad.toString() + ' años';
        this.item.text = minStr + '-' + maxStr;
        this.item.extraClasses = 'filterModified';
      } else if (this.minAntiguedad) {
        // tslint:disable-next-line: max-line-length
        const minStr = this.minAntiguedad === 5 ? 'Menor de 5 años' : this.minAntiguedad === 99 ? 'Mayor de 30 años' : this.minAntiguedad.toString() + ' años';
        this.item.text = minStr;
        this.item.extraClasses = 'filterModified';
      } else if (this.maxAntiguedad) {
        // tslint:disable-next-line: max-line-length
        const maxStr = this.maxAntiguedad === 5 ? 'Menor de 5 años' : this.maxAntiguedad === 99 ? 'Mayor de 30 años' : this.maxAntiguedad.toString() + ' años';
        this.item.text = maxStr;
        this.item.extraClasses = 'filterModified';
      } else {
        this.item.text = this.item.defaultText;
        this.item.extraClasses = '';
      }
    }
  }
  BanosChanged(current: CustomCbComponent) {
    if (current.checked) {
      if (current.value === this.maxBanos && current.value === this.minBanos) {
        this.maxBanos = null;
        this.minBanos = null;
      } else if (current.value === this.maxBanos) {
        this.maxBanos = null;
      } else if (current.value === this.minBanos) {
        this.minBanos = null;
      } else {
        this.maxBanos = current.value;
        this.minBanos = current.value;
      }
    } else {
      if (!this.maxBanos || current.value > this.maxBanos) {
        this.maxBanos = current.value;
      }
      if (!this.minBanos || current.value < this.minBanos) {
        this.minBanos = current.value;
      }
    }
    this.updateBanosColor();
    this.commit();
  }
  updateBanosColor() {
    this.cbComponents.filter(e => e.type === FilterType.Banos).forEach(e => {
      if (this.maxBanos || this.minBanos) {
        if (this.maxBanos && this.minBanos) {
          if (e.value >= this.minBanos && e.value <= this.maxBanos) {
            e.checked = true;
          } else {
            e.checked = false;
          }
        } else if (this.maxBanos) {
          if (e.value !== this.maxBanos) {
            e.checked = false;
          } else {
            e.checked = true;
          }
        } else if (this.minBanos) {
          if (e.value !== this.minBanos) {
            e.checked = false;
          } else {
            e.checked = true;
          }
        } else {
          e.checked = false;
        }
      } else {
        e.checked = false;
      }
    });
    if (this.item.ftype === FilterType.Banos) {
      if (this.minBanos && this.maxBanos && this.minBanos !== this.maxBanos) {
        this.item.text = this.minBanos.toString() + '-' + this.maxBanos.toString() + ' Banos';
        this.item.extraClasses = 'filterModified';
      } else if (this.minBanos) {
        this.item.text = this.minBanos.toString() + ' Banos';
        this.item.extraClasses = 'filterModified';
      } else if (this.maxBanos) {
        this.item.text = this.maxBanos.toString() + ' Banos';
        this.item.extraClasses = 'filterModified';
      } else {
        this.item.text = this.item.defaultText;
        this.item.extraClasses = '';
      }
    }
  }
  EstacionamientosChanged(current: CustomCbComponent) {
    if (current.checked) {
      if (current.value === this.maxEstacionamientos && current.value === this.minEstacionamientos) {
        this.maxEstacionamientos = null;
        this.minEstacionamientos = null;
      } else if (current.value === this.maxEstacionamientos) {
        this.maxEstacionamientos = null;
      } else if (current.value === this.minEstacionamientos) {
        this.minEstacionamientos = null;
      } else {
        this.maxEstacionamientos = current.value;
        this.minEstacionamientos = current.value;
      }
    } else {
      if (!this.maxEstacionamientos || current.value > this.maxEstacionamientos) {
        this.maxEstacionamientos = current.value;
      }
      if (!this.minEstacionamientos || current.value < this.minEstacionamientos) {
        this.minEstacionamientos = current.value;
      }
    }
    this.updateEstacionamientosColor();
    this.commit();
  }
  updateEstacionamientosColor() {
    this.cbComponents.filter(e => e.type === FilterType.Estacionamiento).forEach(e => {
      if (this.maxEstacionamientos || this.minEstacionamientos) {
        if (this.maxEstacionamientos && this.minEstacionamientos) {
          if (e.value >= this.minEstacionamientos && e.value <= this.maxEstacionamientos) {
            e.checked = true;
          } else {
            e.checked = false;
          }
        } else if (this.maxEstacionamientos) {
          if (e.value !== this.maxEstacionamientos) {
            e.checked = false;
          } else {
            e.checked = true;
          }
        } else if (this.minEstacionamientos) {
          if (e.value !== this.minEstacionamientos) {
            e.checked = false;
          } else {
            e.checked = true;
          }
        } else {
          e.checked = false;
        }
      } else {
        e.checked = false;
      }
    });
    if (this.item.ftype === FilterType.Estacionamiento) {
      if (this.minEstacionamientos && this.maxEstacionamientos && this.minEstacionamientos !== this.maxEstacionamientos) {
        this.item.text = this.minEstacionamientos.toString() + '-' + this.maxEstacionamientos.toString() + ' Estacionamientos';
        this.item.extraClasses = 'filterModified';
      } else if (this.minEstacionamientos) {
        this.item.text = this.minEstacionamientos.toString() + ' Estacionamientos';
        this.item.extraClasses = 'filterModified';
      } else if (this.maxEstacionamientos) {
        this.item.text = this.maxEstacionamientos.toString() + ' Estacionamientos';
        this.item.extraClasses = 'filterModified';
      } else {
        this.item.text = this.item.defaultText;
        this.item.extraClasses = '';
      }
    }
  }
  checkBoxClick(event: PointerEvent, item: TipoInmueblesCB) {
    const clear = item.id === -1 && !item.checked;
    if (item.id === -1 && item.checked) {
      return;
    }
    const elementref = this.baseTiposInmuebles.find(e => e.id === item.id);
    elementref.checked = !item.checked;
    if (clear) {
      for (const e of this.baseTiposInmuebles) {
        if (e.id !== -1) {
          e.checked = false;
        }
      }
    } else if (!this.baseTiposInmuebles.some(e => e.checked)) {
      this.baseTiposInmuebles.find(e => e.id === -1).checked = true;
    } else {
      this.baseTiposInmuebles.find(e => e.id === -1).checked = false;
    }
    setTimeout(() => {
      this.commit();
    }, 0);
  }
  async updateTipoInmuebleColor() {
    if (this.item.ftype === FilterType.TipoInmueble) {
      const tiposInmuebles = (await Utils.firstValueFrom(this.tiposInmuebles)).filter(ti => ti.checked);
      if (tiposInmuebles && tiposInmuebles.length > 0) {
        if (tiposInmuebles.length === 1) {
          if (tiposInmuebles[0].id === -1) {
            this.item.text = this.item.defaultText;
            this.item.extraClasses = '';
          } else {
            this.item.text = tiposInmuebles[0].nombre;
            this.item.extraClasses = 'filterModified';
          }
        } else {
          this.item.text = tiposInmuebles[0].nombre + '...+' + (tiposInmuebles.length - 1).toString();
          this.item.extraClasses = 'filterModified';
        }
      } else {
        this.item.text = this.item.defaultText;
        this.item.extraClasses = '';
      }
    }
    this.cd.detectChanges();
  }
  changeAmenidades(event: PointerEvent, item: AmenidadesCB) {
    setTimeout(() => {
      const elementref = this.Amenidades.find(e => e.id === item.id);
      elementref.checked = !elementref.checked;
      this.updateAmenidadesColor();
      this.commit();
    }, 0);
  }
  async updateAmenidadesColor() {
    if (this.item.ftype === FilterType.Amenidades) {
      const Amenidades = this.Amenidades.filter(ti => ti.checked);
      if (Amenidades && Amenidades.length > 0) {
        if (Amenidades.length === 1) {
          if (Amenidades[0].id === -1) {
            this.item.text = this.item.defaultText;
            this.item.extraClasses = '';
          } else {
            this.item.text = Amenidades[0].name;
            this.item.extraClasses = 'filterModified';
          }
        } else {
          this.item.text = Amenidades[0].name + '...+' + (Amenidades.length - 1).toString();
          this.item.extraClasses = 'filterModified';
        }
      } else {
        this.item.text = this.item.defaultText;
        this.item.extraClasses = '';
      }
    }
  }
  commit() {
    Utils.getSearchData(this.crud, this.route).then(async search => {
      this.Search = search;
      switch (this.item.ftype) {
        case FilterType.TipoInmueble:
          this.Search.TiposInmuebles = (this.baseTiposInmuebles).filter(ti => ti.checked).map(e => e.id);
          break;
        case FilterType.Habitaciones:
          this.Search.MaxRecamaras = this.maxHabitaciones;
          this.Search.MinRecamaras = this.minHabitaciones;
          break;
        case FilterType.Banos:
          this.Search.MaxBanos = this.maxBanos;
          this.Search.MinBanos = this.minBanos;
          break;
        case FilterType.Estacionamiento:
          this.Search.MaxEstacionamientos = this.maxEstacionamientos;
          this.Search.MinEstacionamientos = this.minEstacionamientos;
          break;
        case FilterType.Construccion:
          const formConstruccion: FormConstruccionTerrenoPrecio = this.formConstruccion.value;
          this.Search.MinConstruccion = formConstruccion.min;
          this.Search.MaxConstruccion = formConstruccion.max;
          break;
        case FilterType.Terreno:
          const formTerreno: FormConstruccionTerrenoPrecio = this.formTerreno.value;
          this.Search.MinTerreno = formTerreno.min;
          this.Search.MaxTerreno = formTerreno.max;
          break;
        case FilterType.Amenidades:
          this.Search.Amenidades = this.Amenidades.filter(ti => ti.checked).map(e => e.id);
          break;
        case FilterType.Antiguedad:
          this.Search.MaxAntiguedad = this.maxAntiguedad;
          this.Search.MinAntiguedad = this.minAntiguedad;
          break;
        case FilterType.Precio:
          const formPrecio: FormConstruccionTerrenoPrecio = this.formPrecio.value;
          this.Search.MinPrice = formPrecio.min;
          this.Search.MaxPrice = formPrecio.max;
          break;
        default:
          break;
      }
      this.router.navigateByUrl('/Search' + Utils.encodeObject(this.Search));
      setTimeout(() => {
        this.updateMenuView();
      }, 0);
    });
  }
}
