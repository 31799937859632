import { Observable } from 'rxjs';
import { RepositoryService } from './../services/Repository/Repository.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Usuario } from '../models/Usuario';
import { AppStateModel } from '../services/Store/Reducer';
import { Search } from '../models/Search';
import { House } from '../models/House';
import { ActivatedRoute } from '@angular/router';
import { LatLng } from '../models/LatLng';
import { GeoResponseData, IPResponseData } from '../models/IPResposeData';
export class Utils {
  static emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
  static datoRequerido = 'Este dato es requerido.';
  static formatoIncorrecto = 'El formato es incorrecto.';
  static FormatoMailIncorrecto = 'El formato del correo es incorrecto.';
  static ejemploMail = 'abc@abc.abc';
  static minLength = 'Se requieren al menos {0} caracteres';
  static maxLength = 'Se requieren máximo {0} caracteres';
  static getSublocalityByLatLng(data: LatLng): Promise<google.maps.GeocoderResult> {
    return new Promise((resolve, _) => {
      const latlng = new google.maps.LatLng(data.Lat, data.Lng);
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({location: latlng}, (res: google.maps.GeocoderResult[]) => {
        let sublocality: google.maps.GeocoderResult = null;
        for (const l of res) {
          if (l.types.some(t => t === 'postal_code')) {
            sublocality = l;
            break;
          }
        }
        if (!sublocality) {
          sublocality = res[0];
        }
        resolve(sublocality);
      });
    });
  }
  static getSearchData(crud: RepositoryService, route: ActivatedRoute): Promise<Search> {
    return new Promise((resolve, reject) => {
      const split = location.hash.split('?').join('/').split('/');
      if (split[1] === 'Search' && split.length === 3 && split[2] !== '') {
        resolve(Utils.decodeSearch(route.snapshot.queryParams));
      } else {
        const geocoder = new google.maps.Geocoder();
        navigator.geolocation.getCurrentPosition((loca) => {
          if (loca.coords) {
            // Once you get the LatLnt
            const latlng = new google.maps.LatLng(loca.coords.latitude, loca.coords.longitude);
            geocoder.geocode({location: latlng}, (res: google.maps.GeocoderResult[]) => {
                let locality: google.maps.GeocoderResult = null;
                for (const l of res) {
                  if (l.types.some(t => t === 'locality')) {
                    locality = l;
                    break;
                  }
                }
                if (!locality) {
                  locality = res[0];
                }
                const bounds = locality?.geometry?.bounds ? locality.geometry.bounds : new google.maps.LatLngBounds(
                  locality.geometry.viewport.getSouthWest(),
                  locality.geometry.viewport.getNorthEast()
                );
                const LatLngBL: LatLng = {
                  Lat: bounds.getSouthWest().lat(),
                  Lng: bounds.getSouthWest().lng()
                };
                const LatLngTR: LatLng = {
                  Lat: bounds.getNorthEast().lat(),
                  Lng: bounds.getNorthEast().lng()
                };
                const search: Search = new Search();
                search.LatBL = LatLngBL.Lat;
                search.LngBL = LatLngBL.Lng;
                search.LatTR = LatLngTR.Lat;
                search.LngTR = LatLngTR.Lng;
                search.SearchStr = '';
                search.OptionsSelectBuyLease = 1;
                search.placeId = null;
                resolve(search);
            });
            // End Once you get the LatLnt
          } else {
            Utils.firstValueFrom(
              crud.GetPetition('https://api.freegeoip.app/json/?apikey=a02e16c0-b487-11ec-945c-f33ff2e66370', true, false)
            ).then((ipData: GeoResponseData) => {
              // Once you get the LatLnt
              const latlng = new google.maps.LatLng(ipData.latitude, ipData.longitude);
              geocoder.geocode({location: latlng}, (res: google.maps.GeocoderResult[]) => {
                  let locality: google.maps.GeocoderResult = null;
                  for (const l of res) {
                    if (l.types.some(t => t === 'locality')) {
                      locality = l;
                      break;
                    }
                  }
                  if (!locality) {
                    locality = res[0];
                  }
                  const bounds = locality?.geometry?.bounds ? locality.geometry.bounds : new google.maps.LatLngBounds(
                    locality.geometry.viewport.getSouthWest(),
                    locality.geometry.viewport.getNorthEast()
                  );
                  const LatLngBL: LatLng = {
                    Lat: bounds.getSouthWest().lat(),
                    Lng: bounds.getSouthWest().lng()
                  };
                  const LatLngTR: LatLng = {
                    Lat: bounds.getNorthEast().lat(),
                    Lng: bounds.getNorthEast().lng()
                  };
                  const search: Search = new Search();
                  search.LatBL = LatLngBL.Lat;
                  search.LngBL = LatLngBL.Lng;
                  search.LatTR = LatLngTR.Lat;
                  search.LngTR = LatLngTR.Lng;
                  search.SearchStr = '';
                  search.OptionsSelectBuyLease = 1;
                  search.placeId = null;
                  resolve(search);
              });
              // End Once you get the LatLnt
            }).catch(_ => {
              resolve(null);
            });
          }
        }, (_) => {
          Utils.firstValueFrom(crud.GetPetition('https://api.freegeoip.app/json/?apikey=a02e16c0-b487-11ec-945c-f33ff2e66370', true, false))
          .then((ipData: GeoResponseData) => {
            // Once you get the LatLnt
            const latlng = new google.maps.LatLng(ipData.latitude, ipData.longitude);
            geocoder.geocode({location: latlng}, (res: google.maps.GeocoderResult[]) => {
                let locality: google.maps.GeocoderResult = null;
                for (const l of res) {
                  if (l.types.some(t => t === 'locality')) {
                    locality = l;
                    break;
                  }
                }
                if (!locality) {
                  locality = res[0];
                }
                const bounds = locality?.geometry?.bounds ? locality.geometry.bounds : new google.maps.LatLngBounds(
                  locality.geometry.viewport.getSouthWest(),
                  locality.geometry.viewport.getNorthEast()
                );
                const LatLngBL: LatLng = {
                  Lat: bounds.getSouthWest().lat(),
                  Lng: bounds.getSouthWest().lng()
                };
                const LatLngTR: LatLng = {
                  Lat: bounds.getNorthEast().lat(),
                  Lng: bounds.getNorthEast().lng()
                };
                const search: Search = new Search();
                search.LatBL = LatLngBL.Lat;
                search.LngBL = LatLngBL.Lng;
                search.LatTR = LatLngTR.Lat;
                search.LngTR = LatLngTR.Lng;
                search.SearchStr = '';
                search.OptionsSelectBuyLease = 1;
                search.placeId = null;
                resolve(search);
            });
          // End Once you get the LatLnt
          }).catch(_ => {
            resolve(null);
          });
        });
      }
    });
  }
  static ProcessHouse(house: House): House {
    house = this.CloneObject(house);
    house.TituloUrl = house.titulo.split(' ').join('_');
    house.destacado = false;
    house.estacionamientos = house?.estacionamientos ? house.estacionamientos : 0;
    house.imagenesToShow = house.imagenes_set.map(img => img.thumbnail);
    house.Opened = false;
    house.is_liked = house.is_liked === true || house.is_liked === 'true'; // Descartando "Not authenticated."
    let shortTitle = '';
    if (house?.titulo) {
      shortTitle = house?.titulo?.length > 23 ? house?.titulo.substring(0, 20) + '...' : house?.titulo;
    }
    house.ShortTitle = shortTitle;
    let ShortDir = '';
    if (house?.direccion) {
      ShortDir = house?.direccion?.length > 35 ? house?.direccion.substring(0, 32) + '...' : house?.direccion;
    }
    house.ShortDir = ShortDir;
    let ShortDesc = '';
    if (house?.descripcion) {
      ShortDesc = house?.descripcion?.length > 130 ? house?.descripcion.substring(0, 127) + '...' : house?.descripcion;
    }
    house.ShortDesc = ShortDesc;
    return house
  }
  static CloneObject<T>(objeto: T): T { return JSON.parse(JSON.stringify(objeto)) as T; }
  static isSearchURL(URL: string | null = null): boolean {
    if (!URL) {
      URL = location.hash;
    }
    const splited = URL.split('?').join('/').split('/');
    return splited.length >= 2 && splited[1] === 'Search';
  }
  static getDb(model) {
    return model ? model as AppStateModel : null;
  }
  static getUser(crud: RepositoryService): Promise<Usuario | null> {
    return new Promise((resolve, reject) => {
      Utils.firstValueFrom(crud.GetPetition('user/me')).then((res: Usuario) => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }
  static IsLogged(user: Usuario): boolean {
    return user && Object.keys(user).length > 0;
  }
  static setPath(url: string, domSanitizer: DomSanitizer): SafeResourceUrl {
    return domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
  // Retorna un entero aleatorio entre min (incluido) y max (incluido)
  static getRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max + 1 - min)) + min;
  }
  static getSearchStr(search: Search): string {
    let cad = '';
    const defaultFiltersData = new Search();
    if (!search) {
      search = new Search();
    }
    if (search.TiposInmuebles.length > 0 && search.TiposInmuebles[0] !== -1) {
      cad += '&tipo_propiedad=';
      for (const item of search.TiposInmuebles) {
        cad +=  + item.toString() + ',';
      }
      cad = cad.substring(0, cad.length - 1);
      // cad += ']';
    }
    if (search.Amenidades.length > 0 && search.Amenidades[0] !== -1) {
      cad += '&amenidades=';
      for (const item of search.Amenidades) {
        cad +=  + item.toString() + ',';
      }
      cad = cad.substring(0, cad.length - 1);
      // cad += ']';
    }
    if (search.MinPrice !== null && search.MinPrice !== undefined && search.MinPrice !== defaultFiltersData.MinPrice) {
      cad += '&precio_min=' + search.MinPrice;
    }
    if (search.MaxPrice !== null && search.MaxPrice !== undefined && search.MaxPrice !== defaultFiltersData.MaxPrice) {
      cad += '&precio_max=' + search.MaxPrice;
    }
    // cad += '&acepta_credito=' + (search.AceptaCredito ? '1' : '0');
    // cad += '&dentro_de_privada=' + (search.DentroPrivada ? '1' : '0');
    if (search.MaxBanos !== null && search.MaxBanos !== undefined && search.MaxBanos !== defaultFiltersData.MaxBanos) {
      cad += '&banos_max=' + search.MaxBanos;
    }
    if (search.MinBanos !== null && search.MinBanos !== undefined && search.MinBanos !== defaultFiltersData.MinBanos) {
      cad += '&banos_min=' + search.MinBanos;
    }
    // tslint:disable-next-line: max-line-length
    if (search.MaxRecamaras !== null && search.MaxRecamaras !== undefined && search.MaxRecamaras !== defaultFiltersData.MaxRecamaras) {
      cad += '&recamaras_max=' + search.MaxRecamaras;
    }
    // tslint:disable-next-line: max-line-length
    if (search.MinRecamaras !== null && search.MinRecamaras !== undefined && search.MinRecamaras !== defaultFiltersData.MinRecamaras) {
      cad += '&recamaras_min=' + search.MinRecamaras;
    }
    // tslint:disable-next-line: max-line-length
    if (search.MaxEstacionamientos !== null && search.MaxEstacionamientos !== undefined && search.MaxEstacionamientos !== defaultFiltersData.MaxEstacionamientos) {
      cad += '&estacionamientos_max=' + search.MaxEstacionamientos;
    }
    // tslint:disable-next-line: max-line-length
    if (search.MinEstacionamientos !== null && search.MinEstacionamientos !== undefined && search.MinEstacionamientos !== defaultFiltersData.MinEstacionamientos) {
      cad += '&estacionamientos_min=' + search.MinEstacionamientos;
    }
    // tslint:disable-next-line: max-line-length
    if (search.MaxAntiguedad !== null && search.MaxAntiguedad !== undefined && search.MaxAntiguedad !== defaultFiltersData.MaxAntiguedad) {
      cad += '&antiguedad_max=' + search.MaxAntiguedad;
    }
    // tslint:disable-next-line: max-line-length
    if (search.MinAntiguedad !== null && search.MinAntiguedad !== undefined && search.MinAntiguedad !== defaultFiltersData.MinAntiguedad) {
      cad += '&antiguedad_min=' + search.MinAntiguedad;
    }
    if (search.MaxTerreno !== null && search.MaxTerreno !== undefined && search.MaxTerreno !== defaultFiltersData.MaxTerreno) {
      cad += '&m_2_max=' + search.MaxTerreno;
    }
    if (search.MinTerreno !== null && search.MinTerreno !== undefined && search.MinTerreno !== defaultFiltersData.MinTerreno) {
      cad += '&m_2_min=' + search.MinTerreno;
    }
    // tslint:disable-next-line: max-line-length
    if (search.MaxConstruccion !== null && search.MaxConstruccion !== undefined && search.MaxConstruccion !== defaultFiltersData.MaxConstruccion) {
      cad += '&m_2_construccion_max=' + search.MaxConstruccion;
    }
    // tslint:disable-next-line: max-line-length
    if (search.MinConstruccion !== null && search.MinConstruccion !== undefined && search.MinConstruccion !== defaultFiltersData.MinConstruccion) {
      cad += '&m_2_construccion_min=' + search.MinConstruccion;
    }
    return cad;
  }
  // tslint:disable-next-line: no-shadowed-variable
  static numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  static encodeObject(obj: any): string {
    let res = '?';
    if (Object.keys(obj)) {
      for (const key of Object.keys(obj)) {
        if (obj[key]) {
          if (res !== '?') {
            res += '&';
          }
          res += key + '=' + JSON.stringify(obj[key]);
        }
      }
    }
    if (res === '?') {
      res = '';
    }
    return res;
  }
  static decodeSearch(src: any): Search {
    const res: Search = new Search();
    for (const key of Object.keys(res)) {
      res[key] = src[key] ? JSON.parse(src[key]) : null;
    }
    return res;
  }
  static firstValueFrom<T>(obs: Observable<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      const subs = obs.subscribe(res => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
