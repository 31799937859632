export enum SortType {
    Distancia = 1,
    MayorPrecio = 2,
    MenorPrecio = 3,
}
export enum ShareSource {
    Undefined = 0,
    ShareButton = 1,
    Instagram = 2,
    Facebook = 3,
    PadelPlace = 4,
    PublicidadPapel = 5,
    PublicidadWeb = 6,
}
