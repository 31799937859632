<div class="contfather blackBG" [style.border-radius]="BorderRadious"
[style.width]="Width" [style.height]="Height"
[style.min-width]="MinWidth" [style.min-height]="MinHeight"
[style.max-width]="MaxWidth" [style.max-height]="MaxHeight" *ngIf="currentIndex[0] != -1">
  <div class="lbuttoncarrcont" *ngIf="lbuttonVisible()">
    <button mat-mini-fab class="carrbtn" (click)="lbuttonClick()"><mat-icon>chevron_left</mat-icon></button>
  </div>
  <ion-slides class="drawAndDrop" #sliderCarr (ionSlideDidChange)="changed()" [pager]="Pager" >
    <ion-slide *ngFor="let url of Images; let i=index">
      <img [src]="url" [ngClass]="{forceContentCarr: ForceWH}" class="carrImg" [style.height]="'100%'"/>
    </ion-slide>
  </ion-slides>
  <div class="indexNumber" *ngIf="ShowIndex" [ngClass]="{hideIfSmall: !ShowIndexIfSmall}">
    {{currentIndex[0] + 1}} de {{Images?.length}}
  </div>
  <div class="rbuttoncarrcont" *ngIf="rbuttonVisible()">
    <button mat-mini-fab class="carrbtn" (click)="rbuttonClick()"><mat-icon>chevron_right</mat-icon></button>
  </div>
</div>

