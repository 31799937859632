import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngxs/store';
import { House, HousePagination } from 'src/app/models/House';
import { RepositoryService } from 'src/app/services/Repository/Repository.service';
import { AppStateService } from 'src/app/services/Store/AppState.service';
import { Utils } from 'src/app/Utils/Utils';

@Component({
  selector: 'app-favorites',
  templateUrl: './Favorites.component.html',
  styleUrls: ['./Favorites.component.scss']
})
export class FavoritesComponent implements OnInit {
  currentPagination: HousePagination = null;
  // tslint:disable-next-line: max-line-length
  constructor(private assSelector: AppStateService, private crud: RepositoryService, private store: Store, private cd: ChangeDetectorRef) {
    Utils.firstValueFrom(this.crud.GetPetition('inmueble/like/inmuebles/', false, true)).then((res: HousePagination) => {
      if (res.results && res.results.length > 0) {
        this.currentPagination = res;
        this.currentPagination.results = Utils.CloneObject(res.results);
      } else {
        // this.router.navigateByUrl('/NotResultsFound/' + HouseCategory.Buy.toString());
        this.currentPagination = null;
        this.currentPagination.results = [];
      }
    });
  }

  ngOnInit() {
  }

}
