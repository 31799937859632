import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardPageComponent implements OnInit, AfterViewInit {
  @ViewChild('mtg', {read: MatTabGroup}) mtg: MatTabGroup;
  loading = true;
  constructor(private cd: ChangeDetectorRef) {}
  ngAfterViewInit(): void {

  }

  ngOnInit() {}

}
