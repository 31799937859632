import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetTiposInmuebles, GetAmenidades } from './Actions';

@Injectable({
  providedIn: 'root'
})
export class InitStoreService {
  constructor(private store: Store) {
    this.store.dispatch(new GetTiposInmuebles());
    this.store.dispatch(new GetAmenidades());
  }
}
