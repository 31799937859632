import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-publica-tu-propiedad-modal',
  templateUrl: './PublicaTuPropiedadModal.component.html',
  styleUrls: ['./PublicaTuPropiedadModal.component.scss']
})
export class PublicaTuPropiedadModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PublicaTuPropiedadModalComponent>) {
  }
  ngOnInit() {
  }
  closeModal() {
    this.dialogRef.close();
  }
}
