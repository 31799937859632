import { SetCurrentUser } from './Store/Actions';
import { RepositoryService } from 'src/app/services/Repository/Repository.service';
import { AppStateService } from './Store/AppState.service';
import { Injectable, EventEmitter } from '@angular/core';
import { Usuario } from '../models/Usuario';
import { Store } from '@ngxs/store';
import { Utils } from '../Utils/Utils';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  ToggleSideBar: EventEmitter<boolean> = new EventEmitter();
  isToggleOpen = false;
  constructor(private readonly ass: AppStateService, private readonly crud: RepositoryService, private readonly store: Store) { }
  getCurrentUser(): Promise<Usuario | null> {
    return new Promise((response, rej) => {
      // tslint:disable-next-line: prefer-const no-var-keyword
      var subs = this.ass.CurrentUser.subscribe(user => {
        if (user) {
          response(user);
          if (subs) {
            subs.unsubscribe();
          }
        } else if (localStorage.getItem('token')) {
          Utils.firstValueFrom(this.crud.GetPetition('user/me')).then((res: Usuario) => {
            this.store.dispatch(new SetCurrentUser(localStorage.getItem('token')));
            response(res);
            if (subs) {
              subs.unsubscribe();
            }
          }).catch(_ => {
            this.store.dispatch(new SetCurrentUser(null));
            response(null);
            if (subs) {
              subs.unsubscribe();
            }
          });
        } else {
          response(null);
          if (subs) {
            subs.unsubscribe();
          }
        }
      });
    });
  }
}
