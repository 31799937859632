import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { UsuarioLogin } from 'src/app/models/Usuario';
import { RepositoryService } from 'src/app/services/Repository/Repository.service';
import { ShowLoading, SetCurrentUser } from 'src/app/services/Store/Actions';
import { AppStateService } from 'src/app/services/Store/AppState.service';
import { Utils } from 'src/app/Utils/Utils';
@Component({
  selector: 'app-login',
  templateUrl: './LogIn.component.html',
  styleUrls: ['./LogIn.component.scss']
})
export class LogInComponent implements OnDestroy, OnInit {
  loading = false;
  loadingInit = true;
  Utils = Utils;
  hide = true;
  formLogin: UntypedFormGroup;
  subs: Subscription = null;
  // tslint:disable-next-line: max-line-length
  constructor(private fb: UntypedFormBuilder, private store: Store, private router: Router, private crud: RepositoryService, private injector: Injector, private ass: AppStateService) {}
  ngOnInit(): void {
    const loginUser: UsuarioLogin = {
      email: '',
      password: '',
    };
    this.formLogin = this.fb.group(loginUser);
    this.formLogin.controls.password.setValidators([Validators.required]);
    this.formLogin.controls.email.setValidators([Validators.required]);
    this.formLogin.updateValueAndValidity();
    this.formLogin.controls.email.updateValueAndValidity();
    this.subs = this.ass.CurrentUser.subscribe(user => {
      if (user) {
        this.router.navigateByUrl('/Home');
      }
    });
    this.loadingInit = false;
  }
  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
  login() {
    if (this.formLogin.valid) {
      const body: UsuarioLogin = this.formLogin.value;
      body.email = body.email.trim().toLowerCase();
      body.password = body.password.trim();
      this.loading = true;
      Utils.firstValueFrom(this.crud.PostPetition('user/token/', body, false, false)).then((res: any) => {
        this.store.dispatch(new SetCurrentUser(res.token));
        this.loading = false;
        this.router.navigateByUrl('/');
      }).catch(error => {
        this.loading = false;
        this.crud.ShowErrors(error);
      });
    }
  }
  continueAsInvited() {
    this.router.navigateByUrl('/Home');
  }
  registrarme() {
    this.router.navigateByUrl('/Registro');
  }
}
