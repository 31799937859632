<div class="fullContainer posRelative textCenter">
  <mat-drawer-container class="example-container" [style.height]="'100%'" [hasBackdrop]="false">
    <mat-drawer-content class="hFillContent" [style.Width]="'100vw'">
      <h1>Favoritos</h1>
      <hr>
      <app-notresultsfound [category]="3" [hInitial]="true" *ngIf="!currentPagination || currentPagination?.results?.length === 0"></app-notresultsfound>
      <div *ngIf="currentPagination?.results?.length > 0">
        <div class="d-flex frowfwrap">
          <app-house-item *ngFor="let house of currentPagination?.results; let i=index" [House]="house"></app-house-item>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
