<div class="fullContainer posRelative textCenter OYAuto">
  <app-navbar [ShowExpanded]="false" [ShowSearchBar]="true"></app-navbar>
  <div class="childContainer" *ngIf="house">
    <div class="show-sm mb24px">
      <app-carousel1 class="houseCarousel" [ForceWH]="true" [Infinite]="true" [BorderRadious]="'3px'" [Pager]="true" [ShowIndex]="false" [Type]="1" [ShowArrows]="false"
      [Width]="'100%'" [Height]="'100%'" [MaxHeight]="maxHeightImgs"
      [Images]="house?.imagenesToShow" *ngIf="house?.imagenesToShow?.length > 0"></app-carousel1>
    </div>
    <div class="mlr24px pb66px">
      <div class="d-flex space-between mb6px">
        <div class="houseTitle">{{house?.titulo}}</div>
        <div class="d-flex">
          <div class="iconText centerMiddle fakeUrl" (click)="showShare()" *ngIf="!shareMode">
            <mat-icon class="shareIcon">share</mat-icon>
            Compartir
          </div>
          <div class="iconText centerMiddle" *ngIf="shareMode">
            <share-buttons 
            theme="material-dark"
            [include]="['facebook','messenger','whatsapp','twitter','linkedin','pinterest','email','copy','telegram']"
            show="11"
            [image]="imageShare"
            [url]="urlShare"
            [autoSetMeta]="false"></share-buttons>
          </div>
          <div class="iconText fakeUrl ml40px centerMiddle" (click)="setLike()" *ngIf="CurrentUser">
            <mat-icon class="likeIcon" [class.spinner]="likeLoading" *ngIf="house.is_liked">favorite</mat-icon>
            <mat-icon class="likeIconDisabled" [class.spinner]="likeLoading" *ngIf="!house.is_liked">favorite_border</mat-icon>
            {{house.is_liked ? 'Guardada' : 'Guardar'}}
          </div>
        </div>
      </div>
      <div class="houseDirection mb12px">
        {{house?.direccion}}
      </div>
      <div class="row show-sm">
        <div class="col-12">
          <div class="taleft">
            <div class="textHouse">
              {{house?.precio | currency:'USD':'symbol'}}
            </div>
            <div class="iconsDescriptionHouse">
              <div class="cardIconContOld">
                <mat-icon class="cardIcon" svgIcon="bed"></mat-icon>
                <div class="cardIconTextOld cardIconTextOldMobile">{{house.recamaras}} recámara{{house.recamaras !== 1 ? 's' : ''}}</div>
              </div>
              <div class="cardIconContOld">
                <mat-icon class="cardIcon" svgIcon="bath"></mat-icon>
                <div class="cardIconTextOld cardIconTextOldMobile">{{house.banos}} baño{{house.banos !== 1 ? 's' : ''}}</div>
              </div>
              <div class="cardIconContOld">
                <mat-icon class="cardIcon" svgIcon="bath"></mat-icon>
                <sup>1/2</sup>
                <div class="cardIconTextOld cardIconTextOldMobile">{{house.medios_banos}} medio{{house.medios_banos !== 1 ? 's' : ''}} baño{{house.medios_banos !== 1 ? 's' : ''}}</div>
              </div>
              <div class="cardIconContOld">
                <mat-icon class="cardIcon" svgIcon="expand"></mat-icon>
                <div class="cardIconTextOld cardIconTextOldMobile">{{house.m_2 | MetrosScale}}<sup>2</sup></div>
              </div>
              <div class="cardIconContOld">
                <mat-icon class="cardIcon cardIcon2 noselect">wb_shade</mat-icon>
                <div class="cardIconTextOld cardIconTextOldMobile">{{house.m_2_construccion | MetrosScale}}<sup>2</sup> de construcción</div>
              </div>
              <div class="cardIconContOld">
                <mat-icon class="cardIcon" svgIcon="car"></mat-icon>
                <div class="cardIconTextOld cardIconTextOldMobile">
                  {{
                    house?.estacionamientos === 0 || house?.estacionamientos > 1 ? 
                      house.estacionamientos + ' estacionamientos' :
                      house.estacionamientos + ' estacionamiento'
                  }}
                </div>
              </div>
            </div>
            <div class="titulos">
              Descripción
            </div>
            <div class="descriptionHouse">
              <span *ngIf="!longDesc">{{house?.ShortDesc}}</span>
              <div class="seeMore fakeUrl" *ngIf="!longDesc" (click)="showMoreDesc()">
                Ver Más  
                <mat-icon svgIcon="downBlueArrow"></mat-icon>
              </div>
              <span *ngIf="longDesc">
                {{house.descripcion}}
              </span>
            </div>
            <div *ngIf="house?.amenidades?.length !== 0">
              <div class="titulos">
                Amenidades Adicionales
              </div>
              <div class="amenidadesContainer"></div>
            </div>
            <div class="titulos">
              Ubicación
            </div>
            <div class="mapContainer">
              <a class="fakeA" target="_blank" href="https://www.google.com/maps?q={{house?.latitud}},{{house?.longitud}}">
                <google-map #GGMapHP height="100%" width="100%" [options]="options" [draggable]="false">
                  <map-marker
                    #marker{{i}}="mapMarker"
                    [position]="{ lat: house.latitud, lng: house.longitud }"
                    [options]="markerOptions"
                    [icon]="'/assets/SoloLogoGradiantMini.svg'">
                  </map-marker>
                </google-map>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row hide-sm">
        <div class="col col-md-8 col-lg-8">
          <app-carousel1 class="houseCarousel" [ForceWH]="true" [Infinite]="true" [BorderRadious]="'11px'" [Pager]="true" [ShowIndex]="false" [Type]="1" [ShowArrows]="true"
          [Width]="'100%'" [Height]="'100%'" [MaxHeight]="maxHeightImgs"
          [Images]="house?.imagenesToShow" *ngIf="house?.imagenesToShow?.length > 0"></app-carousel1>
          <div class="taleft">
            <div class="textHouse">
              {{house?.precio | currency:'USD':'symbol'}}
            </div>
            <div class="iconsDescriptionHouse">
              <div class="cardIconContOld">
                <mat-icon class="cardIcon" svgIcon="bed"></mat-icon>
                <div class="cardIconTextOld">{{house.recamaras}} recámara{{house.recamaras !== 1 ? 's' : ''}}</div>
              </div>
              <div class="cardIconContOld">
                <mat-icon class="cardIcon" svgIcon="bath"></mat-icon>
                <div class="cardIconTextOld">{{house.banos}} baño{{house.banos !== 1 ? 's' : ''}}</div>
              </div>
              <div class="cardIconContOld">
                <mat-icon class="cardIcon" svgIcon="bath"></mat-icon>
                <sup>1/2</sup>
                <div class="cardIconTextOld">{{house.medios_banos}} medio{{house.medios_banos !== 1 ? 's' : ''}} baño{{house.medios_banos !== 1 ? 's' : ''}}</div>
              </div>
              <div class="cardIconContOld">
                <mat-icon class="cardIcon" svgIcon="expand"></mat-icon>
                <div class="cardIconTextOld">{{house.m_2 | MetrosScale}}<sup>2</sup></div>
              </div>
              <div class="cardIconContOld">
                <mat-icon class="cardIcon cardIcon2 noselect">wb_shade</mat-icon>
                <div class="cardIconTextOld">{{house.m_2_construccion | MetrosScale}}<sup>2</sup> de construcción</div>
              </div>
              <div class="cardIconContOld">
                <mat-icon class="cardIcon" svgIcon="car"></mat-icon>
                <div class="cardIconTextOld">
                  {{
                    house?.estacionamientos === 0 || house?.estacionamientos > 1 ? 
                      house.estacionamientos + ' estacionamientos' :
                      house.estacionamientos + ' estacionamiento'
                  }}
                </div>
              </div>
            </div>
            <div class="titulos">
              Descripción
            </div>
            <div class="descriptionHouse">
              <span *ngIf="!longDesc">{{house?.ShortDesc}}</span>
              <div class="seeMore fakeUrl" *ngIf="!longDesc" (click)="showMoreDesc()">
                Ver Más  
                <mat-icon svgIcon="downBlueArrow"></mat-icon>
              </div>
              <span *ngIf="longDesc">
                {{house.descripcion}}
              </span>
            </div>
            <div *ngIf="house?.amenidades?.length !== 0">
              <div class="titulos">
                Amenidades Adicionales
              </div>
              <div class="amenidadesContainer"></div>
            </div>
            <div class="titulos">
              Ubicación
            </div>
            <div class="mapContainer">
              <a class="fakeA" target="_blank" href="https://www.google.com/maps?q={{house?.latitud}},{{house?.longitud}}">
                <google-map #GGMapHP height="100%" width="100%" [options]="options" [draggable]="false">
                  <map-marker
                    #marker{{i}}="mapMarker"
                    [position]="{ lat: house.latitud, lng: house.longitud }"
                    [options]="markerOptions"
                    [icon]="'/assets/SoloLogoGradiantMini.svg'">
                  </map-marker>
                </google-map>
              </a>
            </div>
          </div>
        </div>
        <div class="col col-4">
          <div class="cardWhiteProspectos">
            <div class="centerMiddle">
              <app-prospecto-comprador [house]="house"></app-prospecto-comprador>
            </div>
          </div>
        </div>
      </div>
      <div class="taleft">
        <!--CONTINUACIÓN ABAJO A COL-12-->
      </div>
    </div>
    <div class="show-sm extraMarginBtnMobile"></div>
    <div class="show-sm downBtnSectionMobile" *ngIf="house">
      <button mat-flat-button class="button btnBlue2" (click)="contactanosClick()">
        ¡Contáctanos!
      </button>
    </div>
  </div>
</div>
