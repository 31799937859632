import { PaginationBase } from './PaginationBase';

export class Amenidad {
    id = -1;
    name: string = null;
}
export class AmenidadesPagination extends PaginationBase {
    results: Amenidad[];
}
export const DefaultAmenidades: Amenidad[] = [
    {
        id: 1,
        name: 'Alberca'
    },
    {
        id: 2,
        name: 'Jardín'
    },
    {
        id: 3,
        name: 'Elevador'
    },
    {
        id: 4,
        name: 'Vapor'
    },
    {
        id: 5,
        name: 'Condominio'
    },
    {
        id: 6,
        name: 'Coto privado'
    },
    {
        id: 7,
        name: 'Roof Garden'
    },
    {
        id: 8,
        name: 'Balcón'
    },
];
