import { EventEmitter } from '@angular/core';

export class FiltersChips {
    text = '';
    onClick?: EventEmitter<any> | any = null;
    defaultText = '';
    extraClasses = '';
    ftype: FilterType = FilterType.TipoInmueble;
}
export enum FilterType {
    TipoInmueble = 1,
    Precio = 2,
    Habitaciones = 3,
    Banos = 4,
    Estacionamiento = 5,
    Amenidades = 6,
    Antiguedad = 7,
    Construccion = 8,
    Terreno = 9,
}
