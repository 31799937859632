import { RepositoryService } from './../../../services/Repository/Repository.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Utils } from 'src/app/Utils/Utils';
import { Store } from '@ngxs/store';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss']
})
export class RequestPasswordComponent implements OnInit {
  loadingInit = true;
  loading = false;
  Utils = Utils;
  hide = true;
  formRP: UntypedFormGroup;
  constructor(private router: Router, private fb: UntypedFormBuilder, private crud: RepositoryService, private store: Store) {
  }
  ngOnInit(): void {
    const rpUser: UsuarioRP = {
      email: '',
    };
    this.formRP = this.fb.group(rpUser);
    this.formRP.controls.email.setValidators([Validators.required, Validators.pattern(Utils.emailPattern)]);
    this.formRP.updateValueAndValidity();
    this.formRP.controls.email.updateValueAndValidity();
    this.loadingInit = false;
  }
  rp() {
    const body: UsuarioRP = this.formRP.value;
    body.email = body.email.trim().toLowerCase();
    this.loading = true;
    Utils.firstValueFrom(this.crud.PostPetition('user/send-recovery-email/', body, false, false)).then((res: any) => {
      // TODO SUCCESS MESSAGE
      this.loading = false;
      alertify.notify('Se ha enviado un email de recuperación de contraseña.', 'success', 3);
      this.router.navigateByUrl('/Login');
    }).catch(error => {
      this.loading = false;
      this.crud.ShowErrors(error);
    });
  }
  login() {
    this.router.navigateByUrl('/Login');
  }
}
class UsuarioRP {
  email: string;
}
