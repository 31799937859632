<app-carousel1 *ngIf="Type == CarouselType.images1"
[style.height]="Height"
[style.width]="Width"
[Images]="Images"
[Width]="Width"
[Height]="Height"
[Pager]="Pager"
[ShowIndex]="ShowIndex"
[ShowIndexIfSmall]="ShowIndexIfSmall"
[ForceWH]="ForceWH"
[BorderRadious]="BorderRadious"
[ShowArrows]="ShowArrows"
[Infinite]="Infinite"
></app-carousel1>
<app-carousel2 *ngIf="Type == CarouselType.images2"
[style.height]="Height"
[style.width]="Width"
[Images]="Images"
[BorderRadious]="BorderRadious"
[Width]="Width"
[ForceWH]="ForceWH"
[Height]="Height"
[ShowArrows]="ShowArrows"
[Infinite]="Infinite"
></app-carousel2>
    