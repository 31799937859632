import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetPasswordValidatorResponse, UsuarioSP } from 'src/app/models/Usuario';
import { RepositoryService } from 'src/app/services/Repository/Repository.service';
import { Utils } from 'src/app/Utils/Utils';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  loadingInit = true;
  loading = true;
  Utils = Utils;
  hide = true;
  hide2 = true;
  formSP: UntypedFormGroup;
  // tslint:disable-next-line: max-line-length
  constructor(private router: Router, private fb: UntypedFormBuilder, private crud: RepositoryService, private route: ActivatedRoute, private store: Store) {}
  sp() {
    const body: UsuarioSP = this.formSP.value;
    if (body.password === body.password2) {
      delete body.password2;
      this.loading = true;
      Utils.firstValueFrom(this.crud.PostPetition('user/recovery-password-email/', body, false, false)).then((res: any) => {
        // TODO SUCCESS MESSAGE
        this.loading = false;
        alertify.notify('Se ha cambiado la contraseña con éxito.', 'success', 3);
        this.router.navigateByUrl('/Login');
      }).catch(error => {
        this.loading = false;
        this.crud.ShowErrors(error);
      });
    }
  }
  ngOnInit(): void {
    const token = this.route.snapshot.paramMap.get('token');
    const uid = this.route.snapshot.paramMap.get('uid');
    const formSP: UsuarioSP = {
      password: '',
      password2: '',
      token: null,
      uidb64: null
    };
    this.formSP = this.fb.group(formSP);
    this.formSP.disable();
    this.loadingInit = false;
    Utils.firstValueFrom(this.crud.GetPetition('user/recovery-password-email/' + uid + '/' + token))
    .then((res: SetPasswordValidatorResponse) => {
      if (res?.message === 'The activation link is valid.') {
        const formSP: UsuarioSP = {
          password: '',
          password2: '',
          token,
          uidb64: uid
        };
        this.formSP = this.fb.group(formSP);
        this.formSP.controls.password.setValidators([Validators.required,
          this.MatchPassword, Validators.minLength(8), Validators.maxLength(16)]);
        this.formSP.controls.password2.setValidators([Validators.required,
          this.MatchPassword, Validators.minLength(8), Validators.maxLength(16)]);
        this.formSP.updateValueAndValidity();
        this.loading = false;
      } else {
        this.router.navigateByUrl('/NotResultsFound');
      }
    })//.catch(_ => this.router.navigateByUrl('/NotResultsFound'));
  }
  MatchPassword(AC: UntypedFormControl) {
    const dataForm = AC.parent;
    if (!dataForm) { return null; }
    const newPasswordRepeat = dataForm.get('password2');
    const password = dataForm.get('password')?.value;
    const confirmPassword = newPasswordRepeat?.value;
    const pass2CtrlSTR = 'password2';
    if ((password !== '' || confirmPassword !== '') && password !== confirmPassword) {
      /* for newPasswordRepeat from current field "newPassword" */
      dataForm.controls[pass2CtrlSTR].setErrors( {MatchPassword: true} );
      if ( newPasswordRepeat === AC ) {
        /* for current field "newPasswordRepeat" */
        return {newPasswordRepeat: {MatchPassword: true} };
      }
    } else {
      dataForm.controls[pass2CtrlSTR].setErrors( null );
    }
    return null;
  }
}

