import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { CarouselType } from 'src/app/models/ImageSet';

@Component({
  selector: 'app-carousel1',
  templateUrl: './Carousel1.component.html',
  styleUrls: ['./Carousel1.component.scss']
})
export class Carousel1Component implements AfterViewInit {
  @ViewChild('sliderCarr', {read: IonSlides}) slides: IonSlides;
  @Input() Images: string[] = [];
  @Input() MinWidth = '0px';
  @Input() MinHeight = '0px';
  @Input() MaxWidth = '100%';
  @Input() MaxHeight = '100%';
  @Input() Width = '100%';
  @Input() Height = '100%';
  @Input() Pager = true;
  @Input() ForceWH = false;
  @Input() ShowIndex = true;
  @Input() ShowIndexIfSmall = true;
  @Input() ShowArrows = true;
  @Input() BorderRadious = '0px';
  @Input() Infinite = false;
  currentIndex = [-1];
  constructor(private ref: ChangeDetectorRef) { }
  refresh() {
    this.ref.detectChanges();
  }
  ngAfterViewInit(): void {
    const array = [];
    for (let i = 0; i < this.Images.length; i++) {
      array.push(i);
    }
    this.currentIndex = array;
    this.ref.detectChanges();
  }
  lbuttonClick() {
    if (this.currentIndex[0] !== -1) {
      const item1 = this.currentIndex.pop(); // Before
      this.currentIndex.unshift(item1);
      this.slides.slideTo(this.currentIndex[0]);
    }
  }
  rbuttonClick() {
    if (this.currentIndex[0] !== -1) {
      const item2 = this.currentIndex.shift(); // Next
      this.currentIndex.push(item2);
      this.slides.slideTo(this.currentIndex[0]);
    }
  }
  lbuttonVisible() {
    if (this.currentIndex[0] === -1) {
      return false;
    }
    return (this.currentIndex[0] > 0 && this.ShowArrows) || (this.ShowArrows && this.Infinite && this.Images.length > 1);
  }
  rbuttonVisible() {
    if (this.currentIndex[0] === -1) {
      return false;
    }
    // tslint:disable-next-line: max-line-length
    return (this.currentIndex[0] < this.Images?.length - 1 && this.ShowArrows) || (this.ShowArrows && this.Infinite && this.Images.length > 1);
  }
  changed() {
    this.slides.getActiveIndex().then(res => {
      if (this.currentIndex[0] !== -1) {
        const index = this.currentIndex.findIndex(i => res === i);
        for (let i = 0; i < index; i++) {
          const item2 = this.currentIndex.shift(); // Next
          this.currentIndex.push(item2);
          this.slides.slideTo(this.currentIndex[0]);
        }
      }
    });
  }
}
