import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-noticias-item',
  templateUrl: './noticias-item.component.html',
  styleUrls: ['./noticias-item.component.scss'],
})
export class NoticiasItemComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
