<div>
  <div class="mb15px">
    <div class="contactanosTextTitle">
      ¿Te interesa esta propiedad?
    </div>
    <div class="contactanosTextSubtitle">
      Déjanos tus datos para ponernos en contacto
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="submitInterested()">
    <mat-form-field class="w100pr" appearance="outline">
      <mat-label>Nombre</mat-label> 
      <input class="custom-white" formControlName="nombre" autocomplete="name" matInput>
      <mat-error *ngIf="form?.controls?.nombre?.errors?.required">{{ Utils.datoRequerido }}</mat-error>
    </mat-form-field>
    <mat-form-field class="w100pr" appearance="outline">
      <mat-icon matPrefix>alternate_email</mat-icon>
      <mat-label>Email</mat-label>
      <input matInput class="custom-white" formControlName="correo" #correo id="correo" autocomplete="email"/>
      <mat-hint>Ejemplo: {{ Utils.ejemploMail }}</mat-hint>
      <mat-error *ngIf="form?.controls?.correo?.errors?.required">{{ Utils.datoRequerido }} Ejemplo:
        {{ Utils.ejemploMail }}</mat-error>
      <mat-error *ngIf="form?.controls?.correo?.errors?.pattern">{{ Utils.FormatoMailIncorrecto }} Ejemplo:
        {{ Utils.ejemploMail }}</mat-error>
    </mat-form-field>
    <mat-form-field class="w100pr" appearance="outline">
      <mat-icon matPrefix>local_phone</mat-icon>
      <mat-label>Teléfono</mat-label>
      <input matInput formControlName="interested_phone_number" appNumberOnly maxlength="10" autocomplete="tel">
      <mat-error *ngIf="form?.controls?.interested_phone_number?.errors?.required">{{Utils.datoRequerido}}</mat-error>
      <mat-error *ngIf="form?.controls?.interested_phone_number?.errors?.minlength">{{Utils.minLength.replace('{0}', '10')}}</mat-error>
      <mat-error *ngIf="form?.controls?.interested_phone_number?.errors?.maxlength">{{Utils.maxLength.replace('{0}', '10')}}</mat-error>
    </mat-form-field>
    <div class="taright">
      <button mat-flat-button class="button btnBlue2" [class.spinner]="loading" [disabled]="form.invalid || loading" type="submit">
        ¡Contáctanos!
      </button>
    </div>
  </form>
</div>
