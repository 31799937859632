import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'MetrosScale'
})
export class MetrosScalePipe implements PipeTransform {
  transform(metros: number): string {
    let res = '';
    if (!metros) {
      res = '0m';
    } else if (metros < 1000) {
      res = metros + 'm';
    } else if (metros > 1000000) {
      res = '+999km';
    } else {
      res = (metros / 1000).toFixed(2);
      if (res.substr(res.length - 2, 2) === '00') {
        res = res.substr(0, res.length - 3);
      } else if (res.substr(res.length - 1, 1) === '0') {
        res = res.substr(0, res.length - 1);
      }
      res = res + 'km';
    }
    return res;
  }

}
