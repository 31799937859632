<div class="loginContainer">
  <a
    class="display-flex-center-left a-black-text-decoration-none pb24px"
    routerLink="/"
  >
    <img class="SoloLogo" src="/assets/LogoAzulNoFondo.png" alt="" />
    <div class="boldText">Propefy</div>
  </a>
  <div class="loginCard">
    <form
      [formGroup]="formRP"
      class="frm"
      *ngIf="!loadingInit"
      (ngSubmit)="rp()"
    >
      <div class="loginFormContainer">
        <div class="loginWelcomeText2">Reestablece tu Contraseña</div>
        <div class="loginWelcomeText pb24px">
          Ingresa tu email y te enviaremos ayuda
        </div>
        <mat-form-field
          class="w100pr pb24px custom-semi-oval-mat-form-field custom-blue-mat-form-field"
          appearance="outline"
        >
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" autocomplete="on" required />
          <mat-error *ngIf="formRP?.controls?.email?.errors?.required"
            >Por favor ingresa un email válido</mat-error
          >
        </mat-form-field>
        <button
          mat-flat-button
          class="button btnBlue2 mb24px"
          [class.spinner]="loading"
          [disabled]="loading"
          type="submit"
        >
          Enviar
        </button>
        <div class="fake-hr mb24px"></div>
        <div class="contact-us-register-container mb32px">
          ¿No recibiste email? &nbsp;
          <a
            href="mailto:system@propefy.com"
            tabindex="-1"
            class="forgot-password"
            >Contáctanos</a
          >
        </div>
      </div>
    </form>
  </div>
</div>
