<a mat-list-item href="{{nav.url}}" *ngIf="nav.customUrl">
  <mat-icon class="sideIcon" *ngIf="nav.icon">{{nav.icon}}</mat-icon>
  <div class="fakeIcon" *ngIf="!nav.icon"></div>
  {{nav.name}}
</a>
<a mat-list-item routerLink="{{nav.url}}" routerLinkActive="active" *ngIf="!nav.customUrl">
  <mat-icon class="sideIcon" *ngIf="nav.icon">{{nav.icon}}</mat-icon>
  <div class="fakeIcon" *ngIf="!nav.icon"></div>
  {{nav.name}}
</a>
