export class ImageSet {
    id: number;
    inmueble?: number;
    photo: string;
    thumbnail: string;
}
export enum CarouselType {
    images1 = 1,
    images2 = 2,
    images3 = 3
}
