<div class="FContainer">
  <form [formGroup]="form" class="row">
    <div class="headderFilters">
      <h1>Filtros</h1>
      <div class="btnsContainer" *ngIf="ButtonsTop">
        <button mat-flat-button class="button btnRed" tabindex="11" (click)="init()">
          Reset
        </button>
        <button mat-flat-button class="button btnBlue" tabindex="12" (click)="Aceptar()">
          Aceptar
        </button>
      </div>
    </div>
    <mat-form-field class="col-12" appearance="outline">
      <mat-icon matPrefix>search</mat-icon>
      <mat-label>Buscar</mat-label>
      <input matInput tabindex="1" ngx-google-places-autocomplete #placesRef2 [options]="options" (onAddressChange)="handleAddressChange($event)" class="custom-white" formControlName="Search" autocomplete="off">
    </mat-form-field>
    <mat-form-field class="col-sm-12 col-md-6 col-lg-6" appearance="outline">
      <mat-label>Tipo de inmueble</mat-label> 
      <mat-select tabindex="2" multiple (ngModelChange)="TiposInmueblesChanged()" formControlName="TiposInmuebles">
        <mat-option *ngFor="let t of TiposInmuebles" [value]="t.Value">{{t.Name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="col-sm-12 col-md-6 col-lg-6">
      <div class="centerMiddle plr15px">
        <div class="d-grid">
          <mat-slide-toggle tabindex="3" class="mb15px" (toggleChange)="ModInput('AceptaCredito')" formControlName="AceptaCredito">Acepta crédito</mat-slide-toggle>
          <mat-slide-toggle tabindex="4" class="mb15px" (toggleChange)="ModInput('DentroPrivada')" formControlName="DentroPrivada">Dentro de privada</mat-slide-toggle>
        </div>
      </div>
    </div>
    <mat-form-field class="customInputSlider col-sm-12 col-md-6 col-lg-6" appearance="outline">
      <mat-label>Precio mínimo: {{MinPriceDisplayValue}}</mat-label>
      <mat-slider
        tabindex="5"
        #MinPrice
        (change)="setValueMinMax($event, 1)"
        step="20000"
        min="80000"
        [max]="maxPrice"
        formControlName="MinPrice"
        aria-label="MinPrice">
      </mat-slider>
      <input matInput class="d-none" [value]="minPrice">
    </mat-form-field>
    <mat-form-field class="customInputSlider col-sm-12 col-md-6 col-lg-6" appearance="outline">
      <mat-label>Precio máximo: {{MaxPriceDisplayValue}}</mat-label>
      <mat-slider
        tabindex="6"
        #MaxPrice
        step="20000"
        (change)="setValueMinMax($event, 2)"
        [min]="minPrice"
        max="3020000"
        formControlName="MaxPrice"
        aria-label="MaxPrice"></mat-slider>
      <input matInput class="d-none" [value]="maxPrice">
    </mat-form-field>
    <mat-form-field class="customInputSlider col-sm-12 col-md-6 col-lg-6" appearance="outline">
      <mat-label>Recámaras: {{form.controls.Recamaras.value}}</mat-label>
      <mat-slider
        tabindex="7"
        step="1"
        [min]="0"
        max="10"
        (change)="ModInput('Recamaras')"
        formControlName="Recamaras"
        aria-label="Recamaras"></mat-slider>
      <input matInput class="d-none" [value]="0">
    </mat-form-field>
    <mat-form-field class="customInputSlider col-sm-12 col-md-6 col-lg-6" appearance="outline">
      <mat-label>Baños: {{form.controls.Banos.value}}</mat-label>
      <mat-slider
        tabindex="8"
        step="1"
        [min]="0"
        max="10"
        (change)="ModInput('Banos')"
        formControlName="Banos"
        aria-label="Banos"></mat-slider>
      <input matInput class="d-none" [value]="0">
    </mat-form-field>
    <mat-form-field class="customInputSlider col-sm-12 col-md-6 col-lg-6" appearance="outline">
      <mat-label>Medios baños: {{form.controls.MBanos.value}}</mat-label>
      <mat-slider
        tabindex="9"
        step="1"
        [min]="0"
        max="10"
        (change)="ModInput('MBanos')"
        formControlName="MBanos"
        aria-label="MBanos"></mat-slider>
      <input matInput class="d-none" [value]="0">
    </mat-form-field>
    <mat-form-field class="customInputSlider col-sm-12 col-md-6 col-lg-6" appearance="outline">
      <mat-label>Estacionamientos: {{form.controls.Estacionamientos.value}}</mat-label>
      <mat-slider
        tabindex="10"
        step="1"
        [min]="0"
        max="10"
        (change)="ModInput('Estacionamientos')"
        formControlName="Estacionamientos"
        aria-label="Estacionamientos"></mat-slider>
      <input matInput class="d-none" [value]="0">
    </mat-form-field>
    <div class="btnsContainer col-12 pb30px" *ngIf="!ButtonsTop">
      <button mat-flat-button class="button btnRed" tabindex="11" (click)="init()">
        Reset
      </button>
      <button mat-flat-button class="button btnBlue" tabindex="12" (click)="Aceptar()">
        Aceptar
      </button>
    </div>
  </form>
</div>