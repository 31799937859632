import { Amenidad } from 'src/app/models/Amenidades';
import { TipoInmuebles } from './TipoInmuebles';
export class TipoInmueblesCB extends TipoInmuebles {
  checked = false;
}
export class AmenidadesCB extends Amenidad {
  checked = false;
}
export class FormConstruccionTerrenoPrecio {
  min: number = null;
  max: number = null;
}
