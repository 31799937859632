import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { RepositoryService } from './Repository/Repository.service';
import { Usuario } from '../models/Usuario';
import { AppStateService } from './Store/AppState.service';
import { Utils } from '../Utils/Utils';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class CanActivateIsLoggedService implements CanActivate {
  constructor(private store: Store, private crud: RepositoryService, private router: Router) { }
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('token')) {
      let user: Usuario = null;
      try {
        user = await Utils.getUser(this.crud);
      } catch (error) {}
      if (Utils.IsLogged(user)) {
        return true;
      } else {
        this.router.navigateByUrl('/Home');
        return false;
      }
    }
    this.router.navigateByUrl('/Home');
    return false;
  }
}
